import { Typography } from '@mui/material';

const adornmentSup = (start?: string, end?: string) => {
  const Element = (label: string) => (
    <Typography variant="caption" noWrap overflow="visible" sx={{ p: 0.25 }}>
      {label}
    </Typography>
  );

  const ElementSup = (label: string) => (
    <Typography variant="caption" noWrap overflow="visible" sx={{ p: 0.25 }}>
      <sup>{label}</sup>
    </Typography>
  );

  return {
    startAdornment: start && Element(start),
    endAdornment: end && ElementSup(end),
  };
};

export default adornmentSup;
