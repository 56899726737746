import { Fragment } from 'react';
import { Grid, Stack } from '@mui/material';
import Form from 'components/Form';

import RowContainer from '../components/RowContainer';
import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import RowContent from '../components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'Scheduled Tests',
    key: 'situation1.checked1',
  },
];

const checks2 = [
  {
    label: 'Ward Transfer',
    key: 'situation2.checked1',
  },
];

const Situation = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="S (Situation)" />

      <RowContainer
        xs={12}
        sx={{ margin: '20px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Grid container xs={12} sx={{ marginBottom: '-30px' }}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={12}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                    <Form.MuiTextField
                      required={false}
                      sx={{ marginLeft: '20px' }}
                      fullWidth
                      disabled={disabled}
                      placeholder="Enter"
                      {...register('situation1_input1')}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </RowContent>
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Grid container xs={12}>
            {checks2.map((v, i) => {
              return (
                <Grid item xs={12}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              fullWidth
              multiline
              minRows={3}
              disabled={disabled}
              placeholder="Please specify additional details."
              {...register('situation2_input')}
            />
          </Grid>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Situation;
