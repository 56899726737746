const locale = {
  en: {
    // common
    ETC: 'etc',
    'GRADE.1': 'Student',
    'GRADE.2': 'Prof./TA',
    'GENDER.1': 'Female',
    'GENDER.2': 'Male',
    'EXIST.SHORT.1': 'N',
    'EXIST.SHORT.2': 'Y',
    'NEED.1': 'Necessary',
    'NEED.2': 'Unnecessary',
    'NEEDED.1': 'Not Needed',
    'NEEDED.2': 'Needed',
    'YESORNO.1': 'Yes',
    'YESORNO.2': 'No',
    'NONE.EXIST.1': 'None',
    'NONE.EXIST.2': 'Exist',

    // error message
    'ERROR.CODE.0': 'An unknown error occurred. ErrorCode: {rc}',
    'ERROR.CODE.100':
      'There is a problem with your account. Please contact the administrator.',
    'ERROR.CODE.101': 'Email authentication code does not match',
    'ERROR.CODE.102': "The virtual patient's ID is incorrect",
    'ERROR.CODE.104': 'Recording paper ID is incorrect',
    'ERROR.CODE.105': 'Nursing record information not found',
    'ERROR.CODE.106':
      'You are an unauthorized user. Please contact the administrator',
    'ERROR.CODE.107': 'Passwords do not match',
    'ERROR.CODE.108':
      'This email address has already been registered. Please enter another Email.',
    'ERROR.CODE.109':
      'This is a virtual patient ID that already exists. Please enter another ID.',
    'ERROR.CODE.110':
      'School information could not be found. Please contact the administrator.',
    'ERROR.CODE.111':
      'You do not have permission. Please contact the administrator.',
    'ERROR.CODE.201':
      'Insurance reimbursement code information could not be found',
    'ERROR.CODE.202': 'Drug code information could not be found',
    'ERROR.CODE.702': 'Not a valid file. Please check the file again',

    // signup
    'REQUIRED.EMAIL.FORMAT': 'The email format is incorrect',
    'REQUIRED.NAME.FORMAT': 'Please edit your name.',
    'REQUIRED.EMAIL.VERIFICATION':
      'Please check your email authentication code',
    'REQUIRED.PASSWORD.FORMAT':
      'Please enter the password at least 8 digits including English + numbers + special symbols.',
    'REQUIRED.PASSWORD.CONFIRM': 'The passwords you entered do not match',
    'REQUIRED.COLLEGE': 'Please select a school',
    'REQUIRED.STUDENT.NO':
      'The student number can only contain 15 or fewer digits excluding “-"',
    'REQUIRED.PASSWORD.CONFIRMTRUE':
      'Enter your password and press the confirm button.',
    'REQUIRED.PASSWORD.CONFIRMTWO': 'Please enter new password confirmation.',
    'REQUIRED.PASSWORD.RECHECKED':
      'Please enter a password with at least 8 characters, including letters, numbers, and special characters.',

    // Main
    'REQUIRED.STUDENT': 'No student has been selected.',
    'REQUIRED.PATIENT': 'No patient has been selected.',

    //CoreNursingSkillVideo
    'REQUIRED.VIDIEO.FORMAT': 'You cannot upload files larger than 500 MB.',

    // nursing record
    'RECORD.0': 'NANDA',
    'RECORD.1': 'SOAPIE',
    'RECORD.2': 'Focus DAR',
    'RECORD.3': 'Narrative',
    'RECORD.4': 'Nursing Process',
    'RECORD.TYPE.0': 'NANDA',
    'RECORD.TYPE.1': 'SOAPIE',
    'RECORD.TYPE.2': 'FOCUSDAR',
    'RECORD.TYPE.3': 'NARRATIVE',
    'RECORD.TYPE.4': 'REMARKS',
    'NANDA.DOMAIN': 'Domain',
    'NANDA.CLASS': 'Class',
    'NANDA.DIAGNOSIS': 'Diagnosis',
    'NANDA.COLLECTINGDATA': 'Subjective/Objective Data Collection',
    'NANDA.GOAL': 'Short-term/Long-term Goals',
    'NANDA.PLAN': 'Nursing Plan',
    'NANDA.INTERVENTIONS': 'Interventions',
    'NANDA.EVALUATION': 'Evaluation',
    'SOAPIE.SUBJECTIVE': 'Subjective Data',
    'SOAPIE.OBJECTIVE': 'Objective Data',
    'SOAPIE.ASSESSMENT': 'Assessment',
    'SOAPIE.PLANNING': 'Planning',
    'SOAPIE.INTERVENTIONS': 'Interventions',
    'SOAPIE.EVALUATION': 'Evaluation',
    'FOCUSDAR.FOCUS': 'Focus',
    'FOCUSDAR.DATA': 'Data',
    'FOCUSDAR.ACTION': 'Action',
    'FOCUSDAR.RESPONSE': 'Response',
    'NARRATIVE.NARRATIVENOTE': 'Narrative Notes',
    'REMARKS.ASSESSMENT': 'Nursing Assessment',
    'REMARKS.DIAGNOSIS': 'Nursing Diagnosis',
    'REMARKS.DIAGNOSISRELATE': 'related to',
    'REMARKS.GOAL': 'Nursing Goal',
    'REMARKS.PLAN': 'Nursing Plan',
    'REMARKS.INTERVENTIONS':
      'Nursing Perform / arbitration / Theoretical Interventions',
    'REMARKS.EVALUATION': 'Nursing Evaluation',
    'REQUIRED.DOMAIN': 'Select Domain',
    'REQUIRED.CLASS': 'Select Class',
    'REQUIRED.DIAGNOSIS': 'Select Diagnosis',
    'REQUIRED.RECORD.TIME': 'Enter Nursing Record Time',

    // hospitalization
    'HOSPITALIZATION.PATH.1': 'OPD',
    'HOSPITALIZATION.PATH.2': 'ER',
    'HOSPITALIZATION.WAY.1': 'On Foot',
    'HOSPITALIZATION.WAY.2': 'Wheelchair',
    'HOSPITALIZATION.WAY.3': 'Stretch Car',
    'HOSPITALIZATION.STATUS.1': 'Alert',
    'HOSPITALIZATION.STATUS.2': 'Drowsy',
    'HOSPITALIZATION.STATUS.3': 'Stupor',
    'HOSPITALIZATION.STATUS.4': 'Semicoma',
    'HOSPITALIZATION.STATUS.5': 'Coma',
    'HOSPITALIZATION.STATUS02.1': 'Smooth',
    'HOSPITALIZATION.STATUS02.2': 'Difficult',
    'HOSPITALIZATION.STATUS02.3': 'Impossible',

    'HOSPITALIZATION.DISEASE.HISTORY.1': 'HTN',
    'HOSPITALIZATION.DISEASE.HISTORY.2': 'DM',
    'HOSPITALIZATION.DISEASE.HISTORY.3': 'TBc',
    'HOSPITALIZATION.DISEASE.HISTORY.4': 'Hep',
    'HOSPITALIZATION.DISEASE.HISTORY.5': 'Cancer',

    'HOSPITALIZATION.BODY.CYCLE.1': 'Chest Pain',
    'HOSPITALIZATION.BODY.CYCLE.2': 'Palpitation',
    'HOSPITALIZATION.BODY.CYCLE.3': 'Arrhythmia',
    'HOSPITALIZATION.BODY.CYCLE.4': 'Pacemaker',
    'HOSPITALIZATION.BODY.BREATH.1': 'Dyspnea',
    'HOSPITALIZATION.BODY.BREATH.2': 'Cough',
    'HOSPITALIZATION.BODY.BREATH.3': 'Sputum',
    'HOSPITALIZATION.BODY.BREATH.4': 'Hemoptysis',
    'HOSPITALIZATION.BODY.CAMOUFLAGE.1': 'Nausea',
    'HOSPITALIZATION.BODY.CAMOUFLAGE.2': 'Vomiting',
    'HOSPITALIZATION.BODY.CAMOUFLAGE.3': 'Abdominal Pain',
    'HOSPITALIZATION.BODY.CAMOUFLAGE.4': 'Dysphagia',
    'HOSPITALIZATION.BODY.NERVE.1': 'Paralysis',
    'HOSPITALIZATION.BODY.NERVE.2': 'Numbness',
    'HOSPITALIZATION.BODY.NERVE.3': 'Paresthesia',
    'HOSPITALIZATION.BODY.NERVE.4': 'Dizziness',
    'HOSPITALIZATION.BODY.SKIN.1': 'Rash',
    'HOSPITALIZATION.BODY.SKIN.2': 'Pruritis',
    'HOSPITALIZATION.BODY.SKIN.3': 'Edema',
    'HOSPITALIZATION.BODY.SKIN.4': 'Ulcer',

    'HOSPITALIZATION.HABIT.OBG.1': 'Not applicable',

    'HOSPITALIZATION.HABIT.FECES.1': 'Normal',
    'HOSPITALIZATION.HABIT.FECES.2': 'Diarrhea',
    'HOSPITALIZATION.HABIT.FECES.3': 'Melena',
    'HOSPITALIZATION.HABIT.FECES.4': 'Constipation',
    'HOSPITALIZATION.HABIT.FECES.5': 'Artificial Anus',
    'HOSPITALIZATION.HABIT.URINE.1': 'Normal',
    'HOSPITALIZATION.HABIT.URINE.2': 'Dysuria',
    'HOSPITALIZATION.HABIT.URINE.3': 'Frequent',
    'HOSPITALIZATION.HABIT.URINE.4': 'Incontinence',
    'HOSPITALIZATION.HABIT.URINE.5': 'Ostomy',

    'HOSPITALIZATION.FUNCTIONAL.EVALUATION.1': 'Possible (0)',
    'HOSPITALIZATION.FUNCTIONAL.EVALUATION.2': 'Need Help (1)',
    'HOSPITALIZATION.FUNCTIONAL.EVALUATION.3': 'Impossible (2)',

    'HOSPITALIZATION.MARRY.1': 'Married',
    'HOSPITALIZATION.MARRY.2': 'Single',

    'HOSPITALIZATION.ECONOMY.MIND.1': 'Stable',
    'HOSPITALIZATION.ECONOMY.MIND.2': 'Anxious',
    'HOSPITALIZATION.ECONOMY.MIND.3': 'Sad',
    'HOSPITALIZATION.ECONOMY.MIND.4': 'Angry',
    'HOSPITALIZATION.ECONOMY.MIND.5': 'Depressed',

    'HOSPITALIZATION.EDUCATION.WAY.1': 'Verbal',
    'HOSPITALIZATION.EDUCATION.WAY.2': 'Prints',
    'HOSPITALIZATION.EDUCATION.WAY.3': 'Demonstration',
    'HOSPITALIZATION.EDUCATION.WAY.4': 'Verbal + Prints',
    'HOSPITALIZATION.EDUCATION.CONTENTS.1': 'Disease',
    'HOSPITALIZATION.EDUCATION.CONTENTS.2': 'Treatment',
    'HOSPITALIZATION.EDUCATION.CONTENTS.3': 'Medication',
    'HOSPITALIZATION.EDUCATION.CONTENTS.4': 'Nutrition',

    'HOSPITALIZATION.LIFE.1': 'Independent',
    'HOSPITALIZATION.LIFE.2': 'Partial Help',
    'HOSPITALIZATION.LIFE.3': 'All Help',
    'HOSPITALIZATION.DESTINATION.1': 'Home',
    'HOSPITALIZATION.DESTINATION.2': 'Other Hospital',
    'HOSPITALIZATION.DESTINATION.3': 'Nursing Home',
    'HOSPITALIZATION.GUARDIAN.1': 'None',
    'HOSPITALIZATION.GUARDIAN.2': 'Spouse',
    'HOSPITALIZATION.GUARDIAN.3': 'Father',
    'HOSPITALIZATION.GUARDIAN.4': 'Mother',
    'HOSPITALIZATION.GUARDIAN.5': 'Children',

    // out hospital
    'OUT.HOSPITAL.DESTINATION.1': 'Home',
    'OUT.HOSPITAL.DESTINATION.2': 'Other Hospital',
    'OUT.HOSPITAL.DESTINATION.3': 'Nursing Home',

    'OUT.HOSPITAL.WAY.1': 'Private vehicle',
    'OUT.HOSPITAL.WAY.2': 'Public Transport',
    'OUT.HOSPITAL.WAY.3': 'Ambulance',
    'OUT.HOSPITAL.WAY.4': 'Care facility',

    'OUT.HOSPITAL.FOOD.1': 'Regular',
    'OUT.HOSPITAL.SHOWER.1': 'Shower',
    'OUT.HOSPITAL.SHOWER.2': 'Bath',
    'OUT.HOSPITAL.ACTIVITY.1': 'Limited',
    'OUT.HOSPITAL.ACTIVITY.2': 'No Limit',

    // clinical observation
    'CLINICAL.OBSERVATION.ADD.ROW':
      'There are values that have not been entered.',
    'CLINICAL.OBSERVATION.STOP': 'Up to 5 entries per virtual patient',
    'CLINICAL.OBSERVATION.ADD.NAN':
      'Please enter only numbers for your contact information.',

    // bedScore
    'BED.SCORE.SENSORY.PERCEPTION.1': 'Completely Limited',
    'BED.SCORE.SENSORY.PERCEPTION.2': 'Very Limited',
    'BED.SCORE.SENSORY.PERCEPTION.3': 'Slightly Limited',
    'BED.SCORE.SENSORY.PERCEPTION.4': 'No Impairment',
    'BED.SCORE.HUMIDITY.1': 'Constantly Moist',
    'BED.SCORE.HUMIDITY.2': 'Very Moist',
    'BED.SCORE.HUMIDITY.3': 'Occasionally Moist',
    'BED.SCORE.HUMIDITY.4': 'Rarely Moist',
    'BED.SCORE.ACTIVITY.1': 'Bedfast',
    'BED.SCORE.ACTIVITY.2': 'Chairfast',
    'BED.SCORE.ACTIVITY.3': 'Walks Occasionally',
    'BED.SCORE.ACTIVITY.4': 'Walks Frequently',
    'BED.SCORE.MOBILITY.1': 'Completely Immobile',
    'BED.SCORE.MOBILITY.2': 'Very limited',
    'BED.SCORE.MOBILITY.3': 'Slightly Limited',
    'BED.SCORE.MOBILITY.4': 'No Limitation',
    'BED.SCORE.NUTRITION.1': 'Very Poor',
    'BED.SCORE.NUTRITION.2': 'Probably Inadequate',
    'BED.SCORE.NUTRITION.3': 'Adequate',
    'BED.SCORE.NUTRITION.4': 'Excellent',
    'BED.SCORE.FRICTION.1': 'Problem',
    'BED.SCORE.FRICTION.2': 'Potential Problem',
    'BED.SCORE.FRICTION.3': 'No Apparent Problems',
    'REQUIRED.BED.SCORE': 'Please select all evaluation items.',

    // needs
    'REQUIRED.NEEDS.BODY.STATUS': '신체상태 항목을 모두 선택해주세요',

    // fall
    'FALL.1': 'No (0 points)',
    'FALL.15': 'Yes (15 points)',
    'FALL.25': 'Yes (25 points)',
    'FALL.20': 'Yes (20 points)',
    'FALL.WALKING.AIDS.1': 'None/on bedrest/nurse assists (0 point)',
    'FALL.WALKING.AIDS.15': 'Crunches/cane(s)/walker (15 points)',
    'FALL.WALKING.AIDS.30': 'furniture (30 points)',
    'FALL.GAIT.1': 'Normal/on bedrest/immobile (0 point)',
    'FALL.GAIT.10': 'Weak (10 points )',
    'FALL.GAIT.20': 'Impaired (20 points)',
    'FALL.CONSCIOUSNESS.1': 'Oriented To Own Ability (0 point)',
    'FALL.CONSCIOUSNESS.15': 'Forgets limitation (15 points)',
    'REQUIRED.FALL': 'Please select all evaluation items.',

    // 소아낙상
    'FALLSCALE.SCORE.AGE.1': 'less than 3 months, \n13 years old and above',
    'FALLSCALE.SCORE.AGE.2': 'More than 7  years old\n- less than 13 years old',
    'FALLSCALE.SCORE.AGE.3': 'More than 3  years old\n- less than 7 years old',
    'FALLSCALE.SCORE.AGE.4': 'More than 3 months\n- less than 3 years old ',

    'FALLSCALE.SCORE.GENDER.1': 'Female',
    'FALLSCALE.SCORE.GENDER.2': 'Male',

    'FALLSCALE.SCORE.DIANOSIS.1': 'Other Diagnosis',
    'FALLSCALE.SCORE.DIANOSIS.2': 'Psych/Behavioral Disorders',
    'FALLSCALE.SCORE.DIANOSIS.3':
      'Alterations in Oxygenation\n(Respiratory 3 Diagnosis, Dehydration,\n Anemia, Anorexia, Syncope/Dizziness, etc.)',
    'FALLSCALE.SCORE.DIANOSIS.4': 'Neurological Diagnosis ',

    'FALLSCALE.SCORE.COGNITIVE.1': 'Oriented to own Ability',
    'FALLSCALE.SCORE.COGNITIVE.2': 'Forget Limitations',
    'FALLSCALE.SCORE.COGNITIVE.3':
      'Not Aware of Limitations\nor less than 4 years old',

    'FALLSCALE.SCORE.ENVIRONMENTAL.1': 'Outpatient Area ',
    'FALLSCALE.SCORE.ENVIRONMENTAL.2':
      'Use Of A Brace\n(Wheelchair, Walker, Crutches)',
    'FALLSCALE.SCORE.ENVIRONMENTAL.3': 'History Of Falls ',

    'FALLSCALE.SCORE.HISTORY.1': 'More Than 48 Hours/None ',
    'FALLSCALE.SCORE.HISTORY.2': 'Within 48 Hours ',
    'FALLSCALE.SCORE.HISTORY.3': 'Within 24 Hours ',

    'FALLSCALE.SCORE.DRUG.1': 'Other Medications/None ',
    'FALLSCALE.SCORE.DRUG.2': 'One Of The Meds Listed Above',
    'FALLSCALE.SCORE.DRUG.3':
      'Multiple Usage of : \n(Hypnotics, Barbiturates, Phenothiazine,\nAntidepressants, Laxatives /Diuretics)',

    'REQUIRED.FALLSCALE.SCORE': 'Please select all evaluation items',

    // safety
    'SAFETY.DISCOVERY.PLACE.1': 'Ward',
    'SAFETY.DISCOVERY.PLACE.2': 'OPD',
    'SAFETY.DISCOVERY.PLACE.3': 'Stairs',
    'SAFETY.DISCOVERY.PLACE.4': 'Examination room',
    'SAFETY.DISCOVERY.PLACE.0': 'etc',

    'SAFETY.ACCIDENT.TYPE.1': 'Red light incident',
    'SAFETY.ACCIDENT.TYPE.2': 'Harmful incident',
    'SAFETY.ACCIDENT.TYPE.3': 'Proximity error',

    'SAFETY.ACCIDENT.CLASSIFICATION.1': 'Medication Error',
    'SAFETY.ACCIDENT.CLASSIFICATION.2': 'Fall or Injury',
    'SAFETY.ACCIDENT.CLASSIFICATION.3': 'Adverse Reaction',
    'SAFETY.ACCIDENT.CLASSIFICATION.4': 'Surgical Complication',
    'SAFETY.ACCIDENT.CLASSIFICATION.5': 'Equipment Failure',
    'SAFETY.ACCIDENT.CLASSIFICATION.0': 'etc',

    'SAFETY.EVENT.CLASSIFICATION.8':
      '[Red light incident]  level 8 : patient death',
    'SAFETY.EVENT.CLASSIFICATION.7':
      '[Red light incident] level 7 : near death situation of a patient  ',
    'SAFETY.EVENT.CLASSIFICATION.6':
      '[Red light incident] level 6 : incorrect site treatment and surgery; permanent damage',
    'SAFETY.EVENT.CLASSIFICATION.5':
      '[Harmful incident]  level 5 : Hospitalization due to temporary damage or extended hospitalization period',
    'SAFETY.EVENT.CLASSIFICATION.4':
      '[Harmful incident] level 4 :  Temporary damage requires intervention(Examination, internal/surgical treatment etc.)',
    'SAFETY.EVENT.CLASSIFICATION.3':
      '[Harmful incident] level 3 : Dose given to a patient and requires further observation',
    'SAFETY.EVENT.CLASSIFICATION.2':
      '[Harmful incident] level 2 : dose given to a patient without a further harm',
    'SAFETY.EVENT.CLASSIFICATION.1':
      '[Proximity error] level 1 : Accident did not affect a patient',
    'SAFETY.EVENT.CLASSIFICATION.0':
      '[Proximity error] level 0 : accidents with errors at risk',

    'SAFETY.TYPE.CONSCIOUSNESS.LEVEL.1': 'Alert',
    'SAFETY.TYPE.CONSCIOUSNESS.LEVEL.2': 'Drowsy',
    'SAFETY.TYPE.CONSCIOUSNESS.LEVEL.3': 'Confuse',
    'SAFETY.TYPE.CONSCIOUSNESS.LEVEL.4': 'Stupor',
    'SAFETY.TYPE.CONSCIOUSNESS.LEVEL.5': 'Coma',

    'SAFETY.TYPE.ACTIVITY.STATUS.1': 'Independent',
    'SAFETY.TYPE.ACTIVITY.STATUS.2': 'Wheelchair',
    'SAFETY.TYPE.ACTIVITY.STATUS.3': 'Walking aids',
    'SAFETY.TYPE.ACTIVITY.STATUS.4': 'Confined to bed/chair',

    'SAFETY.TYPE.ASSISTING.DEVICES.1': 'Wheelchair',
    'SAFETY.TYPE.ASSISTING.DEVICES.2': 'Walking Aids',
    'SAFETY.TYPE.ASSISTING.DEVICES.0': 'No Use',

    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.1': 'Room',
    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.2': 'Toilet',
    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.3': 'Shower room',
    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.4': 'Hallway',
    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.5': 'Test lab',
    'SAFETY.TYPE.PLACE.FALLING.ACCIDENT.0': 'etc',

    'SAFETY.PATIENT.RISK.FACTORS.1': 'Unsteady Gait',
    'SAFETY.PATIENT.RISK.FACTORS.2': 'Lost Balance',
    'SAFETY.PATIENT.RISK.FACTORS.3': 'Visual Impairment',
    'SAFETY.PATIENT.RISK.FACTORS.4': 'Bowel&Bladder Need',
    'SAFETY.PATIENT.RISK.FACTORS.5': 'Lighting',
    'SAFETY.PATIENT.RISK.FACTORS.6': 'Floor',
    'SAFETY.PATIENT.RISK.FACTORS.7': 'Cluttered Area',
    'SAFETY.PATIENT.RISK.FACTORS.8': 'Need Item Out Of Reach',

    'SAFETY.PATIENT.RISK.INJURY.1': 'No Injury',
    'SAFETY.PATIENT.RISK.INJURY.2': 'Pain',
    'SAFETY.PATIENT.RISK.INJURY.3': 'Bruising',
    'SAFETY.PATIENT.RISK.INJURY.4': 'Hematoma',
    'SAFETY.PATIENT.RISK.INJURY.5': 'Abrasion',
    'SAFETY.PATIENT.RISK.INJURY.6': 'Laceration',
    'SAFETY.PATIENT.RISK.INJURY.7': 'Sprain/Strain',
    'SAFETY.PATIENT.RISK.INJURY.8': 'Dislocation',
    'SAFETY.PATIENT.RISK.INJURY.9': 'Fracture',
    'SAFETY.PATIENT.RISK.INJURY.0': 'etc',

    'SAFETY.TYPE.FALL.TYPE.1': 'While using bed',
    'SAFETY.TYPE.FALL.TYPE.2': 'While using\nmedical equipment',
    'SAFETY.TYPE.FALL.TYPE.3': 'While walking',
    'SAFETY.TYPE.FALL.TYPE.4': 'While using chair',

    'SAFETY.TYPE.RISK.FACTOR.1': 'Broken bed wheels',
    'SAFETY.TYPE.RISK.FACTOR.2': 'Lower rail',
    'SAFETY.TYPE.RISK.FACTOR.3': 'Wet floors',
    'SAFETY.TYPE.RISK.FACTOR.4': 'Too big,\ntoo small shoes',
    'SAFETY.TYPE.RISK.FACTOR.5': 'Tripping on objects',
    'SAFETY.TYPE.RISK.FACTOR.0': 'etc',

    'SAFETY.TYPE.PRESCRIPTION.1': 'Dose',
    'SAFETY.TYPE.PRESCRIPTION.2': 'Patient',
    'SAFETY.TYPE.PRESCRIPTION.3': 'Paths',
    'SAFETY.TYPE.PRESCRIPTION.4': 'Medicine',
    'SAFETY.TYPE.PRESCRIPTION.0': 'etc',

    'SAFETY.TYPE.CONFIRM.1': 'Do not confirm',
    'SAFETY.TYPE.CONFIRM.2': 'No check on patient prior to administration',
    'SAFETY.TYPE.CONFIRM.3': 'No check on medication prior to administration',
    'SAFETY.TYPE.CONFIRM.4': 'No check on dose prior to administration',
    'SAFETY.TYPE.CONFIRM.5': 'No check on paths prior to administration',
    'SAFETY.TYPE.CONFIRM.6': 'No check on dosing card  prior to administration',
    'SAFETY.TYPE.CONFIRM.7': 'No check on expiration date',

    'SAFETY.TYPE.INTERPRETATION.1': 'Prescription interpretation error',
    'SAFETY.TYPE.INTERPRETATION.2': 'Medication card misinterpretation',

    'SAFETY.OTHER.TYPE.1': 'Adverse Reaction',
    'SAFETY.OTHER.TYPE.2': 'Surgical Complication',
    'SAFETY.OTHER.TYPE.3': 'Equipment Failure',
    'SAFETY.OTHER.TYPE.0': 'etc',

    'SAFETY.ACCIDENT.RESULT.1': 'Treatment needed',
    'SAFETY.ACCIDENT.RESULT.2': 'Need in further observation',
    'SAFETY.ACCIDENT.RESULT.3': 'No need in treatment and observation',

    // nrs
    'NRS.ADD.ROW': 'There are values that you have not entered.',

    // flacc
    'FLACC.ADD.ROW': 'There are values that you have not entered.',

    // KOOS2
    'KOOS.SYMPTOMS.1': 'Never',
    'KOOS.SYMPTOMS.2': 'Rarely',
    'KOOS.SYMPTOMS.3': 'Sometimes',
    'KOOS.SYMPTOMS.4': 'Often',
    'KOOS.SYMPTOMS.5': 'Always',

    'KOOS.STIFFNESS.1': 'None',
    'KOOS.STIFFNESS.2': 'Mild',
    'KOOS.STIFFNESS.3': 'Moderate',
    'KOOS.STIFFNESS.4': 'Severe',
    'KOOS.STIFFNESS.5': 'Extreme',

    'KOOS.PAIN.1': 'Never',
    'KOOS.PAIN.2': 'Monthly',
    'KOOS.PAIN.3': 'Weekly',
    'KOOS.PAIN.4': 'Daily',
    'KOOS.PAIN.5': 'Always',

    'KOOS.PAIN.TOW.1': 'None',
    'KOOS.PAIN.TOW.2': 'Mild',
    'KOOS.PAIN.TOW.3': 'Moderate',
    'KOOS.PAIN.TOW.4': 'Severe',
    'KOOS.PAIN.TOW.5': 'Extreme',

    'KOOS.DAILYFUNCTION.1': 'None',
    'KOOS.DAILYFUNCTION.2': 'Mild',
    'KOOS.DAILYFUNCTION.3': 'Moderate',
    'KOOS.DAILYFUNCTION.4': 'Severe',
    'KOOS.DAILYFUNCTION.5': 'Extreme',

    'KOOS.SPORTS.1': 'None',
    'KOOS.SPORTS.2': 'Mild',
    'KOOS.SPORTS.3': 'Moderate',
    'KOOS.SPORTS.4': 'Severe',
    'KOOS.SPORTS.5': 'Extreme',

    'KOOS.LIFEQUALITY.1': 'Never',
    'KOOS.LIFEQUALITY.2': 'Monthly',
    'KOOS.LIFEQUALITY.3': 'Weekly',
    'KOOS.LIFEQUALITY.4': 'Daily',
    'KOOS.LIFEQUALITY.5': 'Always',

    'KOOS.LIFEQUALITY.TWO.1': 'Not at all',
    'KOOS.LIFEQUALITY.TWO.2': 'Mildly',
    'KOOS.LIFEQUALITY.TWO.3': 'Moderately',
    'KOOS.LIFEQUALITY.TWO.4': 'Severely',
    'KOOS.LIFEQUALITY.TWO.5': 'Extremely',

    'KOOS.LIFEQUALITY.THREE.1': 'None',
    'KOOS.LIFEQUALITY.THREE.2': 'Mild',
    'KOOS.LIFEQUALITY.THREE.3': 'Moderate',
    'KOOS.LIFEQUALITY.THREE.4': 'Severe',
    'KOOS.LIFEQUALITY.THREE.5': 'Extreme',

    // STarTScreening
    'STARTSCREENING.SCORE.0': 'No(0point)',
    'STARTSCREENING.SCORE.1': 'Yes(1point)',

    'STARTSCREENING.SCORE.FIVE.0': 'Not at all (0 point)',
    'STARTSCREENING.SCORE.FIVE.1': 'Slightly (0 point)',
    'STARTSCREENING.SCORE.FIVE.2': 'Moderately (0 point)',
    'STARTSCREENING.SCORE.FIVE.3': 'Very much (1 point)',
    'STARTSCREENING.SCORE.FIVE.4': 'Extremely (1 point)',

    // bai
    'REQUIRED.BAI': '평가항목을 모두 선택해주세요',

    // transfusion
    'BLOOD.RECORD.SIDE.EFFECT.1': 'Y',
    'BLOOD.RECORD.SIDE.EFFECT.2': 'N',

    // dialysis
    'DIALYSIS.DIALYSIS_INFO.ROUTE.0': 'Other',
    'DIALYSIS.DIALYSIS_INFO.ROUTE.1': 'Outpatient',
    'DIALYSIS.DIALYSIS_INFO.ROUTE.2': 'Emergency room',
    'DIALYSIS.DIALYSIS_INFO.ROUTE.3': 'Hospitalization',

    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.0': 'Other',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.1': 'AVF',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.2': 'AVG',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.3': 'Perm C',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.4': 'JVC',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.5': 'SVC',
    'DIALYSIS.DIALYSIS_INFO.BLOOD_VESSEL.6': 'FVC',

    // emergency
    'EMERGENCY.ACCIDENT_TYPE.1': 'urgent',
    'EMERGENCY.ACCIDENT_TYPE.2': 'emergency',
    'EMERGENCY.ACCIDENT_TYPE.3': 'non-emergency',

    'EMERGENCY.DIVISION.1': 'Accident',
    'EMERGENCY.DIVISION.2': 'Disaster',
    'EMERGENCY.DIVISION.3': 'acute disease',
    'EMERGENCY.DIVISION.4': 'the rapid deterioration of chronic diseases',
    'EMERGENCY.DIVISION.0': 'Etc.',

    // childbirth
    'CHILDBIRTH.BABY_STATUS.GENDER.1': 'Boy',
    'CHILDBIRTH.BABY_STATUS.GENDER.2': 'Girl',

    'CHILDBIRTH.YES_OR_NO.1': 'N',
    'CHILDBIRTH.YES_OR_NO.2': 'Y',

    'CHILDBIRTH.PLACENTA_REMOVAL.METHOD.1': 'Natural Separation',
    'CHILDBIRTH.PLACENTA_REMOVAL.METHOD.2': 'Water Separation',

    'EMERGENGY.RESULT.0': 'Admission',
    'EMERGENGY.RESULT.1': 'Transfer',
    'EMERGENGY.RESULT.2': 'Home',
    'EMERGENGY.RESULT.3': 'Dead',
    'EMERGENGY.RESULT.4': 'etc',

    // home_care
    'HOME_CARE.INSURANCE_TYPE.1': 'Insurance',
    'HOME_CARE.INSURANCE_TYPE.2': 'Benefits',
    'HOME_CARE.INSURANCE_TYPE.3': 'Veteran',

    'HOME_CARE.RESIDENCE.1': 'Apt',
    'HOME_CARE.RESIDENCE.2': 'Union',
    'HOME_CARE.RESIDENCE.3': 'Single',

    'HOME_CARE.SANITARY.1': 'Good',
    'HOME_CARE.SANITARY.2': 'Faulty',

    'HOME_CARE.SAFETY.1': 'Good',
    'HOME_CARE.SAFETY.2': 'Faulty',

    // diet_nutrition
    'DIET_NUTRITION.CLASSIFICATION.1': '환자',
    'DIET_NUTRITION.CLASSIFICATION.2': '보호자',
  },
};

export default locale;
