import MuiDialog from 'components/MuiDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const PersonalInfo = ({ isOpen, onClose }: Props) => (
  <MuiDialog.Confirm
    maxWidth="md"
    isOpen={isOpen}
    title="Consent to collect and use personal information"
    message={`
    DKMediInfo (after this referred to as "company") establishes and discloses the following personal information processing guidelines to protect the personal information of the information subject and to handle grievances quickly and smoothly in accordance with Article 30 of the Personal Information Protection Act

    Article 1(Purpose of Personal Information Processing)
    The company processes personal information for the following purposes. The personal data being processed is not used for any purpose other than the next. If the Purpose of use is changed, necessary measures will be implemented, such as obtaining separate consent under Article 18 of the Personal Information Protection Act.
    1. Personal information is processed to confirm the intention to sign up for and manage membership, maintain and manage membership, maintain and manage membership, prevent the illegal use of services, check the consent of legal representatives, and handle grievances.
    2. Personal information is processed to deliver goods or services, provide services, send contracts and bills, provide content, provide customized services, self-authentication, age verification, payment and settlement, and collect bonds.
    3. Personal information is processed to identify complaints, confirm complaints, contact and notify them for fact-finding, and inform them of the processing results.

    Article 2
    (Processing and Retention Period of Personal Information)
    ① The company processes and retains personal information within the period of personal information retention, use period, or use period agreed upon when collecting personal information from the information subject under laws and regulations.
    ② The processing and retention period of each personal information is as follows.

    1. Membership and management of the website: Until withdrawal from the website of the business operator/organization: Provided, That in the following cases, until the end of the relevant grounds:
    1) Where an investigation, investigation, etc., is in progress due to violation of relevant statutes until the completion of the inquiry, investigation, etc.
    2) If a bond or debt relationship remains due to the use of the website until the settlement of the relevant bond or debt relationship is completed.

    2. Provision of goods or services: Until the supply of goods and services and the payment and settlement of charges are completed, provided, however, that in the following cases, until the end of the relevant period:
    1) Records of transactions, such as labeling, advertising, contract details, performance, etc., under the Consumer Protection Act in Electronic Commerce, etc.
    - Records of display and advertisement: June
    - Record of withdrawal of contract or subscription, payment, supply of goods, etc.: 5 years
    - Records of the handling of consumer complaints or disputes: 3 years
    2) Preservation of communication fact confirmation data under Article 41 of the Communications Secret Protection Act;
    - Subscriber telecommunication date, start and end time, counterparty subscriber number, frequency of use, location tracking data of source station: 1 year
    - Computer communication, Internet log records, access tracking data: 3 months

    Article 3 (Provision of Personal Information to a Third Party)	
    ① The company only processes the personal information of the data subject within the scope specified in Article 1 (Purpose of Personal Information Processing) and provides personal information to third parties only if it falls under Article 17 of the Personal Information Protection Act, such as consent of the data subject and special provisions of the law.
    ② The company provides personal information to third parties as follows.
    - Recipients of personal information: Naver Cloud, Amazon Web Service, Microsoft Azure, AhnLab Cloud
    - Purpose of using the personal information of the recipient: Development of a nursing information program
    - Personal information items provided: Name, address, phone number, email address, card payment account information
    - Retention and use period of the recipient: the period of use period

    Article 4 (Consignment of Personal Information Processing)
    ① The company entrusts the personal information processing work as follows for smooth personal information processing.
    1. Operation of the Program Development center
    - Consignee (trustee): Naver Cloud, Amazon Web Service, Microsoft Azure, AhnLab Cloud
    - Contents of entrusted work: Related to product development
    ② In accordance with Article 25 of the Personal Information Protection Act, the company stipulates in the contract documents the prohibition of personal information processing, technical and administrative protection measures, re-entrustment restrictions, management and supervision of trustees, and damages.
    ③ If the details of the entrusted work or the trustee changes, we will disclose it without delay through this personal information processing policy.

    Article 5 (Rights of users and legal representatives and how to exercise them)
    ① The data subject may exercise the following rights related to the protection of personal information against the company at any time:
    1. Request for personal information access
    2. Request correction in case of errors, etc.
    3. Request for deletion
    4. Request for suspension of processing
    ② The exercise of rights under paragraph (1) may be conducted by writing, telephone, e-mail, or fax to the company, and the company will take action without delay.
    ③ If the data subject requests correction or deletion of errors in personal information, the company will not use or provide the personal information until the correction or deletion is completed.
    ④ The proper exercise under paragraph (1) may be conducted through an agent, such as a legal representative of the data subject or a person delegated.
    In this case, you must submit a power of attorney in accordance with the attached Form 11 of the Enforcement Rules of the Personal Information Protection Act.
    ⑤ Information subjects shall not infringe on the personal information and privacy of themselves or others handled by the company in violation of related laws and regulations such as the Personal Information Protection Act.

    Article 6 (Personal Information Items to be Processed)
    The company is processing the following personal information items.
    1. Essential items for membership and management on the website: Name, date of birth, ID, password, address, phone number, gender, email address, iPin number, university, school number, significant selection: marital status, the field of interest
    2. Required items for goods or services: payment information such as name, date of birth, ID, password, address, phone number, e-mail address, iPin number, credit card number, bank account information, etc., college, student number, major selection: field of interest, past purchase details
    3. The following personal information items can be automatically generated and collected during the process of using the Internet service. IP address, cookie, MAC address, service usage record, visit record, defective usage record, etc.

    Article 7 (Destruction of Personal Information)
    ① The company destroys personal information without delay when personal information becomes unnecessary, such as the lapse of the personal information retention period or the achievement of the Purpose of processing.
    ② If the personal information retention period agreed upon by the data subject has elapsed or the Purpose of processing has been achieved, the personal information should be transferred to a separate database (DB) or stored at a different location.
    ③ The procedures and methods for destroying personal information are as follows.
    1. Destruction procedure The company selects personal information in which the reason for destruction occurs and destroys personal information with the approval of the company's information protection manager.
    2. Destruction method The company destroys personal information recorded and stored in electronic file form using a low-level format, etc., and destroys personal information recorded and stored in paper documents by grinding or incinerating it with a shredder.

    Article 8 (Measures to ensure the safety of personal information)
    The company is taking the following measures to ensure the safety of personal information.
    1. Management measures: Establishment and implementation of internal management plans, regular employee training, etc.
    2. Technical measures: Management of access rights to personal information processing systems, installation of access control systems, encryption of unique identification information, installation of security programs, and application of cloud VPCs.
    3. Physical measures: Control access to computer rooms, data storage rooms, etc.

    Article 9 (Matters concerning the installation, operation, and rejection of automatic personal information collection devices)
    ① The company uses a 'cookie' that stores user information and brings it up from time to time to provide individually customized services to users.
    ② Cookies are a small amount of information that the server (HTTP) used to run the website sends to the user's computer browser and is sometimes stored on the user's hard disk in their computer.
    A. Purpose of use of cookies: It provides optimized information to users by identifying the type of visit and use of each service and website visited by the user, popular search terms, security access, etc.
    B. Installation, operation, and rejection of cookies: You can refuse to save cookies by setting options on the Tools > Internet Options > Personal Information menu at the top of the web browser.
    C. If you refuse to save cookies, you may have difficulty using customized services.

    Article 10 (Personal Information Protection Officer)
    ① The company is responsible for personal information processing and designates a person in charge of personal information protection to handle complaints and remedy damages of the information subject to personal information processing.
    ▶ Name of the person in charge of personal information protection: Lee Dong-kyun Position: Representative Contact: nurse@smartnurse.co.kr
    ※ You will be connected to the privacy department.
    ▶ Department name of the department in charge of personal information protection: Representative person: Lee Dong-kyun Contact information: nurse@smartnurse.co.kr
    ② The information subject can contact the personal information protection manager and the department in charge of all confidential information protection inquiries, complaints, and damage relief that occurred while using the company's services (or business). The company will answer and process the information subject's inquiries without delay.

    Article 11 (Request for access to personal information)
    The information subject may request the following department to view personal information pursuant to Article 35 of the Personal Information Protection Act. The company will try to expedite the data subject's request for personal information access.
    ▶ Department of receiving and processing requests for personal information access:
    - Representative: Dong-kyun Lee
    - Contact : nurse@smartnurse.co.kr

    Article 12 (How to Relief for Infringement of Rights) 
    Information subjects can inquire about damage relief and counseling for personal information infringement from the following institutions.
    ▶ Personal Information Infringement Report Center (operated by Korea Internet & Security Agency)
    - Responsible work: Reporting personal information infringement and applying for counseling
    - Homepage: privacy.kisa.or.kr
    - Phone: 118 (without country code)
    - Address: (58324) 9, Jinheung-gil, Naju-si, Jeollanam-do, Republic of Korea
    ▶ Personal Information Dispute Mediation Committee
    - Responsible work: Application for personal information dispute settlement, collective dispute settlement (civil settlement)
    - Homepage: www.kopico.go.kr
    - Phone: 1833-6972(without country code)
    - Address: (03171) 209, Sejong-daero, Jongno-gu, Seoul, Republic of Korea
    ▶ Cyber Crime Investigation Team of the Supreme Prosecutors' Office: 02-3480-3573 (www.spo.go.kr)
    ▶ National Police Agency Cyber Safety Bureau: 182 (http://cyberbureau.police.go.kr)

    Article 13 (Enforcement and Change of Personal Information Processing Policy) 
    This Personal Information Processing Policy will apply from August 15, 2021.

    `}
    onClose={onClose}
  />
);

export default PersonalInfo;
