import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IHomeCareRecord } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';
import MuiTable from 'components/MuiTable';
import MuiTextField from 'components/Form/MuiTextField';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const InspectionFindings = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const inspectionFindingsList: IHomeCareRecord[] = watch(
    'inspection_findings'
  );

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);
  const [content, setContent] = useState('');

  const columns = [
    { fieldId: 'date', label: '', sx: { width: '20%' } },
    { fieldId: 'content', label: '', sx: { width: '72%' } },
    { fieldId: 'action', label: '', sx: { width: 100 } },
  ];

  const onAddRow = () => {
    const request = { date, content };

    if (Object.values(request).filter(v => !v).length > 0) {
      return onRequired('CLINICAL.OBSERVATION.ADD.ROW');
    }

    onSuccess('Inspection findings have been added.');
    setValue(
      'inspection_findings',
      inspectionFindingsList ? [...inspectionFindingsList, request] : [request]
    );
    setDate('');
    setContent('');
  };

  const inputRow = {
    id: 'add-inspection-findings',
    date: (
      <Form.MuiTextField
        type="date"
        label="Please enter a date"
        InputLabelProps={{ shrink: true }}
        required={false}
        value={date}
        defaultValue={materialDateInput}
        disabled={disabled}
        onChange={({ target: { value } }) => setDate(value)}
      />
    ),
    content: (
      <MuiTextField
        required={false}
        disabled={disabled}
        value={content}
        onChange={({ target: { value } }) => setContent(value)}
      />
    ),
    action: (
      <Button variant="contained" size="small" onClick={onAddRow}>
        ADD
      </Button>
    ),
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'inspection_findings',
      inspectionFindingsList.filter((_, i) => i !== index)
    );
  };

  const displayRows = inspectionFindingsList
    ? inspectionFindingsList.map((item, i) => ({
        ...item,
        id: i,
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];
  const tableRow = disabled ? displayRows : [inputRow, ...displayRows];

  return (
    <Fragment>
      <SectionTitle title="Inspection Findings" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={[...tableRow]} />
      </Grid>
    </Fragment>
  );
};

export default InspectionFindings;
