import { useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import { Grid, Typography } from '@mui/material';

import SectionTitle from '../../components/SectionTitle';
import MuiTable from 'components/MuiTable';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const WeightInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  const columns = [
    { fieldId: 'division', label: 'Sorting', sx: { width: 220 } },
    { fieldId: 'prev', label: 'Previous Weight' },
    { fieldId: 'today', label: 'Today’s Weight' },
    { fieldId: 'diff', label: 'Weight Change' },
  ];

  const rows = [
    {
      id: 'pre',
      division: (
        <Typography
          noWrap
          variant="caption"
          fontWeight={500}
          lineHeight="38px"
          fontSize="12px"
        >
          Pre
        </Typography>
      ),
      prev: (
        <Form.MuiTextField
          {...register('pre_previous_weight')}
          required={false}
          disabled={disabled}
        />
      ),
      today: (
        <Form.MuiTextField
          {...register('pre_today_weight')}
          required={false}
          disabled={disabled}
        />
      ),
      diff: (
        <Form.MuiTextField
          {...register('pre_weight_change')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      id: 'post',
      division: (
        <Typography
          noWrap
          variant="caption"
          fontWeight={500}
          lineHeight="38px"
          fontSize="12px"
        >
          Post
        </Typography>
      ),
      prev: (
        <Form.MuiTextField
          {...register('post_previous_weight')}
          required={false}
          disabled={disabled}
        />
      ),
      today: (
        <Form.MuiTextField
          {...register('post_today_weight')}
          required={false}
          disabled={disabled}
        />
      ),
      diff: (
        <Form.MuiTextField
          {...register('post_weight_change')}
          required={false}
          disabled={disabled}
        />
      ),
    },
  ];

  return (
    <>
      <SectionTitle title="Weight Information" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={[...rows]} />
      </Grid>
    </>
  );
};

export default WeightInfo;
