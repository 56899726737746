import { Fragment } from 'react';
import Form from 'components/Form';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const Situation = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="S (Situation)" />

      <RowContainer xs={12}>
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Form.MuiTextField
            required={false}
            fullWidth
            multiline
            minRows={3}
            disabled={disabled}
            placeholder="Enter"
            sx={{ marginBottom: '20px', marginTop: '10px' }}
            {...register('situation_input')}
          />
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Situation;
