import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const CVCfive = (props: Props) => {
  const { disabled, register } = props;

  const info = [
    'If bleeding persists at the insertion site.',
    'If the insertion site turns red or shows signs of pus or discharge.',
    'If there is warmth or pain at the insertion site.',
    'If there is numbness or pain in the insertion site, shoulder, or arm.',
    'If there is swelling in the face, shoulder, or arm on the side of the insertion.',
    'If the catheter becomes longer or is dislodged.',
    'If the suture site has opened.',
    'If the catheter is damaged (torn or has a hole).',
    'If the temperature rises above 38 degrees Celsius. ',
  ];

  return (
    <Fragment>
      <SectionTitle title="5. Situations that should be reported to the medical staff." />
      <Box sx={{ margin: '38px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          {info.map(a => {
            return (
              <Grid item xs={11.5}>
                <Typography sx={{ fontSize: '14px', lineHeight: '30px' }}>
                  • {a}
                </Typography>
              </Grid>
            );
          })}
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '30px',
              fontWeight: 500,
              marginTop: '40px',
            }}
          >
            I have understood the explanation sufficiently and consent to the
            insertion of the central venous catheter.
          </Typography>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CVCfive;
