import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
// import { ReactComponent as ChannelTalk } from '../../../assets/channelTalk.svg';

interface Props {
  title: string;
  isOpen?: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
}

function Confirm(props: Props) {
  const { title, isOpen = false, maxWidth = 'xs', onClose } = props;
  const navigate = useNavigate();

  return (
    <Dialog maxWidth={maxWidth} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            onClick={onClose}
            sx={{ marginRight: '-20px', color: 'black' }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
          Forgot your Email?
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          whiteSpace: 'pre-line',
          wordBreak: 'keep-all',
          lineHeight: '24px',
        }}
      >
        <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
          1. If you only sign up on the website :
        </Typography>
        Membership registration at ENR is required
        <br /> separately from membership registration
        <br />
        on the website!
        <div style={{ marginTop: '15px', marginBottom: '20px' }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/signup')}
          >
            Smartnurse ENR Sign up
          </Button>
        </div>
        <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
          2. If you’ve registered as an ENR member, <br />
          but forgot your ID :
        </Typography>
        Please click the “Contact Us” button below, and
        <br />
        fill out your school, student number and name.
        <div style={{ marginTop: '1rem', marginBottom: '30px' }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://e1p4z.channel.io/home')}
          >
            CONTACT US
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Confirm;
