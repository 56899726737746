import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const PlacentaRemoval = (props: Props) => {
  const { disabled, getValues, setValue, watch } = props;

  const contents = [
    {
      label: 'Time',
      element: (
        <MobileTimePicker
          disabled={disabled}
          value={watch('placenta_removal.time') || null}
          onChange={v => setValue('placenta_removal.time', v)}
          renderInput={params => (
            <MuiTextField
              {...params}
              required={false}
              placeholder="Choose Time"
              InputProps={{ endAdornment: <AccessTime /> }}
              fullWidth={false}
            />
          )}
        />
      ),
    },
    {
      label: 'Methods',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.PLACENTA_REMOVAL.METHOD"
          values={[1, 2]}
          defaultValue={getValues('placenta_removal.methods')}
          onChange={v => setValue('placenta_removal.methods', v)}
          width="160px"
        />
      ),
    },
    {
      label: 'Curettage',
      element: (
        <Form.MuiRadioGroup
          disabled={disabled}
          i18nKey="CHILDBIRTH.YES_OR_NO"
          values={[1, 2]}
          defaultValue={getValues('placenta_removal.curettage')}
          onChange={v => setValue('placenta_removal.curettage', v)}
          width="100px"
        />
      ),
    },
  ];
  return (
    <>
      <SectionTitle title="Placenta Removal" />
      <RowContainer xs={14}>
        {contents.map(({ label, element }) => {
          return (
            <>
              <RowContent title={label} titleRatio={0.8} childrenRatio={3.2}>
                {element}
              </RowContent>
            </>
          );
        })}
      </RowContainer>
    </>
  );
};

export default PlacentaRemoval;
