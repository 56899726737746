import { Fragment, useState } from 'react';
import { Grid } from '@mui/material';
import regex from 'utils/regex';

import Form from 'components/Form';
import MuiTable from 'components/MuiTable';
import { IFormRegister, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormWatch {
  disabled?: boolean;
}

const Medicines = (props: Props) => {
  const { disabled, register, watch } = props;

  const columns = [
    { fieldId: 'name', label: 'Medication Name' },
    { fieldId: 'amount', label: 'Medication Content' },
    { fieldId: 'count', label: 'Frequency' },
    { fieldId: 'days', label: 'Days' },
    { fieldId: 'how_to', label: 'Usage' },
  ];
  const rows = Array.from({ length: 5 }, (_, i) => {
    const prefix = `out_hospital_medicines.${i}`;

    return {
      id: i,
      name: (
        <Form.MuiTextField
          fullWidth
          disabled={disabled}
          {...register(`${prefix}.name`)}
        />
      ),
      amount: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.amount`)}
        />
      ),
      count: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.count`, {
            required: false,
          })}
        />
      ),
      days: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.days`, { required: false })}
        />
      ),
      how_to: (
        <Form.MuiTextField
          disabled={disabled}
          {...register(`${prefix}.how_to`)}
        />
      ),
    };
  });

  return (
    <Fragment>
      <SectionTitle title="Discharge Medication" />

      <RowContainer xs={12}>
        <Grid item xs={12}>
          <MuiTable columns={columns} rows={rows} />
        </Grid>
      </RowContainer>
    </Fragment>
  );
};

export default Medicines;
