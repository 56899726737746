import { Typography, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const contents = [
  'In some situations there may be other choices to a blood transfusion and these include-fluid replacement with saline or other artificial compounds and/or iron supplements.',
  'Your Doctor will discuss these with yor as some choices are not suitable for everybody.',
  '(Doctor to document in space provided. Continue in Medical Record if necessary.)',
];

const contents2 = [
  '(Doctor to document in space provided. Continue in Medical Record if necessary.)',
];

const DEContents = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="D. Other Relevant Treatment Options" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => (
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '38px',
              whiteSpace: 'pre',
            }}
          >
            {v}
          </Typography>
        ))}
      </Box>

      <SectionTitle title="E. Risks of Not Having the blood and/or Blood Products Transfusion" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents2.map((v, i) => (
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '38px',
              whiteSpace: 'pre',
            }}
          >
            {v}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default DEContents;
