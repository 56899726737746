import MuiDialog from 'components/MuiDialog';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const Complete = ({ isOpen, onClose }: Props) => (
  <MuiDialog.Confirm
    isOpen={isOpen}
    title="Member Registration Application completed"
    message="It takes up to 3 days for approval"
    onClose={onClose}
  />
);

export default Complete;
