import { IFormRegister } from 'routes/Main/type';
import { Box, Typography, useTheme } from '@mui/material';

import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;
  const { palette } = useTheme();

  const labels = [
    { title: 'Signature of Patient', variable: 'patient_sig' },
    {
      title: 'Relationship to Patient(If Not Patient)',
      variable: 'relationship',
    },
    { title: 'Date', variable: 'date' },
  ];

  const labels2 = [
    { title: 'Signature of Physician', variable: 'dr_sig' },
    { title: 'Date', variable: 'dr_date' },
  ];

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  return (
    <>
      <RowContainer xs={12} mb={3}>
        {labels.map(({ title, variable }, _) => (
          <>
            {variable === 'date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={0.5}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  type="date"
                  disabled={disabled}
                  defaultValue={materialDateInput}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : variable === 'sig' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.4}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={2.2}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}

        <SectionTitle title="G. Doctor’s Statement" mb={1} />
        <Typography
          sx={{
            margin: '20px auto 0px 35px',
            width: '98%',
            fontSize: '14px',
            whiteSpace: 'pre-wrap',
          }}
        >
          I acknowledge that I have explained to the patient all the above
          points under the Patient Consent Section(F) and I am of the opinion
          that the patient/families has fully understood the
          <br />
          informaion
        </Typography>
        {labels2.map(({ title, variable }, _) => (
          <>
            {variable === 'dr_date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={0.5}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  type="date"
                  disabled={disabled}
                  defaultValue={materialDateInput}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.4}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default Signature;
