import { Typography, Box } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import SectionTitle from './SectionTitle';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const contents = [
  'In your case, there are currently the following specific conditions.',
];

const MethodandProcess = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <SectionTitle title="3. Special considerations" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => (
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {v}
          </Typography>
        ))}
        <Form.MuiTextField
          placeholder="Enter"
          multiline
          required={false}
          minRows={3}
          disabled={disabled}
          {...register('no4')}
        />
        <Typography
          sx={{ margin: '30px 0 20px 0', fontSize: '12px', fontWeight: 500 }}
        >
          I understand the necessity of the endotracheal intubation procedure,
          the method of the procedure, as well as the potential sequelae and
          complications associated with the
          <br />
          procedure. Therefore, I hereby request to undergo this procedure.
          Additionally, I pledge to diligently follow all instructions related
          to the procedure and fully cooperate with the
          <br />
          directives and judgments of the attending physician and the
          proceduralist.
        </Typography>
      </Box>
    </>
  );
};

export default MethodandProcess;
