import Form from 'components/Form';
import { Fragment } from 'react';
import { Stack } from '@mui/material';

import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContent from '../components/RowContent';
import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

import CheckboxGroup from './components/MuiCheckboxEducation';

import SubMuiRadio from './MuiRadioGroupSub';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const Education = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="Education" />
      <RowContainer xs={12}>
        <RowContent
          title="Educational Method"
          titleRatio={1.78}
          childrenRatio={10}
        >
          <SubMuiRadio
            i18nKey="HOSPITALIZATION.EDUCATION.WAY"
            values={[1, 2, 3, 4]}
            disabled={disabled}
            defaultValue={getValues('education.education_way')}
            onChange={v => setValue('education.education_way', v)}
          />
        </RowContent>
        <RowContent
          title="Educational Contents"
          titleRatio={1.78}
          childrenRatio={10}
        >
          <Stack direction="row" spacing={1}>
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.EDUCATION.CONTENTS"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('education.education_contents.checked')}
              onChange={v =>
                setValue('education.education_contents.checked', v)
              }
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Enter"
              sx={{ width: '150px' }}
              {...register('education.education_contents.input')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Education;
