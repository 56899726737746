import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography, Box } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import { SurveyDialogProps, TSBAR2DefaultValues } from '../type';
import { updateCRRT, updateSBAR2 } from 'apis/survey';

import CommonPatientInfo from '../components/CommonPatientInfo';
import Situation from './Situation';
import Background from './Background';
import Signature from './Signature';
import Assessment from './Assessment';
import Recommendation from './Recommendation';
import Barcode from './Barcode';

const SBAR2 = (props: SurveyDialogProps<TSBAR2DefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TSBAR2DefaultValues) => {
    const {
      situation1,
      situation1_input1,
      situation2,
      situation2_input,

      background_infection,
      background_allergy,
      background_allergy_input1,
      background_allergy_input2,
      background_input,

      assessment,
      assessment_input1,

      recommendation1_input1,
      recommendation1,
      recommendation2_monitor_o2,
      recommendation2_monitor_o2_input,
      recommendation2_monitor_patients,
      recommendation2_monitor_etc_input,
      recommendation2_etc_input,

      sending,
      subject,
      takeover,
      consignee,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      sbar2_survey: {
        situation1: JSON.stringify(situation1),
        situation1_input1,
        situation2: JSON.stringify(situation2),
        situation2_input,

        background_infection: JSON.stringify(background_infection),
        background_allergy: JSON.stringify(background_allergy),
        background_allergy_input1,
        background_allergy_input2,
        background_input,

        assessment: JSON.stringify(assessment),
        assessment_input1,

        recommendation1_input1,
        recommendation1: JSON.stringify(recommendation1),
        recommendation2_monitor_o2: JSON.stringify(recommendation2_monitor_o2),
        recommendation2_monitor_o2_input,
        recommendation2_monitor_patients: JSON.stringify(
          recommendation2_monitor_patients
        ),
        recommendation2_monitor_etc_input,
        recommendation2_etc_input,

        sending,
        subject,
        takeover,
        consignee,
      },
    };

    updateSBAR2(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('SBAR2 has been saved successfully.');
      })
      .catch(e => {
        onFail('Failed to save SBAR2.', e);
      });
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Typography
        sx={{
          margin: '40px auto 40px auto',
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        SBAR2
      </Typography>
      <Barcode {...formProps} patientInfo={patientInfo} />
      <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
      <Box sx={{ marginTop: '48px' }}>
        <Situation {...formProps} />
        <Background {...formProps} />
        <Assessment {...formProps} />
        <Recommendation {...formProps} />
        <Signature {...formProps} />
      </Box>
    </MuiDialog.SurveyForm>
  );
};

export default SBAR2;
