import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography, Box } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import { SurveyDialogProps, TCentralVenousDefaultValues } from '../../type';
import { updateCentralVenous } from 'apis/survey';

import CommonPatientInfo from '../../components/CommonPatientInfo';
import CVCone from './CVCone';
import CVCtwo from './CVCtwo';
import CVCthree from './CVCthree';
import CVCfour from './CVCfour';
import CVCfive from './CVCfive';
import Signature from './Signature';

const CentralVenous = (
  props: SurveyDialogProps<TCentralVenousDefaultValues>
) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TCentralVenousDefaultValues) => {
    const {
      no1,

      pt_bday,
      pt_contact,
      pt_name,
      pt_sign,

      nok_bday,
      nok_contact,
      nok_name,
      nok_sign,

      dr_name,
      dr_sign,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      cvc_insertion_confirmation: {
        no1: JSON.stringify(no1),

        pt_bday,
        pt_contact,
        pt_name,
        pt_sign,

        nok_bday,
        nok_contact,
        nok_name,
        nok_sign,

        dr_name,
        dr_sign,
      },
    };

    // console.log(request);

    updateCentralVenous(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('CVC Insertion has been saved successfully.');
      })
      .catch(e => {
        onFail('Failed to save CVC Insertion', e);
        // console.log(e);
      });
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Typography
        sx={{
          margin: '40px auto 40px auto',
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        Central venous catheter insertion Consent
      </Typography>
      <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
      <Box sx={{ marginTop: '48px' }}>
        <CVCone {...formProps} />
        <CVCtwo {...formProps} />
        <CVCthree {...formProps} />
        <CVCfour {...formProps} />
        <CVCfive {...formProps} />
        <Signature {...formProps} />
      </Box>
    </MuiDialog.SurveyForm>
  );
};

export default CentralVenous;
