import { Fragment, useState } from 'react';
import {
  Box,
  Grid,
  RadioGroup,
  Radio,
  Typography,
  FormHelperText,
} from '@mui/material';
import { StyledFormControlLabelAPGAR } from 'routes/Main/style';
import GridItem from '../../components/GridItem';
import SectionTitle from '../../components/SectionTitle';
import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormValues, IFormWatch, IFormRegister } from 'routes/Main/type';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const radioGroups = [
  {
    text: 'Appearance',
    name: 'appearance',
    labels: ['Blue/pale', 'Blue extremities, pink body', 'All pink'],
  },
  {
    text: 'Pulse',
    name: 'pulse',
    labels: ['Absent', '<100 BPM', '≥100 BPM'],
  },
  {
    text: 'Grimace',
    name: 'grimace',
    labels: ['None', 'Grimace', 'Sneeze/Cough'],
  },
  {
    text: 'Activity',
    name: 'activity',
    labels: ['Limp', 'Some extremity flexion', 'Active'],
  },
  {
    text: 'Respiration',
    name: 'respiration',
    labels: ['Absent', 'Irregular/slow', 'Good/crying'],
  },
];

const ApgarScore = (props: Props) => {
  const { disabled, getValues, setValue, register } = props;

  const [errors, setErrors] = useState({
    one_min: false,
    five_min: false,
  });

  return (
    <>
      <SectionTitle title="APGAR Score" />
      <Box sx={{ width: '90%', margin: '48px auto 12px auto' }}>
        <Grid container spacing={1}>
          <GridItem bgColor="#0000001F" text="APGAR" />
          <GridItem bgColor="#0000001F" text="0 point" />
          <GridItem bgColor="#0000001F" text="1 point" />
          <GridItem bgColor="#0000001F" text="2 point" />

          {radioGroups.map(({ text, name, labels }) => (
            <Fragment key={name}>
              <GridItem bgColor="#0000001F" text={text} />
              <RadioGroup
                row
                sx={{
                  width: '75%',
                  display: 'flex',
                  whiteSpace: 'pre',
                  justifyContent: 'space-around',
                }}
                defaultValue={getValues(`apgar.${name}`)}
                onChange={e => setValue(`apgar.${name}`, e.target.value)}
              >
                <StyledFormControlLabelAPGAR
                  disabled={disabled}
                  value={1}
                  label={labels[0]}
                  control={<Radio />}
                />
                <StyledFormControlLabelAPGAR
                  disabled={disabled}
                  value={2}
                  label={labels[1]}
                  control={<Radio />}
                />
                <StyledFormControlLabelAPGAR
                  disabled={disabled}
                  value={3}
                  label={labels[2]}
                  control={<Radio />}
                />
              </RadioGroup>
            </Fragment>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          width: '90%',
          margin: '0px auto',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            width: '50%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography fontSize="14px">1min</Typography>
          <Box>
            <Form.MuiTextField
              sx={{ width: '250px' }}
              placeholder="0~10 point"
              required={false}
              disabled={disabled}
              error={errors.one_min}
              {...register('newborn_condition.apgar_score1m', {
                onChange: e => {
                  if (e.target.value < 0 || e.target.value > 10)
                    setErrors({ ...errors, one_min: true });
                  else setErrors({ ...errors, one_min: false });
                },
              })}
            />
            {errors.one_min ? (
              <FormHelperText error={true}>
                The Apgar score is greater than 1 and less than 10
              </FormHelperText>
            ) : null}
          </Box>
          <Typography fontSize="14px">5min</Typography>
          <Box>
            <Form.MuiTextField
              sx={{ width: '250px' }}
              placeholder="0~10 point"
              required={false}
              disabled={disabled}
              error={errors.five_min}
              {...register('newborn_condition.apgar_score5m', {
                onChange: e => {
                  if (e.target.value < 0 || e.target.value > 10)
                    setErrors({ ...errors, five_min: true });
                  else setErrors({ ...errors, five_min: false });
                },
              })}
            />
            {errors.five_min ? (
              <FormHelperText error={true}>
                The Apgar score is greater than 1 and less than 10
              </FormHelperText>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ApgarScore;
