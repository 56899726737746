import Form from 'components/Form';

import { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';

import RowContainer from '../../components/RowContainer';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';
import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const GuardianInfo = (props: Props) => {
  const { disabled, register } = props;

  const infos = [
    { title: '1) Name of procedure: CRRT', value: 'applier_name' },
    {
      title: '2) Doctor of the procedure:',
      value: 'no2',
    },
  ];

  return (
    <Fragment>
      <SectionTitle title="2. Name of procedure / Doctor of the procedure" />
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        {infos.map(({ title, value }) => {
          if (title === '1) Name of procedure: CRRT') {
            return (
              <Grid xs={12}>
                <Typography
                  sx={{
                    lineHeight: '20px',
                    margin: '20px 0 0 15px',
                    fontSize: '14px',
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            );
          }
          return (
            <Grid xs={12}>
              <Stack direction="row">
                <Typography
                  sx={{
                    lineHeight: '20px',
                    margin: '20px 0 0 15px',
                    fontSize: '14px',
                  }}
                >
                  {title}
                </Typography>
                <Form.MuiTextField
                  sx={{
                    margin: '10px 0 0 15px',
                    width: '200px',
                  }}
                  required={false}
                  disabled={disabled}
                  {...register(`${value}`)}
                />
              </Stack>
            </Grid>
          );
        })}
      </RowContainer>
    </Fragment>
  );
};

export default GuardianInfo;
