import { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MuiDialog from 'components/MuiDialog';
import {
  SurveyDialogProps,
  TSafetyDefaultValues,
} from 'routes/Main/Survey/type';
import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import CommonPatientInfo from '../components/CommonPatientInfo';
import TextareaSection from '../components/TextAreaSection';
import ConsequencesDetails from './ConsequencesDetails';
import FallingType from './FallingType';
import MedicationType from './MedicationType';
import OtherType from './OtherType';

import { updateSafety } from 'apis/survey';

const Safety = (props: SurveyDialogProps<TSafetyDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue, watch, control } =
    useForm({
      defaultValues,
    });

  const onSubmit = (data: TSafetyDefaultValues) => {
    const {
      accident_consequences_details,
      event_classification,
      falling_type,
      medication_type,
      other_type,
      other_type_etc,
      accident_detail,
      accident_handling,
      accident_result,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      safety_survey: {
        accident_consequences_details: {
          ...accident_consequences_details,
        },
        event_classification,
        falling_type: {
          ...falling_type,
          patient_risk_factors: JSON.stringify(
            falling_type.patient_risk_factors
          ),
          patient_risk_factors_two: JSON.stringify(
            falling_type.patient_risk_factors_two
          ),
        },
        medication_type: {
          ...medication_type,
        },
        other_type,
        other_type_etc,
        accident_detail,
        accident_handling,
        accident_result,
      },
    };

    updateSafety(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess(
          'The patient safety incident report has been successfully saved.'
        );
      })
      .catch(e => onFail('Failed to save patient safety incident report.', e));
  };

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    control,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Patient Safety Report
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <ConsequencesDetails {...formProps} />
        {/* <EventClassification {...formProps} /> */}
        <MedicationType {...formProps} />
        <FallingType {...formProps} />
        <OtherType {...formProps} />
        <TextareaSection
          {...formProps}
          title="Describe the incident"
          registerId="accident_detail"
        />
        <TextareaSection
          {...formProps}
          title="Immediate actions taken"
          registerId="accident_handling"
        />
        <TextareaSection
          {...formProps}
          title="Follow-up actions"
          registerId="accident_handling"
        />
        <TextareaSection
          {...formProps}
          title="Preventive measures"
          registerId="accident_handling"
        />
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default Safety;
