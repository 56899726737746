import Form from 'components/Form';
import { Stack } from '@mui/material';

import { useState } from 'react';

import { IFormValues, IFormRegister, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentPreWrap from './components/RowContentPreWrap';
import SectionTitle from '../components/SectionTitle';

import MuiRadioGroup from './components/RadioConsequence';

import { MobileTimePicker } from '@mui/x-date-pickers';
import MuiTextField from 'components/Form/MuiTextField';
import { AccessTime } from '@mui/icons-material';

interface Props extends IFormValues, IFormRegister, IFormWatch {
  disabled?: boolean;
}

const ConsequencesDetails = (props: Props) => {
  const { disabled, getValues, setValue, register, watch } = props;

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);

  const [classification, setClassification] = useState(
    getValues('accident_consequences_details.accident_classification')
      ? getValues('accident_consequences_details.accident_classification')
      : -1
  );

  return (
    <>
      <SectionTitle title="Incident Details" />
      <RowContainer xs={12}>
        <RowContent
          title="Date of the incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <Form.MuiTextField
            type="date"
            label="Please enter a date"
            InputLabelProps={{ shrink: true }}
            required={false}
            value={date}
            defaultValue={materialDateInput}
            disabled={disabled}
            sx={{ width: '170px' }}
            {...register('accident_consequences_details.accidence_date', {
              onChange: e => setDate(e.target.value),
            })}
          />
        </RowContent>
        <RowContent
          title="Time of the incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <MobileTimePicker
            value={
              watch('accident_consequences_details.discovery_date') || null
            }
            onChange={v =>
              setValue('accident_consequences_details.discovery_date', v)
            }
            renderInput={params => (
              <MuiTextField
                {...params}
                required={false}
                placeholder="Choose Time"
                sx={{ width: '170px' }}
                InputProps={{ endAdornment: <AccessTime /> }}
              />
            )}
          />
          {/* <Form.MuiTextField
            type="date"
            label="Please enter a date"
            InputLabelProps={{ shrink: true }}
            required={false}
            value={date2}
            defaultValue={materialDateInput}
            disabled={disabled}
            sx={{ width: '170px' }}
            {...register('accident_consequences_details.discovery_date', {
              onChange: e => setDate2(e.target.value),
            })}
          /> */}
        </RowContent>
        <RowContentPreWrap
          title="Location of the 
          incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <MuiRadioGroup
            i18nKey="SAFETY.DISCOVERY.PLACE"
            values={[1, 2, 3, 4, 0]}
            disabled={disabled}
            defaultValue={getValues(
              'accident_consequences_details.discovery_place'
            )}
            onChange={v =>
              setValue('accident_consequences_details.discovery_place', v)
            }
            width="auto"
          />
          <Form.MuiTextField
            required={false}
            fullWidth={false}
            disabled={disabled}
            placeholder="Direct input"
            sx={{ marginLeft: '10px' }}
            {...register('accident_consequences_details.discovery_place_etc')}
          />
        </RowContentPreWrap>

        <RowContent
          title="Nature of the incident"
          titleRatio={1.5}
          childrenRatio={10.5}
        >
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="SAFETY.ACCIDENT.CLASSIFICATION"
              defaultValue={classification}
              value={classification}
              values={[1, 2, 3, 4, 5]}
              disabled={disabled}
              onChange={v => {
                setValue(
                  'accident_consequences_details.accident_classification',
                  v
                );
                setClassification(v);
              }}
              width="auto"
            />
          </Stack>
        </RowContent>
        <RowContent titleRatio={1.5} childrenRatio={10.5}>
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="SAFETY.ACCIDENT.CLASSIFICATION"
              defaultValue={classification}
              value={classification}
              values={[0]}
              disabled={disabled}
              onChange={v => {
                setValue(
                  'accident_consequences_details.accident_classification',
                  v
                );
                setClassification(v);
              }}
              width="auto"
            />
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              sx={{ marginLeft: '10px' }}
              {...register(
                'accident_consequences_details.accident_classification_etc'
              )}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default ConsequencesDetails;
