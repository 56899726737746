import { useEffect, useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

import { Box, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
}

const NewOpInfo = (props: Props) => {
  const { disabled, register, watch, setValue, getValues } = props;

  const [postureEtc, setPostureEtc] = useState(0);

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);

  const asa_class_labels = [
    'ASAI',
    'ASAII',
    'ASAIII',
    'ASAIV',
    'ASAV',
    'ASAVI',
  ];
  const position_labels = [
    'Fowler',
    'Lateral',
    'Lithotomy',
    'Orthopnea',
    'Prone',
    'Recumbent',
    'Sims',
    'Supine',
  ];

  const contents = [
    {
      label: 'OP Date',
      element: (
        <Form.MuiTextField
          type="date"
          label="Please enter a date"
          InputLabelProps={{ shrink: true }}
          required={false}
          value={date}
          defaultValue={materialDateInput}
          disabled={disabled}
          {...register('operation_information.operating_date', {
            onChange: e => setDate(e.target.value),
          })}
        />
      ),
    },
    {
      label: 'Preop diagnosis',
      element: (
        <Form.MuiTextField
          {...register('operation_information.operating_department')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Postop Diagnosis',
      element: (
        <Form.MuiTextField
          {...register('operation_information.operating_time')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Name of OP',
      element: (
        <Form.MuiTextField
          {...register('operation_information.asa_class')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Location',
      element: (
        <Form.MuiTextField
          {...register('operation_information.main_operation_name')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Skin Condition',
      element: (
        <Form.MuiTextField
          {...register('operation_information.minor_operation_name')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Skin Preparation',
      element: (
        <Form.MuiTextField
          {...register('operation_information.past_history')}
          required={false}
          disabled={disabled}
        />
      ),
    },
    {
      label: 'Position',
      element: (
        <Form.MuiTextField
          select
          required={false}
          disabled={disabled}
          defaultValue={getValues('operation_information.allergy')}
          {...register('operation_information.allergy')}
        >
          <MenuItem value="Supine">Supine</MenuItem>
          <MenuItem value="Trendelenburg">Trendelenburg</MenuItem>
          <MenuItem value="Reverse Trendelenburg">
            Reverse Trendelenburg
          </MenuItem>
          <MenuItem value="Fowler’s">Fowler’s</MenuItem>
          <MenuItem value="Semi-Fowler’s">Semi-Fowler’s</MenuItem>
          <MenuItem value="Lithotomy">Lithotomy</MenuItem>
          <MenuItem value="Lateral (Left)">Lateral (Left)</MenuItem>
          <MenuItem value="Lateral (Right)">Lateral (Right)</MenuItem>
          <MenuItem value="Prone">Prone</MenuItem>
          <MenuItem value="Jackknife">Jackknife</MenuItem>
          <MenuItem value="Direct Input">Direct Input</MenuItem>
        </Form.MuiTextField>
      ),
    },
    {
      label: 'NPO',
      element: (
        <Form.MuiTextField
          select
          required={false}
          disabled={disabled}
          defaultValue={getValues('operation_information.npo_status')}
          {...register('operation_information.npo_status')}
        >
          <MenuItem value="금식">NPO</MenuItem>
          <MenuItem value="금식안함">Not NPO</MenuItem>
        </Form.MuiTextField>
      ),
    },
    {
      label: 'Prophylactic Anti',
      element: (
        <Form.MuiTextField
          select
          required={false}
          disabled={disabled}
          defaultValue={getValues(
            'operation_information.prophylactic_antibiotics'
          )}
          {...register('operation_information.prophylactic_antibiotics')}
        >
          <MenuItem value="Injection">Injection</MenuItem>
          <MenuItem value="None">None</MenuItem>
        </Form.MuiTextField>
      ),
    },

    {
      label: 'Allergy',
      element: (
        <Box display="flex">
          {/* <Form.MuiTextField
            select
            required={false}
            disabled={disabled}
            sx={{ width: `${postureEtc ? '18%' : '37%'}` }}
            defaultValue={
              [...position_labels, undefined].includes(
                getValues('operation_information.position')
              )
                ? getValues('operation_information.position')
                : 'etc'
            }
            {...register('operation_information.position')}
            onChange={e => {
              if (e.target.value === 'etc') setPostureEtc(1);
              else setPostureEtc(0);
            }}
          >
            {position_labels.map(v => (
              <MenuItem value={v}>{v} position</MenuItem>
            ))}
            <MenuItem value="etc">Direct input</MenuItem>
          </Form.MuiTextField>
          {postureEtc ? (
            <Form.MuiTextField
              {...register('operation_information.position_etc')}
              placeholder="Direct input"
              sx={{ marginLeft: '5px', width: '18%' }}
            />
          ) : null} */}
          <Form.MuiTextField
            {...register('operation_information.position')}
            required={false}
            sx={{ width: '37%' }}
            disabled={disabled}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_xray'
                )}
                {...register('operation_information.preoperative_xray')}
              />
            }
            label="Pre X-ray"
            sx={{ marginLeft: '20px' }}
          />
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                defaultChecked={getValues(
                  'operation_information.preoperative_ekg'
                )}
                {...register('operation_information.preoperative_ekg')}
              />
            }
            defaultValue={getValues('operation_information.preoperative_ekg')}
            label="Post EKG"
            sx={{ marginLeft: '20px' }}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (
      ![...position_labels, undefined].includes(
        getValues('operation_information.position')
      )
    ) {
      setValue(
        'operation_information.position_etc',
        getValues('operation_information.position')
      );
      setPostureEtc(1);
    }
  }, []);

  return (
    <>
      <SectionTitle title="Surgery Information" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => (
          <>
            <RowContent
              title={label}
              titleRatio={1}
              childrenRatio={label === 'Allergy' ? 5 : 2}
            >
              {element}
            </RowContent>
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default NewOpInfo;
