import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { AccessTime, Delete } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import { Ti18nId } from 'hooks/useI18n';
import { IMentalNursingRecord } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import MuiTextField from 'components/Form/MuiTextField';
import SectionTitle from '../../components/SectionTitle';

import { formatStringToDate } from 'utils/formatting';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const GlucoseRecords = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const mentalNursingRecordList: IMentalNursingRecord[] =
    watch('mental_survey');

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);
  const [time, setTime] = useState(null);
  const [patient, setPatient] = useState('');
  const [student, setStudent] = useState('');
  const [basis, setBasis] = useState('');
  const [evaluation, setEvaluation] = useState('');
  const [desc, setDesc] = useState('');

  const onAddRow = () => {
    const request = {
      date,
      time,
      patient_activity: patient,
      student_activity: student,
      student_rationale: basis,
      evaluation,
      mental_nursing: desc,
    };

    console.log('???안담겨?', Object.values(request).filter(v => !v).length);

    if (Object.values(request).filter(v => !v).length > 0) {
      return onRequired('CLINICAL.OBSERVATION.ADD.ROW');
    }

    onSuccess('Mental Nursing record added');
    setValue(
      'mental_survey',
      mentalNursingRecordList
        ? [...mentalNursingRecordList, request]
        : [request]
    );
    setValue('mental_nursing_date', '');
    setDate(materialDateInput);
    setTime(null);
    setPatient('');
    setStudent('');
    setBasis('');
    setEvaluation('');
    setDesc('');
  };

  console.log('담기남', date);

  const onDeleteRow = (index: number) => {
    setValue(
      'mental_survey',
      mentalNursingRecordList.filter((_, i) => i !== index)
    );
  };

  const inputRows = [
    {
      label: 'Date',
      elements: [
        {
          type: 'date',
          element: (
            <Form.MuiTextField
              type="date"
              label="Please enter a date"
              InputLabelProps={{ shrink: true }}
              required={false}
              value={date}
              defaultValue={materialDateInput}
              disabled={disabled}
              {...register('mental_nursing_date', {
                onChange: e => setDate(e.target.value),
              })}
            />
          ),
        },
        {
          type: 'time',
          element: (
            <MobileTimePicker
              value={time}
              onChange={setTime}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  required={false}
                  placeholder="Choose Time"
                  InputProps={{ endAdornment: <AccessTime /> }}
                />
              )}
            />
          ),
        },
        {
          type: 'button',
          element: (
            <Button variant="contained" size="small" onClick={onAddRow}>
              ADD
            </Button>
          ),
        },
      ],
    },
    {
      label: 'Patient’s verbal/nonverbal activities',
      elements: [
        {
          type: 'text',
          element: (
            <MuiTextField
              value={patient}
              required={false}
              onChange={({ target: { value } }) => setPatient(value)}
              multiline
              minRows={2}
              placeholder="Enter"
            />
          ),
        },
      ],
    },
    {
      label: 'Nurse’s verbal/nonverbal activities',
      elements: [
        {
          type: 'text',
          element: (
            <MuiTextField
              value={student}
              required={false}
              onChange={({ target: { value } }) => setStudent(value)}
              multiline
              minRows={2}
              placeholder="Enter"
            />
          ),
        },
      ],
    },
    {
      label: 'Rationale for Nurse’s response',
      elements: [
        {
          type: 'text',
          element: (
            <MuiTextField
              value={basis}
              required={false}
              onChange={({ target: { value } }) => setBasis(value)}
              multiline
              minRows={2}
              placeholder="Enter"
            />
          ),
        },
      ],
    },
    {
      label: 'Evaluation',
      elements: [
        {
          type: 'text',
          element: (
            <MuiTextField
              value={evaluation}
              required={false}
              onChange={({ target: { value } }) => setEvaluation(value)}
              multiline
              minRows={2}
              placeholder="Enter"
            />
          ),
        },
      ],
    },
    {
      label: 'Mental Nursing Descriptive Record',
      elements: [
        {
          type: 'text',
          element: (
            <MuiTextField
              value={desc}
              required={false}
              onChange={({ target: { value } }) => setDesc(value)}
              multiline
              minRows={2}
              placeholder="Enter"
            />
          ),
        },
      ],
    },
  ];

  const displayRows = mentalNursingRecordList
    ? mentalNursingRecordList.map((item, i) => ({
        ...item,
        id: i,
        time: formatStringToDate(item.time, 'hh:mm a'),
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];
  return (
    <Fragment>
      <SectionTitle title="Mental Health Records" />
      <Grid item xs={12}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {!disabled &&
              inputRows.map(row => (
                <TableRow
                  key={row.label}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    {row.label}
                  </TableCell>
                  {row.elements.map(element =>
                    element.type === 'date' || element.type === 'time' ? (
                      <TableCell>{element.element}</TableCell>
                    ) : element.type === 'button' ? (
                      <TableCell align="right">{element.element}</TableCell>
                    ) : (
                      <TableCell colSpan={3}>{element.element}</TableCell>
                    )
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableBody>
            {displayRows.map(row => (
              <>
                <TableRow
                  key="Date"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Date
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.date}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.time}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{row.action}</TableCell>
                </TableRow>
                <TableRow
                  key="Patient’s verbal/nonverbal activities"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Patient’s verbal/nonverbal activities
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.patient_activity}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="Nurse’s veerbal/nonverbal activities"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Nurse’s veerbal/nonverbal activities
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.student_activity}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="Rationale for Nurse’s response"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Rationale for Nurse’s response
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.student_rationale}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="Evaluation"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Evaluation
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.evaluation}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  key="Mental Nursing Descriptive Record"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell style={{ width: '20%', verticalAlign: 'top' }}>
                    Mental Nursing Descriptive Record
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography style={{ fontSize: '14px' }}>
                      {row.mental_nursing}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Fragment>
  );
};

export default GlucoseRecords;
