import { formatToRequestParameter } from 'utils/formatting';
import apiGateway from '../axios';
import {
  IGetUserInfo,
  IPostAccountCreateRequest,
  IPostChangePassword,
  IPostLoginRequest,
  IPostPasswordReset,
  IPostSendMailRequest,
  IPostSendMailRequestForPW,
  IPostVerifyMailRequest,
} from './type';

export const getUserInfo = (request: IGetUserInfo) => {
  const url = `/account/info?${formatToRequestParameter(request)}`;
  return apiGateway.get(url);
};

export const postSendMail = (request: IPostSendMailRequest) => {
  const url = `/account/send_english_mail?${formatToRequestParameter(request)}`;
  return apiGateway.post(url);
};

// 비밀번호찾기의 메일보내기
export const postSendMailForPW = (request: IPostSendMailRequestForPW) => {
  const url = `/account/send_english_mail_for_password_reset?${formatToRequestParameter(
    request
  )}`;
  return apiGateway.post(url);
};

// 비밀번호 찾기의 비밀번호 재설정
export const postPwReset = (request: IPostPasswordReset) => {
  const url = `/account/reset/password`;
  return apiGateway.post(url, request);
};

export const postVerifyMail = (request: IPostVerifyMailRequest) => {
  const url = `/account/verify_mail?${formatToRequestParameter(request)}`;
  return apiGateway.post(url);
};

export const postAccountCreate = (request: IPostAccountCreateRequest) => {
  const url = `/account/create`;
  return apiGateway.post(url, request);
};

export const postLogin = (request: IPostLoginRequest) => {
  const url = `/account/login?${formatToRequestParameter(request)}`;
  return apiGateway.post(url);
};

export const postChangePassword = (request: IPostChangePassword) => {
  const url = `/account/changePersonalInformation`;
  return apiGateway.post(url, request);
};
