import Form from 'components/Form';

import { Fragment } from 'react';
import { Grid } from '@mui/material';

import RowContainer from '../components/RowContainer';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';
import RowContent from '../components/RowContent';
import RowContentPre from './components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const scheduled = [
  {
    label: 'Cath',
    key: 'recommendation_schedule.checked1',
  },
  {
    label: 'US',
    key: 'recommendation_schedule.checked2',
  },
  {
    label: 'Stress',
    key: 'recommendation_schedule.checked3',
  },
  {
    label: 'Echo',
    key: 'recommendation_schedule.checked4',
  },
  {
    label: 'Dopplers',
    key: 'recommendation_schedule.checked5',
  },
  {
    label: 'MRI',
    key: 'recommendation_schedule.checked6',
  },
];

const consults1 = [
  {
    label: 'CM',
    key: 'recommendation_consults.checked1',
  },
  {
    label: 'PT',
    key: 'recommendation_consults.checked2',
  },
  {
    label: 'OT',
    key: 'recommendation_consults.checked3',
  },
  {
    label: 'GI',
    key: 'recommendation_consults.checked4',
  },
  {
    label: 'Cards',
    key: 'recommendation_consults.checked5',
  },
  {
    label: 'Neuro',
    key: 'recommendation_consults.checked6',
  },
  {
    label: 'Nephro',
    key: 'recommendation_consults.checked7',
  },
  {
    label: 'Wound',
    key: 'recommendation_consults.checked8',
  },
];

const consults2 = [
  {
    label: 'Ortho',
    key: 'recommendation_consults.checked9',
  },
  {
    label: 'Psych',
    key: 'recommendation_consults.checked10',
  },
  {
    label: 'Pulm',
    key: 'recommendation_consults.checked11',
  },
  {
    label: 'Surg',
    key: 'recommendation_consults.checked12',
  },
];

const discharge = [
  {
    label: 'Home',
    key: 'recommendation_discharge.checked1',
  },
  {
    label: 'Home Health',
    key: 'recommendation_discharge.checked2',
  },
  {
    label: 'ALF',
    key: 'recommendation_discharge.checked3',
  },
  {
    label: 'SNF',
    key: 'recommendation_discharge.checked4',
  },
  {
    label: 'Rehab',
    key: 'recommendation_discharge.checked5',
  },
];

const Recommendation = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="R (Recommendation)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContentPre
          title="Scheduled
Procedures"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            {scheduled.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContentPre>
        <RowContent title="Consults" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {consults1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {consults2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Discharge" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {discharge.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Recommendation;
