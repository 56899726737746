import { useState } from 'react';

import useStudent from 'store/student/useStudent';
import { setCookie } from 'utils/cookie';
import useUser from 'store/user/useUser';

import {
  Dialog,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  useTheme,
} from '@mui/material';

import { ReactComponent as Number01 } from 'assets/icon-number-01.svg';
import { ReactComponent as Number02 } from 'assets/icon-number-02.svg';
import { ReactComponent as Number03 } from 'assets/icon-number-03.svg';
import { ReactComponent as Number04 } from 'assets/icon-number-04.svg';
import { ReactComponent as Number05 } from 'assets/icon-number-05.svg';

export interface SimpleDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setShow: (show: boolean) => void;
}

function Introduction(props: SimpleDialogProps) {
  const { palette } = useTheme();
  const { open, setOpen, setShow } = props;
  // const { student_name } = useStudent();

  const {
    student_name,
    student_uuid,
    student_grade,
    college_ci,
    college_name,
  } = useUser();

  const contents = [
    {
      title: 'Platform',
      desc1: 'Smart ENR is an electronic nursing record system designed',
      desc2: 'for nursing students and new nurses',
      desc3: '',
    },
    {
      title: 'Our Solution',
      desc1: 'We developed Smart ENR to help nursing students and new',
      desc2: 'nurses overcome these challenges by practicing with an EHR',
      desc3: 'system',
    },
    {
      title: 'Background',
      desc1: 'Approximately 97% of hospitals in the US employ electronic',
      desc2: 'health record (EHR) systems, and nurses spend up to 35% of',
      desc3: 'their working hours on nursing documentation',
    },
    {
      title: 'Usability',
      desc1: 'Smart ENR is cloud-based, allowing you to access it anytime',
      desc2: 'anywhere, and on any device—PC, laptop, tablet, or',
      desc3: 'smartphone—without the need for installation',
    },
    {
      title: 'Current Problem',
      desc1: 'Most new nurses face challenges with nursing records',
      desc2: '',
      desc3: '',
    },
  ];

  const icons = [
    <Number01 fill={palette.primary.main} />,
    <Number04 fill={palette.primary.main} />,
    <Number02 fill={palette.primary.main} />,
    <Number05 fill={palette.primary.main} />,
    <Number03 fill={palette.primary.main} />,
  ];

  /* 다시 보지 않기 체크박스 관련 */
  const [check, setCheck] = useState(false);
  /*************************/

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '75%',
            padding: '50px 100px',
            backgroundColor: palette.mode === 'dark' ? 'grey' : '',
          },
        },
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Stack direction="row">
        <Typography
          sx={{ fontWeight: '700', fontSize: '64px', lineHeight: '80px' }}
        >
          Welcome,
        </Typography>
        <Typography
          sx={{
            color: `${palette.primary.main}`,
            fontWeight: '700',
            fontSize: '64px',
            lineHeight: '80px',
          }}
        >
          {student_name}
        </Typography>
      </Stack>
      <Typography
        sx={{ fontWeight: '700', fontSize: '64px', lineHeight: '80px' }}
      >
        This is Smart ENR :)
      </Typography>
      <Typography
        sx={{
          color: `${palette.primary.main}`,
          fontWeight: '700',
          fontSize: '30px',
          lineHeight: '50px',
        }}
      >
        Shall we go practice nursing records?
      </Typography>
      {/* <Button
        sx={{
          width: '187px',
          height: '48px',
          color: 'white',
          backgroundColor: `${palette.primary.main}`,
          marginTop: '15px',
          whiteSpace: 'nowrap',
        }}
        variant="contained"
        onClick={() => {
          setShow(true);
          setOpen(false);
        }}
      >
        Tap to keep watching
      </Button> */}
      <Grid
        container
        columnSpacing={10}
        rowSpacing={5}
        marginTop="62px"
        marginBottom="45px"
      >
        {contents.map(({ title, desc1, desc2, desc3 }, idx) => (
          <Grid item xs={6}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              marginBottom="20px"
            >
              {icons[idx]}
              <Typography
                sx={{
                  color: `${palette.primary.main}`,
                  fontWeight: '700',
                  fontSize: '40px',
                  lineHeight: '50px',
                }}
              >
                {title}
              </Typography>
            </Stack>
            <Typography sx={{ whiteSpace: 'nowrap' }}>
              {desc1}
              <br />
              {desc2}
              <br />
              {desc3}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" spacing={2}>
        <Button
          sx={{
            width: '187px',
            height: '48px',
            color: `${palette.primary.main}`,
          }}
          variant="outlined"
          onClick={() => {
            if (check) setCookie('no_intro', 'true', 30);
            setShow(false);
            setOpen(false);
          }}
        >
          Tap to Close
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              value={check}
              onChange={e => setCheck(e.target.checked)}
            />
          }
          // label="Do not open for a month"
          label="Do not open"
        />
      </Stack>
    </Dialog>
  );
}

export default Introduction;
