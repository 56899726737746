import { useState } from 'react';
import { CheckCircle, KeyboardArrowLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import Form from 'components/Form';
import { getCollegeList } from 'apis/admin';
import MuiAutocomplete from './components/MuiAutocomplete';

import { ISendMailProps, IVerifyMailProps } from './types';
import SignUpDialog from './SignUpDialog';

import { styled } from '@mui/styles';

interface Props {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  sendMailProps: ISendMailProps;
  verifyMailProps: IVerifyMailProps;
}

function SignUpForm(props: Props) {
  const [isTerms, setIsTerms] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const sxBtnStyles = { width: 150, pl: 0, pr: 0, fontSize: 14 };
  const { register, setValue, getValues, sendMailProps, verifyMailProps } =
    props;

  const VerificationIcon = () => (
    <CheckCircle
      fontSize="small"
      color="primary"
      sx={{
        display: verifyMailProps.isVerification ? 'block' : 'none',
      }}
    />
  );

  // 출생년도 옵션
  const rendering = () => {
    const result = [];
    for (let i = 1950; i < 2011; i++) {
      result.push(
        <StMenuItem key={i} value={i}>
          {i}
        </StMenuItem>
      );
    }
    return result;
  };

  // select option style
  const StMenuItem = styled(MenuItem)({
    height: 55,
  });

  // 비밀번호 에러 찾기
  const [passwordValue, setPasswordValue] = useState('');

  const passwordRegex = () => {
    let check = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,10})$/;

    return check.test(passwordValue);
  };

  const [passwordCheck, setPasswordCheck] = useState('');

  // 이름에 특수문자 넣지못하게 막는
  const [이름, set이름] = useState('');

  const nameRegex = () => {
    let check = /@/;
    return check.test(이름);
  };

  // 이메일 유효성
  const [이메일, set이메일] = useState('');

  const emailRegex = () => {
    let check = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    return check.test(이메일);
  };

  return (
    <Box>
      <Container maxWidth="sm" sx={{ mt: 7.5, mb: 6 }}>
        <Button
          href="/#/"
          size="large"
          color="inherit"
          startIcon={<KeyboardArrowLeft />}
          sx={{ mb: 5, p: 0 }}
        >
          Create Account
        </Button>

        <Stack spacing={2.5}>
          <TextField
            // required
            fullWidth
            type="email"
            sx={{ display: 'none' }}
            {...register('userEmail')}
          />
          <Form.Item label="Email">
            <Box display="flex" gap={1}>
              <TextField
                // required
                helperText={
                  emailRegex()
                    ? ''
                    : 'Please enter your university email (if you do not have one, please enter your personal email)'
                }
                color={emailRegex() ? 'success' : 'warning'}
                fullWidth
                type="email"
                {...register('userEmail', {
                  onChange: e => set이메일(e.target.value),
                })}
              />
              <LoadingButton
                sx={{ ...sxBtnStyles, maxHeight: 56 }}
                loading={sendMailProps.isLoading}
                variant={sendMailProps.isSendMail ? 'outlined' : 'contained'}
                onClick={sendMailProps.onClick}
              >
                {sendMailProps.isSendMail ? 'Resend' : 'Authenticate'}
              </LoadingButton>
            </Box>
          </Form.Item>
          <Form.Item
            label="Authentication Number"
            isHidden={!sendMailProps.isSendMail}
          >
            <Box display="flex" gap={1}>
              <TextField
                // required
                fullWidth
                placeholder="Please enter the number sent by email"
                InputProps={{
                  readOnly: verifyMailProps.isVerification,
                  endAdornment: <VerificationIcon />,
                }}
                {...register('userCode')}
              />
              <LoadingButton
                variant="contained"
                loading={verifyMailProps.isLoading}
                onClick={verifyMailProps.onClick}
                sx={{
                  ...sxBtnStyles,
                  display: !verifyMailProps.isVerification ? 'block' : 'none',
                  height: '55px',
                }}
              >
                Enter
              </LoadingButton>
            </Box>
            <Typography
              sx={{
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.6) ',
                marginLeft: '14px',
                marginTop: '5px',
              }}
            >
              In case you failed to received authentication number, please check
              your spam folder
            </Typography>
          </Form.Item>
          <input
            type="password"
            style={{ display: 'none' }}
            aria-hidden="true"
          />

          <Form.Item label="Password">
            <Form.Password
              required
              value={passwordValue}
              fullWidth
              placeholder="8 characters, English letters + numbers + special characters"
              helperText={
                passwordRegex()
                  ? "It's safe!"
                  : 'Only @ $ ! % * # ^ () _ = | ] is allowed for special symbols'
              }
              color={passwordRegex() ? 'success' : 'warning'}
              {...register('userPassword', {
                onChange: e => setPasswordValue(e.target.value),
              })}
            />
          </Form.Item>
          <Form.Item label="Repeat Password">
            <Form.Password
              required
              fullWidth
              isHideVisibleBtn
              helperText={
                passwordValue === passwordCheck ? 'Matches!' : "Doesn't match!"
              }
              color={passwordValue === passwordCheck ? 'success' : 'warning'}
              {...register('userPasswordConfirm', {
                onChange: e => setPasswordCheck(e.target.value),
              })}
            />
          </Form.Item>
          <TextField
            helperText={
              nameRegex()
                ? 'Please be careful not to write your email address.'
                : ''
            }
            color={nameRegex() ? 'warning' : 'success'}
            required
            sx={{ display: 'none' }}
            fullWidth
            {...register('userName', {
              onChange: e => set이름(e.target.value),
            })}
          />
          <Form.Item label="Name">
            <TextField
              helperText={
                nameRegex()
                  ? 'Please be careful not to write your email address.'
                  : ''
              }
              color={nameRegex() ? 'warning' : 'success'}
              required
              fullWidth
              {...register('userName', {
                onChange: e => set이름(e.target.value),
              })}
            />
          </Form.Item>
          <Grid container>
            <Grid item xs={6}>
              <Form.Item label="Gender">
                <Form.MuiRadioGroup
                  i18nKey="GENDER"
                  values={[1, 2]}
                  defaultValue={getValues('gender')}
                  onChange={value => setValue('gender', value)}
                />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item label="Position">
                <Form.MuiRadioGroup
                  i18nKey="GRADE"
                  values={[1, 2]}
                  defaultValue={getValues('grade')}
                  onChange={value => setValue('grade', value)}
                />
              </Form.Item>
            </Grid>
          </Grid>

          <Form.Item label="School(University/College)">
            <MuiAutocomplete
              listKey="college_lists"
              valueKey="college_id"
              noOptionsText="Please enter another school"
              onChange={value => setValue('college', value.college_id)}
              getApi={getCollegeList}
              getOptionLabel={option => option.college_name}
            />
          </Form.Item>
          <Form.Item label="Student number(Prof. Number)">
            <TextField
              // required
              fullWidth
              type="number"
              variant="outlined"
              {...register('studentNo')}
            />
          </Form.Item>
          <Form.Item label="Year of Birth">
            <TextField
              // required
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              defaultValue="2010"
              {...register('birth')}
            >
              {rendering()}
            </TextField>
          </Form.Item>
        </Stack>

        <FormGroup sx={{ mt: 2.5 }}>
          <FormControlLabel
            label="(Mandatory) Agree to the terms and conditions"
            control={
              <Checkbox
                // required
                size="small"
                onChange={(_, checked) => setIsTerms(checked)}
              />
            }
          />
          <FormControlLabel
            label="(Mandatory) Agree to collect personal information"
            control={
              <Checkbox
                // required
                size="small"
                onChange={(_, checked) => setIsPersonal(checked)}
              />
            }
          />
        </FormGroup>

        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ mt: 3.25 }}
        >
          SIGN UP
        </Button>
      </Container>

      <SignUpDialog.TermsOfService
        isOpen={isTerms}
        onClose={() => setIsTerms(false)}
      />

      <SignUpDialog.PersonalInfo
        isOpen={isPersonal}
        onClose={() => setIsPersonal(false)}
      />
    </Box>
  );
}

export default SignUpForm;
