import { Box, Skeleton, Toolbar } from '@mui/material';
import useSelectorTyped from 'store/useSelectorTyped';
import NursingRecord from './NursingRecord';
import MedicalNote from './MedicalNote';

interface Props {
  coachRef: any;
}

const Content = (props: Props) => {
  const { coachRef } = props;
  const patientInfo = useSelectorTyped(state => state.patient.patientInfo);

  const contentBoxProps = {
    display: 'flex',
    mt: 2.5,
    columnGap: 2.5,
    overflow: 'hidden',
    height: 'calc(100% - 180px)',
  };

  if (!patientInfo) {
    return (
      <Box {...contentBoxProps}>
        <Box flex={1} display="flex" flexDirection="column" overflow="auto">
          <Skeleton variant="rectangular" sx={{ flex: 1 }} />
        </Box>
        <Box
          ref={coachRef}
          flex={1}
          display="flex"
          flexDirection="column"
          overflow="auto"
        >
          <Skeleton variant="rectangular" sx={{ flex: 1 }} />
        </Box>
      </Box>
    );
  }

  return (
    <Box {...contentBoxProps}>
      <MedicalNote patientInfo={patientInfo} />
      <NursingRecord patientInfo={patientInfo} coachRef={coachRef} />
    </Box>
  );
};

export default Content;
