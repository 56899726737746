import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography, Box } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import { SurveyDialogProps, TTransfusionAgreeDefaultValues } from '../../type';
import { updateTransfusionAgree } from 'apis/survey';

import CommonPatientInfo from '../../components/CommonPatientInfo';
import PatientsRights from './PatientsRights';
import PatientsResponsibility from './PatientsResponsibility';
import Signature from './Signature';

const PatientsGuide = (
  props: SurveyDialogProps<TTransfusionAgreeDefaultValues>
) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  // 수혈동의서 => Patient Guide 연결
  const onSubmit = (data: TTransfusionAgreeDefaultValues) => {
    const { pt_bday, pt_contact, pt_name } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      transfusion_confirmation: {
        pt_bday,
        pt_contact,
        pt_name,
      },
    };

    // console.log('리퀘스트', request);

    updateTransfusionAgree(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess(
          'Patient Guide for Patients Rights and Responsibilities has been saved successfully.'
        );
      })
      .catch(e => {
        onFail(
          'Failed to save Patient Guide for Patients Rights and Responsibilities',
          e
        );
        // console.log(e);
      });
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Typography
        sx={{
          margin: '40px auto 40px auto',
          fontWeight: '700',
          fontSize: '16px',
          textAlign: 'center',
        }}
      >
        Patient Guide for Patients Rights and Responsibilities
      </Typography>
      <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
      <Box sx={{ marginTop: '48px' }}>
        <PatientsRights {...formProps} />
        <PatientsResponsibility {...formProps} />
        <Signature {...formProps} />
      </Box>
    </MuiDialog.SurveyForm>
  );
};

export default PatientsGuide;
