import {
  Typography,
  Grid,
  Table,
  Stack,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  MenuItem,
  useTheme,
} from '@mui/material';
import MuiTextField from 'components/Form/MuiTextField';
import { Delete } from '@mui/icons-material';

import { Fragment, useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

import { Ti18nId } from 'hooks/useI18n';

import { IIORCheckDetailCord } from 'apis/survey/type';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const SocialHistory = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const { palette } = useTheme();

  const mentalNursingRecordList: IIORCheckDetailCord[] = watch(
    'intake_output_survey'
  );

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);
  const [duty, setDuty] = useState('');
  const [total, setTotal] = useState('');

  const [intake1, setIntake1] = useState('');
  const [intake2, setIntake2] = useState('');
  const [intake3, setIntake3] = useState('');
  const [intake4, setIntake4] = useState('');
  const [intake5, setIntake5] = useState('');
  const [intake6, setIntake6] = useState('');

  const [intake_two1, setIntake_two1] = useState('');
  const [intake_two2, setIntake_two2] = useState('');
  const [intake_two3, setIntake_two3] = useState('');
  const [intake_two4, setIntake_two4] = useState('');
  const [intake_two5, setIntake_two5] = useState('');
  const [intake_two6, setIntake_two6] = useState('');

  const [intake_three1, setIntake_three1] = useState('');
  const [intake_three2, setIntake_three2] = useState('');
  const [intake_three3, setIntake_three3] = useState('');
  const [intake_three4, setIntake_three4] = useState('');
  const [intake_three5, setIntake_three5] = useState('');
  const [intake_three6, setIntake_three6] = useState('');

  const [intake_four1, setIntake_four1] = useState('');
  const [intake_four2, setIntake_four2] = useState('');
  const [intake_four3, setIntake_four3] = useState('');
  const [intake_four4, setIntake_four4] = useState('');
  const [intake_four5, setIntake_four5] = useState('');
  const [intake_four6, setIntake_four6] = useState('');

  const [intake_five1, setIntake_five1] = useState('');
  const [intake_five2, setIntake_five2] = useState('');
  const [intake_five3, setIntake_five3] = useState('');
  const [intake_five4, setIntake_five4] = useState('');
  const [intake_five5, setIntake_five5] = useState('');
  const [intake_five6, setIntake_five6] = useState('');

  const [output1, setOutput1] = useState('');
  const [output2, setOutput2] = useState('');
  const [output3, setOutput3] = useState('');
  const [output4, setOutput4] = useState('');
  const [output5, setOutput5] = useState('');
  const [output6, setOutput6] = useState('');

  const [output_two1, setOutput_two1] = useState('');
  const [output_two2, setOutput_two2] = useState('');
  const [output_two3, setOutput_two3] = useState('');
  const [output_two4, setOutput_two4] = useState('');
  const [output_two5, setOutput_two5] = useState('');
  const [output_two6, setOutput_two6] = useState('');

  const [output_three1, setOutput_three1] = useState('');
  const [output_three2, setOutput_three2] = useState('');
  const [output_three3, setOutput_three3] = useState('');
  const [output_three4, setOutput_three4] = useState('');
  const [output_three5, setOutput_three5] = useState('');
  const [output_three6, setOutput_three6] = useState('');

  const [output_four1, setOutput_four1] = useState('');
  const [output_four2, setOutput_four2] = useState('');
  const [output_four3, setOutput_four3] = useState('');
  const [output_four4, setOutput_four4] = useState('');
  const [output_four5, setOutput_four5] = useState('');
  const [output_four6, setOutput_four6] = useState('');

  const [output_five1, setOutput_five1] = useState('');
  const [output_five2, setOutput_five2] = useState('');
  const [output_five3, setOutput_five3] = useState('');
  const [output_five4, setOutput_five4] = useState('');
  const [output_five5, setOutput_five5] = useState('');
  const [output_five6, setOutput_five6] = useState('');

  const onAddRow = () => {
    const request = {
      date,
      duty,
      total,

      intake1,
      intake2,
      intake3,
      intake4,
      intake5,
      intake6,

      intake_two1,
      intake_two2,
      intake_two3,
      intake_two4,
      intake_two5,
      intake_two6,

      intake_three1,
      intake_three2,
      intake_three3,
      intake_three4,
      intake_three5,
      intake_three6,

      intake_four1,
      intake_four2,
      intake_four3,
      intake_four4,
      intake_four5,
      intake_four6,

      intake_five1,
      intake_five2,
      intake_five3,
      intake_five4,
      intake_five5,
      intake_five6,

      output1,
      output2,
      output3,
      output4,
      output5,
      output6,

      output_two1,
      output_two2,
      output_two3,
      output_two4,
      output_two5,
      output_two6,

      output_three1,
      output_three2,
      output_three3,
      output_three4,
      output_three5,
      output_three6,

      output_four1,
      output_four2,
      output_four3,
      output_four4,
      output_four5,
      output_four6,

      output_five1,
      output_five2,
      output_five3,
      output_five4,
      output_five5,
      output_five6,
    };

    console.log('request  : ', request);
    // if (Object.values(request).filter(v => !v).length > 0) {
    //   return onRequired('CLINICAL.OBSERVATION.ADD.ROW');
    // }

    onSuccess('Intake and Output Record Add.');
    setValue(
      'intake_output_survey',
      mentalNursingRecordList
        ? [...mentalNursingRecordList, request]
        : [request]
    );
    setValue('intake_output_date', materialDateInput);
    setDate(materialDateInput);
    setDuty('');

    setIntake1('');
    setIntake2('');
    setIntake3('');
    setIntake4('');
    setIntake5('');
    setIntake6('');

    setIntake_two1('');
    setIntake_two2('');
    setIntake_two3('');
    setIntake_two4('');
    setIntake_two5('');
    setIntake_two6('');

    setIntake_three1('');
    setIntake_three2('');
    setIntake_three3('');
    setIntake_three4('');
    setIntake_three5('');
    setIntake_three6('');

    setIntake_four1('');
    setIntake_four2('');
    setIntake_four3('');
    setIntake_four4('');
    setIntake_four5('');
    setIntake_four6('');

    setIntake_five1('');
    setIntake_five2('');
    setIntake_five3('');
    setIntake_five4('');
    setIntake_five5('');
    setIntake_five6('');

    setTotal('');

    setOutput1('');
    setOutput2('');
    setOutput3('');
    setOutput4('');
    setOutput5('');
    setOutput6('');

    setOutput_two1('');
    setOutput_two2('');
    setOutput_two3('');
    setOutput_two4('');
    setOutput_two5('');
    setOutput_two6('');

    setOutput_three1('');
    setOutput_three2('');
    setOutput_three3('');
    setOutput_three4('');
    setOutput_three5('');
    setOutput_three6('');

    setOutput_four1('');
    setOutput_four2('');
    setOutput_four3('');
    setOutput_four4('');
    setOutput_four5('');
    setOutput_four6('');

    setOutput_five1('');
    setOutput_five2('');
    setOutput_five3('');
    setOutput_five4('');
    setOutput_five5('');
    setOutput_five6('');
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'intake_output_survey',
      mentalNursingRecordList.filter((_, i) => i !== index)
    );
  };

  const meals = ['Day', 'Evening', 'Night', 'No choice'];

  const inputRows = [
    {
      label: '일시',
      elements: [
        {
          type: 'date',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <Form.MuiTextField
                type="date"
                defaultValue={materialDateInput}
                InputLabelProps={{ shrink: true }}
                label="Please enter a date"
                required={false}
                {...register('intake_output_date', {
                  onChange: e => setDate(e.target.value),
                })}
              />

              <Form.MuiTextField
                select
                value={duty}
                required={false}
                onChange={({ target: { value } }) => setDuty(value)}
              >
                {meals.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Form.MuiTextField>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취량',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 24 }}>
              <Typography
                sx={{ fontSize: '13px', color: '#14855B', fontWeight: 500 }}
              >
                intake
              </Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취량 구분',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 24 }}>
              <Typography sx={{ fontSize: '12px' }}>oral</Typography>
              <Typography sx={{ fontSize: '12px' }}>fluid</Typography>
              <Typography sx={{ fontSize: '12px' }}>etc</Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취량 구분/양',
      elements: [
        {
          type: 'title',
          element: (
            <Stack direction="row" sx={{ gap: 9 }}>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취 input 01',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={intake1}
                required={false}
                onChange={({ target: { value } }) => setIntake1(value)}
                placeholder="food/NPO"
              />
              <MuiTextField
                value={intake2}
                required={false}
                onChange={({ target: { value } }) => setIntake2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake3}
                required={false}
                onChange={({ target: { value } }) => setIntake3(value)}
                placeholder="N/S 1L"
              />
              <MuiTextField
                value={intake4}
                required={false}
                onChange={({ target: { value } }) => setIntake4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake5}
                required={false}
                onChange={({ target: { value } }) => setIntake5(value)}
                placeholder="Blood"
              />
              <MuiTextField
                value={intake6}
                required={false}
                onChange={({ target: { value } }) => setIntake6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취 intake 02',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={intake_two1}
                required={false}
                onChange={({ target: { value } }) => setIntake_two1(value)}
                placeholder="food/NPO"
              />
              <MuiTextField
                value={intake_two2}
                required={false}
                onChange={({ target: { value } }) => setIntake_two2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_two3}
                required={false}
                onChange={({ target: { value } }) => setIntake_two3(value)}
                placeholder="N/S 1L"
              />
              <MuiTextField
                value={intake_two4}
                required={false}
                onChange={({ target: { value } }) => setIntake_two4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_two5}
                required={false}
                onChange={({ target: { value } }) => setIntake_two5(value)}
                placeholder="Blood"
              />
              <MuiTextField
                value={intake_two6}
                required={false}
                onChange={({ target: { value } }) => setIntake_two6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취 intake 03',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={intake_three1}
                required={false}
                onChange={({ target: { value } }) => setIntake_three1(value)}
                placeholder="food/NPO"
              />
              <MuiTextField
                value={intake_three2}
                required={false}
                onChange={({ target: { value } }) => setIntake_three2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_three3}
                required={false}
                onChange={({ target: { value } }) => setIntake_three3(value)}
                placeholder="N/S 1L"
              />
              <MuiTextField
                value={intake_three4}
                required={false}
                onChange={({ target: { value } }) => setIntake_three4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_three5}
                required={false}
                onChange={({ target: { value } }) => setIntake_three5(value)}
                placeholder="Blood"
              />
              <MuiTextField
                value={intake_three6}
                required={false}
                onChange={({ target: { value } }) => setIntake_three6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취 intake 04',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={intake_four1}
                required={false}
                onChange={({ target: { value } }) => setIntake_four1(value)}
                placeholder="food/NPO"
              />
              <MuiTextField
                value={intake_four2}
                required={false}
                onChange={({ target: { value } }) => setIntake_four2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_four3}
                required={false}
                onChange={({ target: { value } }) => setIntake_four3(value)}
                placeholder="N/S 1L"
              />
              <MuiTextField
                value={intake_four4}
                required={false}
                onChange={({ target: { value } }) => setIntake_four4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_four5}
                required={false}
                onChange={({ target: { value } }) => setIntake_four5(value)}
                placeholder="Blood"
              />
              <MuiTextField
                value={intake_four6}
                required={false}
                onChange={({ target: { value } }) => setIntake_four6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '섭취 intake 05',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={intake_five1}
                required={false}
                onChange={({ target: { value } }) => setIntake_five1(value)}
                placeholder="food/NPO"
              />
              <MuiTextField
                value={intake_five2}
                required={false}
                onChange={({ target: { value } }) => setIntake_five2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_five3}
                required={false}
                onChange={({ target: { value } }) => setIntake_five3(value)}
                placeholder="N/S 1L"
              />
              <MuiTextField
                value={intake_five4}
                required={false}
                onChange={({ target: { value } }) => setIntake_five4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={intake_five5}
                required={false}
                onChange={({ target: { value } }) => setIntake_five5(value)}
                placeholder="Blood"
              />
              <MuiTextField
                value={intake_five6}
                required={false}
                onChange={({ target: { value } }) => setIntake_five6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
  ];

  const inputRows1 = [
    {
      label: '일시',
      elements: [
        {
          type: 'time',
          element: (
            <Stack direction="row" sx={{ gap: 3 }}>
              <Typography
                sx={{
                  fontSize: '15px',
                  whiteSpace: 'nowrap',
                  lineHeight: '38px',
                }}
              >
                total I/O
              </Typography>
              <Form.MuiTextField
                required={false}
                value={
                  Number(intake2) +
                  Number(intake4) +
                  Number(intake6) +
                  Number(intake_two2) +
                  Number(intake_two4) +
                  Number(intake_two6) +
                  Number(intake_three2) +
                  Number(intake_three4) +
                  Number(intake_three6) +
                  Number(intake_four2) +
                  Number(intake_four4) +
                  Number(intake_four6) +
                  Number(intake_five2) +
                  Number(intake_five4) +
                  Number(intake_five6) -
                  (Number(output2) +
                    Number(output4) +
                    Number(output6) +
                    Number(output_two2) +
                    Number(output_two4) +
                    Number(output_two6) +
                    Number(output_three2) +
                    Number(output_three4) +
                    Number(output_three6) +
                    Number(output_four2) +
                    Number(output_four4) +
                    Number(output_four6) +
                    Number(output_five2) +
                    Number(output_five4) +
                    Number(output_five6))
                }
              />
              <Button variant="contained" size="small" onClick={onAddRow}>
                Add
              </Button>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설량',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 24 }}>
              <Typography
                sx={{ fontSize: '13px', color: '#14855B', fontWeight: 500 }}
              >
                output
              </Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설량 구분',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 24 }}>
              <Typography sx={{ fontSize: '12px' }}>urine</Typography>
              <Typography sx={{ fontSize: '12px' }}>stool</Typography>
              <Typography sx={{ fontSize: '12px' }}>etc</Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설량 구분/양',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 9.4 }}>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                type
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                amount
              </Typography>
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설 output 01',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={output1}
                required={false}
                onChange={({ target: { value } }) => setOutput1(value)}
                placeholder="foley"
              />
              <MuiTextField
                value={output2}
                required={false}
                onChange={({ target: { value } }) => setOutput2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output3}
                required={false}
                onChange={({ target: { value } }) => setOutput3(value)}
                placeholder="loose"
              />
              <MuiTextField
                value={output4}
                required={false}
                onChange={({ target: { value } }) => setOutput4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output5}
                required={false}
                onChange={({ target: { value } }) => setOutput5(value)}
                placeholder="JP drain"
              />
              <MuiTextField
                value={output6}
                required={false}
                onChange={({ target: { value } }) => setOutput6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설 output 02',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={output_two1}
                required={false}
                onChange={({ target: { value } }) => setOutput_two1(value)}
                placeholder="foley"
              />
              <MuiTextField
                value={output_two2}
                required={false}
                onChange={({ target: { value } }) => setOutput_two2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_two3}
                required={false}
                onChange={({ target: { value } }) => setOutput_two3(value)}
                placeholder="loose"
              />
              <MuiTextField
                value={output_two4}
                required={false}
                onChange={({ target: { value } }) => setOutput_two4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_two5}
                required={false}
                onChange={({ target: { value } }) => setOutput_two5(value)}
                placeholder="JP drain"
              />
              <MuiTextField
                value={output_two6}
                required={false}
                onChange={({ target: { value } }) => setOutput_two6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설 output 03',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={output_three1}
                required={false}
                onChange={({ target: { value } }) => setOutput_three1(value)}
                placeholder="foley"
              />
              <MuiTextField
                value={output_three2}
                required={false}
                onChange={({ target: { value } }) => setOutput_three2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_three3}
                required={false}
                onChange={({ target: { value } }) => setOutput_three3(value)}
                placeholder="loose"
              />
              <MuiTextField
                value={output_three4}
                required={false}
                onChange={({ target: { value } }) => setOutput_three4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_three5}
                required={false}
                onChange={({ target: { value } }) => setOutput_three5(value)}
                placeholder="JP drain"
              />
              <MuiTextField
                value={output_three6}
                required={false}
                onChange={({ target: { value } }) => setOutput_three6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설 output 04',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={output_four1}
                required={false}
                onChange={({ target: { value } }) => setOutput_four1(value)}
                placeholder="foley"
              />
              <MuiTextField
                value={output_four2}
                required={false}
                onChange={({ target: { value } }) => setOutput_four2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_four3}
                required={false}
                onChange={({ target: { value } }) => setOutput_four3(value)}
                placeholder="loose"
              />
              <MuiTextField
                value={output_four4}
                required={false}
                onChange={({ target: { value } }) => setOutput_four4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_four5}
                required={false}
                onChange={({ target: { value } }) => setOutput_four5(value)}
                placeholder="JP drain"
              />
              <MuiTextField
                value={output_four6}
                required={false}
                onChange={({ target: { value } }) => setOutput_four6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
    {
      label: '배설 output 05',
      elements: [
        {
          type: 'text',
          element: (
            <Stack direction="row" sx={{ gap: 1 }}>
              <MuiTextField
                value={output_five1}
                required={false}
                onChange={({ target: { value } }) => setOutput_five1(value)}
                placeholder="foley"
              />
              <MuiTextField
                value={output_five2}
                required={false}
                onChange={({ target: { value } }) => setOutput_five2(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_five3}
                required={false}
                onChange={({ target: { value } }) => setOutput_five3(value)}
                placeholder="loose"
              />
              <MuiTextField
                value={output_five4}
                required={false}
                onChange={({ target: { value } }) => setOutput_five4(value)}
                placeholder=""
                type="number"
              />
              <MuiTextField
                value={output_five5}
                required={false}
                onChange={({ target: { value } }) => setOutput_five5(value)}
                placeholder="JP drain"
              />
              <MuiTextField
                value={output_five6}
                required={false}
                onChange={({ target: { value } }) => setOutput_five6(value)}
                placeholder=""
                type="number"
              />
            </Stack>
          ),
        },
      ],
    },
  ];

  const displayRows = mentalNursingRecordList
    ? mentalNursingRecordList.map((item, i) => ({
        ...item,
        id: i,
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{
              display: disabled ? 'none' : 'block',
              height: '20px',
              marginTop: '-15px',
            }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];

  return (
    <Fragment>
      <SectionTitle title="Intake and Output" />
      <RowContainer ratio={12} sx={{ mb: 'auto', marginTop: '10px' }}>
        <Grid item xs={6} sx={{ marginBottom: '30px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {!disabled &&
                inputRows.map(row => (
                  <TableRow
                    key={row.label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.elements.map(element =>
                      element.type === 'date' || element.type === 'time' ? (
                        <TableCell>{element.element}</TableCell>
                      ) : element.type === 'button' ? (
                        <TableCell align="right">{element.element}</TableCell>
                      ) : (
                        <TableCell colSpan={3}>{element.element}</TableCell>
                      )
                    )}
                  </TableRow>
                ))}

              <TableRow>
                <TableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      lineHeight: '38px',
                    }}
                  >
                    total
                    <Form.MuiTextField
                      size="small"
                      sx={{
                        width: '14vw',
                        minWidth: '200px',
                        maxWidth: '224px',
                      }}
                      value={
                        Number(intake2) +
                        Number(intake4) +
                        Number(intake6) +
                        Number(intake_two2) +
                        Number(intake_two4) +
                        Number(intake_two6) +
                        Number(intake_three2) +
                        Number(intake_three4) +
                        Number(intake_three6) +
                        Number(intake_four2) +
                        Number(intake_four4) +
                        Number(intake_four6) +
                        Number(intake_five2) +
                        Number(intake_five4) +
                        Number(intake_five6)
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6} sx={{ marginBottom: '30px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {!disabled &&
                inputRows1.map(row => (
                  <TableRow
                    key={row.label}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {row.elements.map(element =>
                      element.type === 'date' || element.type === 'time' ? (
                        <TableCell>{element.element}</TableCell>
                      ) : element.type === 'button' ? (
                        <TableCell align="right">{element.element}</TableCell>
                      ) : (
                        <TableCell colSpan={3}>{element.element}</TableCell>
                      )
                    )}
                  </TableRow>
                ))}

              <TableRow>
                <TableCell>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      lineHeight: '38px',
                    }}
                  >
                    total
                    <Form.MuiTextField
                      size="small"
                      sx={{
                        width: '14vw',
                        minWidth: '200px',
                        maxWidth: '224px',
                      }}
                      value={
                        Number(output2) +
                        Number(output4) +
                        Number(output6) +
                        Number(output_two2) +
                        Number(output_two4) +
                        Number(output_two6) +
                        Number(output_three2) +
                        Number(output_three4) +
                        Number(output_three6) +
                        Number(output_four2) +
                        Number(output_four4) +
                        Number(output_four6) +
                        Number(output_five2) +
                        Number(output_five4) +
                        Number(output_five6)
                      }
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <SectionTitle title="List" mb={2} />
        <RowContainer ratio={12}>
          <Grid item xs={6}>
            <Table
              sx={{
                minWidth: 650,
              }}
              aria-label="simple table"
            >
              <TableBody>
                {displayRows.map(row => {
                  console.log(row);

                  const 섭취총계 =
                    (row.intake2 ? Number(row.intake2) : 0) +
                    (row.intake4 ? Number(row.intake4) : 0) +
                    (row.intake6 ? Number(row.intake6) : 0) +
                    (row.intake_two2 ? Number(row.intake_two2) : 0) +
                    (row.intake_two4 ? Number(row.intake_two4) : 0) +
                    (row.intake_two6 ? Number(row.intake_two6) : 0) +
                    (row.intake_three2 ? Number(row.intake_three2) : 0) +
                    (row.intake_three4 ? Number(row.intake_three4) : 0) +
                    (row.intake_three6 ? Number(row.intake_three6) : 0) +
                    (row.intake_four2 ? Number(row.intake_four2) : 0) +
                    (row.intake_four4 ? Number(row.intake_four4) : 0) +
                    (row.intake_four6 ? Number(row.intake_four6) : 0) +
                    (row.intake_five2 ? Number(row.intake_five2) : 0) +
                    (row.intake_five4 ? Number(row.intake_five4) : 0) +
                    (row.intake_five6 ? Number(row.intake_five6) : 0);

                  return (
                    <>
                      <TableRow
                        key="일시"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          colSpan={3}
                          sx={{
                            borderTop: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography
                            style={{ fontSize: '12px', height: '18px' }}
                          >
                            {row.date ? row.date : '-'}
                          </Typography>
                        </TableCell>

                        <TableCell
                          colSpan={3}
                          sx={{
                            borderTop: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            {row.duty ? row.duty : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: `${palette.primary.main}`,
                              fontWeight: 500,
                            }}
                          >
                            intake
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            oral
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            fluid
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            etc
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow key="섭취 input 01">
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.intake1 ? row.intake1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake2 ? row.intake2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake3 ? row.intake3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake4 ? row.intake4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake5 ? row.intake5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake6 ? row.intake6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="섭취 intake 02"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.intake_two1 ? row.intake_two1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_two2 ? row.intake_two2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_two3 ? row.intake_two3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_two4 ? row.intake_two4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_two5 ? row.intake_two5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_two6 ? row.intake_two6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="섭취 intake 03"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.intake_three1 ? row.intake_three1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_three2 ? row.intake_three2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_three3 ? row.intake_three3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_three4 ? row.intake_three4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_three5 ? row.intake_three5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_three6 ? row.intake_three6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="섭취 intake 04"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.intake_four1 ? row.intake_four1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_four2 ? row.intake_four2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_four3 ? row.intake_four3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_four4 ? row.intake_four4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_four5 ? row.intake_four5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_four6 ? row.intake_four6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="섭취 intake 05"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.intake_five1 ? row.intake_five1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_five2 ? row.intake_five2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_five3 ? row.intake_five3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_five4 ? row.intake_five4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.intake_five5 ? row.intake_five5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.intake_five6 ? row.intake_five6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            borderBottom: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            total
                          </Typography>
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            borderBottom: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            {섭취총계}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={6}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {displayRows.map(row => {
                  const 섭취총계 =
                    (row.intake2 ? Number(row.intake2) : 0) +
                    (row.intake4 ? Number(row.intake4) : 0) +
                    (row.intake6 ? Number(row.intake6) : 0) +
                    (row.intake_two2 ? Number(row.intake_two2) : 0) +
                    (row.intake_two4 ? Number(row.intake_two4) : 0) +
                    (row.intake_two6 ? Number(row.intake_two6) : 0) +
                    (row.intake_three2 ? Number(row.intake_three2) : 0) +
                    (row.intake_three4 ? Number(row.intake_three4) : 0) +
                    (row.intake_three6 ? Number(row.intake_three6) : 0) +
                    (row.intake_four2 ? Number(row.intake_four2) : 0) +
                    (row.intake_four4 ? Number(row.intake_four4) : 0) +
                    (row.intake_four6 ? Number(row.intake_four6) : 0) +
                    (row.intake_five2 ? Number(row.intake_five2) : 0) +
                    (row.intake_five4 ? Number(row.intake_five4) : 0) +
                    (row.intake_five6 ? Number(row.intake_five6) : 0);

                  const 배설총계 =
                    (row.output2 ? Number(row.output2) : 0) +
                    (row.output4 ? Number(row.output4) : 0) +
                    (row.output6 ? Number(row.output6) : 0) +
                    (row.output_two2 ? Number(row.output_two2) : 0) +
                    (row.output_two4 ? Number(row.output_two4) : 0) +
                    (row.output_two6 ? Number(row.output_two6) : 0) +
                    (row.output_three2 ? Number(row.output_three2) : 0) +
                    (row.output_three4 ? Number(row.output_three4) : 0) +
                    (row.output_three6 ? Number(row.output_three6) : 0) +
                    (row.output_four2 ? Number(row.output_four2) : 0) +
                    (row.output_four4 ? Number(row.output_four4) : 0) +
                    (row.output_four6 ? Number(row.output_four6) : 0) +
                    (row.output_five2 ? Number(row.output_five2) : 0) +
                    (row.output_five4 ? Number(row.output_five4) : 0) +
                    (row.output_five6 ? Number(row.output_five6) : 0);

                  return (
                    <>
                      <TableRow
                        key="일시"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          colSpan={3}
                          sx={{
                            borderTop: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            total I/O
                          </Typography>
                        </TableCell>

                        <TableCell
                          colSpan={2}
                          sx={{
                            borderTop: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography
                            style={{ fontSize: '12px', marginLeft: '-90px' }}
                          >
                            {섭취총계 + 배설총계}
                          </Typography>
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            borderTop: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          {row.action}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: `${palette.primary.main}`,
                              fontWeight: 500,
                            }}
                          >
                            output
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            urine
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            stool
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              fontWeight: 400,
                            }}
                          >
                            etc
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow key="배설 output 01">
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.output1 ? row.output1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output2 ? row.output2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output3 ? row.output3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output4 ? row.output4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output5 ? row.output5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output6 ? row.output6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="배설 output 02"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.output_two1 ? row.output_two1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_two2 ? row.output_two2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_two3 ? row.output_two3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_two4 ? row.output_two4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_two5 ? row.output_two5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_two6 ? row.output_two6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="배설 output 03"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.output_three1 ? row.output_three1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_three2 ? row.output_three2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_three3 ? row.output_three3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_three4 ? row.output_three4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_three5 ? row.output_three5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_three6 ? row.output_three6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="배설 output 04"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.output_four1 ? row.output_four1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_four2 ? row.output_four2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_four3 ? row.output_four3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_four4 ? row.output_four4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_four5 ? row.output_four5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_four6 ? row.output_four6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key="배설 output 05"
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: '#01010176',
                            }}
                          >
                            {row.output_five1 ? row.output_five1 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_five2 ? row.output_five2 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_five3 ? row.output_five3 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_five4 ? row.output_five4 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography
                            style={{ fontSize: '12px', color: '#01010176' }}
                          >
                            {row.output_five5 ? row.output_five5 : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography style={{ fontSize: '12px' }}>
                            {row.output_five6 ? row.output_five6 : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          sx={{
                            borderBottom: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            total
                          </Typography>
                        </TableCell>
                        <TableCell
                          colSpan={1}
                          sx={{
                            borderBottom: `1px solid ${palette.primary.main} `,
                          }}
                        >
                          <Typography style={{ fontSize: '12px' }}>
                            {배설총계}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </RowContainer>
      </RowContainer>
    </Fragment>
  );
};

export default SocialHistory;
