import Form from 'components/Form';

import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import RowContentPre from './components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { Stack } from '@mui/system';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks_geni_1 = [
  {
    label: 'Voiding',
    key: 'assessment_genitour.checked1',
  },
  {
    label: 'Foley',
    key: 'assessment_genitour.checked2',
  },
  {
    label: 'Incontinence',
    key: 'assessment_genitour.checked3',
  },
  {
    label: 'Anuria',
    key: 'assessment_genitour.checked4',
  },
];

const checks_geni_2 = [
  {
    label: 'Clear',
    key: 'assessment_genitour.checked5',
  },
  {
    label: 'Cloudy',
    key: 'assessment_genitour.checked6',
  },
];

const checks_geni_3 = [
  {
    label: 'Yellow',
    key: 'assessment_genitour.checked7',
  },
  {
    label: 'Amber',
    key: 'assessment_genitour.checked8',
  },
  {
    label: 'Bloody',
    key: 'assessment_genitour.checked9',
  },
];

const checks_geni_4 = [
  {
    label: 'BR',
    key: 'assessment_genitour.checked10',
  },
  {
    label: 'Urinal',
    key: 'assessment_genitour.checked11',
  },
  {
    label: 'Bedpan',
    key: 'assessment_genitour.checked12',
  },
  {
    label: 'Bedside Comm',
    key: 'assessment_genitour.checked13',
  },
];

const checks_geni_dialysis = [
  {
    label: 'M',
    key: 'assessment_genitour_dialysis.checked1',
  },
  {
    label: 'Tu',
    key: 'assessment_genitour_dialysis.checked2',
  },
  {
    label: 'W',
    key: 'assessment_genitour_dialysis.checked3',
  },
  {
    label: 'Th',
    key: 'assessment_genitour_dialysis.checked4',
  },
  {
    label: 'F',
    key: 'assessment_genitour_dialysis.checked5',
  },
  {
    label: 'Sa',
    key: 'assessment_genitour_dialysis.checked6',
  },
  {
    label: 'Su',
    key: 'assessment_genitour_dialysis.checked7',
  },
];

const checks_muscul_weak = [
  {
    label: 'RUE',
    key: 'assessment_muscul_weak.checked1',
  },
  {
    label: 'LUE',
    key: 'assessment_muscul_weak.checked2',
  },
  {
    label: 'RLE',
    key: 'assessment_muscul_weak.checked3',
  },
  {
    label: 'LLE',
    key: 'assessment_muscul_weak.checked4',
  },
];

const checks_muscul_nuber = [
  {
    label: 'RUE',
    key: 'assessment_muscul_nuber.checked1',
  },
  {
    label: 'LUE',
    key: 'assessment_muscul_nuber.checked2',
  },
  {
    label: 'RLE',
    key: 'assessment_muscul_nuber.checked3',
  },
  {
    label: 'LLE',
    key: 'assessment_muscul_nuber.checked4',
  },
];

const checks_bg = [
  {
    label: 'AC&HS',
    key: 'assessment_bg.checked1',
  },
  {
    label: 'Q6',
    key: 'assessment_bg.checked2',
  },
  {
    label: '',
    key: 'assessment_bg.checked3',
  },
];

const checks_drain = [
  {
    label: 'Chest Tube',
    key: 'assessment_drains.checked1',
  },
  {
    label: 'JP',
    key: 'assessment_drains.checked2',
  },
  {
    label: 'Hemovac',
    key: 'assessment_drains.checked3',
  },
  {
    label: 'Accordion',
    key: 'assessment_drains.checked4',
  },
  {
    label: 'Wound Vac',
    key: 'assessment_drains.checked5',
  },
];

const checks_drain2 = [
  {
    label: 'R',
    key: 'assessment_drains.checked6',
  },
  {
    label: 'L',
    key: 'assessment_drains.checked7',
  },
  {
    label: '-',
    key: '',
  },
];

const checks_drain3 = [
  {
    label: 'Sanguineous',
    key: 'assessment_drains.checked8',
  },
  {
    label: 'Serosanguinous',
    key: 'assessment_drains.checked9',
  },
];

const Assessment4 = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <>
      <RowContainer
        xs={12}
        sx={{ margin: '0px 0px 5px 0px', marginLeft: '-25px' }}
      >
        <RowContent title="Genitourinary" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_geni_1.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_geni_2.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_geni_3.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_geni_4.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Dialysis
              </Typography>
            </Grid>
            {checks_geni_dialysis.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent
          title="Musculoskeletal"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Weakness
              </Typography>
            </Grid>
            {checks_muscul_weak.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Nuberness
              </Typography>
            </Grid>
            {checks_muscul_nuber.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>

        <RowContentPre
          title="Skin
(Wounds, 
Dressings)"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Form.MuiTextField
            required={false}
            sx={{}}
            disabled={disabled}
            multiline
            minRows={3}
            placeholder="Enter"
            {...register(`assessment_skin_input`)}
          />
        </RowContentPre>
        <RowContent title="BG Nonitoring" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_bg.map((v, i) => {
              return (
                <Grid item xs={i === 2 ? 0.2 : 1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckboxSubSup
                      label={v.label}
                      sup={i === 1 ? 'o' : ''}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              sx={{ width: '110px' }}
              disabled={disabled}
              placeholder="Enter"
              InputProps={{ ...Form.adornmentSup('Q', '0') }}
              {...register(`assessment_bg_input`)}
            />
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                AC B
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_bg_ac_b')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                AC L
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_bg_ac_l')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                AC D
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_bg_ac_d')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.4}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                HS
              </Typography>
            </Grid>

            <Grid item xs={2.9}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  disabled={disabled}
                  placeholder="Enter"
                  {...register('assessment_bg_hs')}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="Drains" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_drain.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_drain2.map((v, i) => {
              if (v.label === '-') {
                return (
                  <Grid item xs={1.4}>
                    <Stack direction="row">
                      <Typography
                        sx={{
                          fontSize: '12px',
                          whiteSpace: 'nowrap',
                          lineHeight: '38px',
                          marginRight: '15px',
                        }}
                      >
                        Level
                      </Typography>
                      <Form.MuiTextField
                        required={false}
                        disabled={disabled}
                        placeholder="Enter"
                        {...register('assessment_drains_input')}
                      />
                    </Stack>
                  </Grid>
                );
              }
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks_drain3.map((v, i) => {
              return (
                <Grid item xs={1.4}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default Assessment4;
