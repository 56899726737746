import Form from 'components/Form';

import { IFormValues, IFormRegister, IFormWatch } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from './components/RowContentNowrap';

import SectionTitle from '../components/SectionTitle';
import MuiCheckbox from './components/CheckboxFallingType';
import MuiCheckboxTwo from './components/CheckboxFallingTypeTwo';
import MuiRadio from './components/MuiRadioFallingType';
import MuiRadioFallingTwo from './components/MuiRadioFallingTwo';
import { Stack } from '@mui/material';

interface Props extends IFormValues, IFormRegister, IFormWatch {
  disabled?: boolean;
}

const FallingType = (props: Props) => {
  const { disabled, getValues, setValue, register } = props;

  return (
    <>
      <SectionTitle title="Incident Type - Fall or Injury" />
      <RowContainer xs={12}>
        <RowContent
          title="Level of Consciousness"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadio
            i18nKey="SAFETY.TYPE.CONSCIOUSNESS.LEVEL"
            values={[1, 2, 3, 4, 5]}
            disabled={disabled}
            defaultValue={getValues('falling_type.consciousness_level')}
            onChange={v => setValue('falling_type.consciousness_level', v)}
            width="auto"
          />
        </RowContent>
        <RowContent title="Ambulation" titleRatio={2} childrenRatio={10}>
          <MuiRadioFallingTwo
            i18nKey="SAFETY.TYPE.ACTIVITY.STATUS"
            values={[1, 2, 3, 4]}
            disabled={disabled}
            defaultValue={getValues('falling_type.activity_status')}
            onChange={v => setValue('falling_type.activity_status', v)}
            width="auto"
          />
        </RowContent>
        <RowContent
          title="Location of incident occurred"
          titleRatio={2}
          childrenRatio={10}
        >
          <Stack direction="row">
            <MuiRadio
              i18nKey="SAFETY.TYPE.PLACE.FALLING.ACCIDENT"
              values={[1, 2, 3, 4, 0]}
              disabled={disabled}
              defaultValue={getValues('falling_type.place_falling_accident')}
              onChange={v => setValue('falling_type.place_falling_accident', v)}
              width="auto"
            />
            <Form.MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.place_falling_accident_etc')}
            />
          </Stack>
        </RowContent>
        <RowContent
          title="Factors attributing to incident"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiCheckbox
            i18nNullKey="ETC"
            disabled={disabled}
            i18nKey="SAFETY.PATIENT.RISK.FACTORS"
            values={[1, 2, 3, 4]}
            defaultValue={getValues('falling_type.patient_risk_factors')}
            onChange={v => setValue('falling_type.patient_risk_factors', v)}
          />
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <MuiCheckbox
            i18nNullKey="ETC"
            disabled={disabled}
            i18nKey="SAFETY.PATIENT.RISK.FACTORS"
            values={[5, 6, 7, 8]}
            defaultValue={getValues('falling_type.patient_risk_factors')}
            onChange={v => setValue('falling_type.patient_risk_factors', v)}
          />
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row">
            <MuiCheckbox
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="SAFETY.PATIENT.RISK.FACTORS"
              values={[0]}
              defaultValue={getValues('falling_type.patient_risk_factors')}
              onChange={v => setValue('falling_type.patient_risk_factors', v)}
            />
            <Form.MuiTextField
              required={false}
              fullWidth={false}
              disabled={disabled}
              sx={{ width: '248px' }}
              placeholder="Enter"
              {...register('falling_type.assisting_devices')}
            />
          </Stack>
        </RowContent>

        <RowContent title="Physical Injury" titleRatio={2} childrenRatio={10}>
          <MuiCheckboxTwo
            i18nNullKey="ETC"
            disabled={disabled}
            i18nKey="SAFETY.PATIENT.RISK.INJURY"
            values={[1, 2, 3, 4, 5]}
            defaultValue={getValues('falling_type.patient_risk_factors_two')}
            onChange={v => setValue('falling_type.patient_risk_factors_two', v)}
          />
        </RowContent>
        <RowContent title="" titleRatio={2} childrenRatio={10}>
          <Stack direction="row">
            <MuiCheckboxTwo
              i18nNullKey="ETC"
              disabled={disabled}
              i18nKey="SAFETY.PATIENT.RISK.INJURY"
              values={[6, 7, 8, 9, 0]}
              defaultValue={getValues('falling_type.patient_risk_factors_two')}
              onChange={v =>
                setValue('falling_type.patient_risk_factors_two', v)
              }
            />
            <Form.MuiTextField
              required={false}
              fullWidth
              disabled={disabled}
              placeholder="Enter"
              {...register('falling_type.fall_type')}
            />
          </Stack>
        </RowContent>
      </RowContainer>
    </>
  );
};

export default FallingType;
