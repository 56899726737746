import { Fragment } from 'react';
import { Stack, Typography } from '@mui/material';

import Form from 'components/Form';
import CheckboxGroup from './components/CheckboxGroup';
import CheckboxOBG from './components/CheckboxOBG';

import { TGender } from 'apis/account/type';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import ShortRowContent from './components/RowContentSub';

import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister, IFormValues {
  gender: TGender;
  disabled?: boolean;
}

const Habit = (props: Props) => {
  const { gender, disabled, register, getValues, setValue } = props;

  // gender 여성인 경우 필수
  const isRequiredObstetric = gender === 1;

  return (
    <Fragment>
      <SectionTitle title="Habits" />
      <RowContainer xs={12}>
        <ShortRowContent title="Stool">
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              sx={{ width: '150px', marginRight: '65px' }}
              InputProps={{ ...Form.adornment('', '/day') }}
              {...register('habit.feces.value')}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.HABIT.FECES"
              values={[1, 2, 3, 4, 5, 0]}
              disabled={disabled}
              defaultValue={getValues('habit.feces_info.checked')}
              onChange={v => setValue('habit.feces_info.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="etc"
              sx={{ width: '140px' }}
              {...register('habit.feces.input')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Urine">
          <Stack direction="row" spacing={1}>
            <Form.MuiTextField
              type="number"
              textAlign="right"
              disabled={disabled}
              sx={{ width: '150px', marginRight: '65px' }}
              InputProps={{ ...Form.adornment('', '/day') }}
              {...register('habit.urine.value')}
            />
            <CheckboxGroup
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.HABIT.URINE"
              values={[1, 2, 3, 4, 5, 0]}
              disabled={disabled}
              defaultValue={getValues('habit.urine_info.checked')}
              onChange={v => setValue('habit.urine_info.checked', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="etc"
              sx={{ width: '140px' }}
              {...register('habit.urine.input')}
            />
          </Stack>
        </ShortRowContent>

        <ShortRowContent title="Alcohol">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit.drink.value')}
              onChange={v => setValue('habit.drink.value', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Period"
              sx={{ maxwidth: '120px', paddingLeft: '25px' }}
              {...register('habit.drink.input')}
            />
            <Form.MuiCheckbox
              label="Stop Drinking"
              disabled={disabled}
              defaultValue={getValues('habit.drink.checked')}
              {...register('habit.drink.checked')}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              placeholder="Period"
              sx={{ maxwidth: '50px' }}
              {...register('habit.drink.input2')}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Smoking">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="EXIST.SHORT"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit.smoke.value')}
              onChange={v => setValue('habit.smoke.value', v)}
            />
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              sx={{ maxwidth: '120px', paddingLeft: '25px' }}
              placeholder="Period"
              {...register('habit.smoke.input')}
            />
            <Form.MuiCheckbox
              label="Stop Smoking"
              disabled={disabled}
              defaultValue={getValues('habit.smoke.checked')}
              {...register('habit.smoke.checked')}
            />
            <Form.MuiTextField
              required={false}
              placeholder="Period"
              disabled={disabled}
              {...register('habit.smoke.input2')}
            />
          </Stack>
        </ShortRowContent>

        <ShortRowContent title="Sleep Disorder">
          <Stack direction="row" spacing={1}>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit.sleep')}
              onChange={v => setValue('habit.sleep', v)}
            />
          </Stack>
        </ShortRowContent>

        <ShortRowContent title="Nutrition Disorder">
          <Stack direction="row" spacing={1}>
            <Typography
              variant="caption"
              fontWeight="bold"
              lineHeight="38px"
              sx={{ marginRight: '30px' }}
            >
              Weight change in the last month
            </Typography>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit.nutrition.weight')}
              onChange={v => setValue('habit.nutrition.weight', v)}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="caption"
              fontWeight="bold"
              lineHeight="38px"
              sx={{ marginRight: '20px' }}
            >
              Appetite change in the last month
            </Typography>
            <Form.MuiRadioGroup
              i18nKey="NONE.EXIST"
              values={[1, 2]}
              disabled={disabled}
              defaultValue={getValues('habit.nutrition.appetite')}
              onChange={v => setValue('habit.nutrition.appetite', v)}
            />
          </Stack>
        </ShortRowContent>
        <ShortRowContent title="Obstetric History">
          <Stack direction="row" spacing={1}>
            <CheckboxOBG
              i18nNullKey="ETC"
              i18nKey="HOSPITALIZATION.HABIT.OBG"
              values={[1]}
              disabled={disabled}
              defaultValue={getValues('habit.feces_info.checked')}
              onChange={v => setValue('habit.feces_info.checked', v)}
            />
          </Stack>
          <Stack direction="row" spacing={1} gap={'26px'}>
            <Form.MuiTextField
              fullWidth={false}
              disabled={disabled}
              required={isRequiredObstetric}
              InputProps={{ ...Form.adornment('G') }}
              {...register('habit.obstetric.G')}
            />
            <Form.MuiTextField
              fullWidth={false}
              disabled={disabled}
              required={isRequiredObstetric}
              InputProps={{ ...Form.adornment('T') }}
              {...register('habit.obstetric.T')}
            />
            <Form.MuiTextField
              fullWidth={false}
              disabled={disabled}
              required={isRequiredObstetric}
              InputProps={{ ...Form.adornment('P') }}
              {...register('habit.obstetric.P')}
            />
            <Form.MuiTextField
              fullWidth={false}
              disabled={disabled}
              required={isRequiredObstetric}
              InputProps={{ ...Form.adornment('A') }}
              {...register('habit.obstetric.A')}
            />
            <Form.MuiTextField
              fullWidth={false}
              disabled={disabled}
              required={isRequiredObstetric}
              InputProps={{ ...Form.adornment('L') }}
              {...register('habit.obstetric.L')}
            />
          </Stack>
        </ShortRowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Habit;
