import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const CVCone = (props: Props) => {
  const { disabled, register } = props;

  const info = [
    'When the patient is clinically unstable and multiple fluid infusions are required.',
    'When administering medications with anticipated complications through peripheral veins for an extended or continuous duration (e.g., parenteral nutrition, vesicant drugs, \n     concentrated electrolytes, hypertonic solutions, etc.)',
    'When performing invasive hemodynamic monitoring.',
    'During prolonged intermittent infusion.',
    'When there is a history of difficulty or failure in peripheral venous catheter insertion even with ultrasound guidance.',
  ];

  return (
    <Fragment>
      <SectionTitle title="2. Indications for central venous catheter insertion." />
      <Box sx={{ margin: '38px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
            The indications for a central venous catheter are as follows.
          </Typography>

          {info.map(a => {
            return (
              <Grid item xs={11.5}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '30px',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  • {a}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CVCone;
