import { Fragment } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import MuiTable from 'components/MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import useTableForm from '../hooks/useTableForm';

interface Props extends IFormValues, IFormWatch {}

const FallScaleContents = (props: Props) => {
  const { palette } = useTheme();
  const { radioGroup, sumValues } = useTableForm(props);

  const columns = [
    {
      fieldId: 'title',
      label: 'Category',
      sx: { width: '180px' },
    },
    { fieldId: '0', label: '1 point' },
    { fieldId: '1', label: '2 point' },
    { fieldId: '2', label: '3 point' },
    { fieldId: '3', label: '4 point' },
  ];

  const rows = [
    {
      id: 'age',
      title: 'Age',
      ...radioGroup({
        key: 'contents.age',
        options: [1, 2, 3, 4],
        i18nKey: 'FALLSCALE.SCORE.AGE',
      }),
      width: '150px',
    },
    {
      id: 'sex',
      title: 'Gender',
      ...radioGroup({
        key: 'contents.sex',
        options: [1, 2],
        i18nKey: 'FALLSCALE.SCORE.GENDER',
      }),
      width: '150px',
    },
    {
      id: 'diagnosis',
      title: 'Diagnosis',
      ...radioGroup({
        key: 'contents.diagnosis',
        options: [1, 2, 3, 4],
        i18nKey: 'FALLSCALE.SCORE.DIANOSIS',
      }),
      width: '150px',
    },
    {
      id: 'cognitive_disorder',
      title: 'Cognitive Disorder',
      ...radioGroup({
        key: 'contents.cognitive_disorder',
        options: [1, 2, 3],
        i18nKey: 'FALLSCALE.SCORE.COGNITIVE',
      }),
      width: '150px',
    },
    {
      id: 'environmental_factors',
      title: 'Environmental Factors',
      ...radioGroup({
        key: 'contents.environmental_factors',
        options: [1, 2, 3],
        i18nKey: 'FALLSCALE.SCORE.ENVIRONMENTAL',
      }),
      width: '150px',
    },
    {
      id: 'surgical_sedative_anesthetic_factors',
      title: 'Surgical / Sedative / \nAnesthetic Factors',
      ...radioGroup({
        key: 'contents.surgical_sedative_anesthetic_factors',
        options: [1, 2, 3],
        i18nKey: 'FALLSCALE.SCORE.HISTORY',
        width: '150px',
      }),
    },
    {
      id: 'medication_use',
      title: 'Medication Usage',
      ...radioGroup({
        key: 'contents.medication_use',
        options: [1, 2, 3],
        i18nKey: 'FALLSCALE.SCORE.DRUG',
        width: '150px',
      }),
    },
  ];

  const watchSumValues = () => {
    const values = rows.map(({ id }) => Number(props.watch(`contents.${id}`)));
    return sumValues(values);
  };

  return (
    <Fragment>
      <SectionTitle title="Humty Dumpty Fall Scale" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            sx={{ marginRight: '210px' }}
            gutterBottom
            minWidth={115}
            fontWeight={700}
            variant="subtitle1"
          >
            Total : {watchSumValues()} point
          </Typography>
          <Typography
            minWidth={115}
            variant="caption"
            sx={{ color: `${palette.primary.main}` }}
          >
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                7-11 point :
              </Box>
              low-risk group
              <br />
              (No fall prevention activity record)
            </Typography>
            <Typography variant="inherit">
              <Box component={'strong'} mr={0.5}>
                12 point or more :
              </Box>
              High Risk
              <br />
              (Write down the fall prevention activity record 3 times/day)
            </Typography>
          </Typography>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default FallScaleContents;
