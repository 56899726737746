import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { ReactComponent as ChannelTalk } from '../../../assets/channelTalk.svg';
import regex from 'utils/regex';
import useNotification from 'hooks/useNotification';
import { postPwReset, postSendMailForPW, postVerifyMail } from 'apis/account';
import Form from 'components/Form';
import { LoadingButton } from '@mui/lab';
import { CheckCircle } from '@mui/icons-material';

interface Props {
  title: string;
  isOpen?: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
}

function PasswordConfirm(props: Props) {
  const { title, isOpen = false, maxWidth = 'xs', onClose } = props;
  const navigate = useNavigate();
  const { onResultCode, onSuccess, onFail, onRequired } = useNotification();

  const { handleSubmit, register, getValues, setValue } = useForm({
    defaultValues: { grade: 1, gender: 1 } as any,
  });

  // 이메일 유효성
  const [이메일, set이메일] = useState('');

  const emailRegex = () => {
    let check = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,10})$/;

    return check.test(이메일);
  };

  // 이름에 특수문자 넣지못하게 막는
  const [이름, set이름] = useState('');

  const [비밀번호, set비밀번호] = useState('');
  const [비밀번호확인, set비밀번호확인] = useState('');

  const passwordRegex = () => {
    let check =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^~\-\+\\()_=|])[A-Za-z\d@$!%*#?&^~\-\+\\()_=|]{8,}$/i;
    return check.test(비밀번호);
  };

  const [인증확인, set인증확인] = useState(false);

  const [isSendMail, setSendMail] = useState(false);
  const [isSendMailLoading, setSendMailLoading] = useState(false);
  const onSendMail = () => {
    setSendMailLoading(true);
    const user_email = getValues('userEmail');

    // 이메일 형식 확인
    if (!regex.email.test(user_email)) {
      onRequired('REQUIRED.EMAIL.FORMAT');
      return setSendMailLoading(false);
    }

    postSendMailForPW({ student_id: user_email, student_name: 이름 })
      .then(({ data: { rc } }) => {
        if (rc === 100) {
          return onResultCode(rc);
        }

        setSendMail(true);
        setIsVerification(false);
        onSuccess(
          'A verification code has been sent to the requested email address.'
        );
      })
      .catch(e => onFail('Failed to send authentication number.', e))
      .finally(() => setSendMailLoading(false));
  };

  // 이메일 인증
  const [isVerification, setIsVerification] = useState(false);
  const [isVerifyMailLoading, setVerifyMailLoading] = useState(false);
  const onVerifyMail = () => {
    setVerifyMailLoading(true);
    const user_code = getValues('userCode');
    const user_email = getValues('userEmail');

    postVerifyMail({ user_code, user_email })
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);
        set인증확인(true);
        setIsVerification(true);
        onSuccess('Email verified.');
      })
      .catch(e => onFail('Email verification failed.', e))
      .finally(() => setVerifyMailLoading(false));
  };

  const VerificationIcon = () => (
    <CheckCircle
      fontSize="small"
      color="primary"
      sx={{
        display: isVerification ? 'block' : 'none',
      }}
    />
  );

  const onSubmit = async () => {
    try {
      const request = {
        student_name: 이름,
        student_id: 이메일,
        new_password: 비밀번호,
      };

      const {
        data: { result_code: loginRc },
      } = await postPwReset(request);

      if (비밀번호.length > 0 && !regex.password.test(비밀번호)) {
        return onRequired('REQUIRED.PASSWORD.RECHECKED');
      }

      if (비밀번호 !== 비밀번호확인) {
        return onRequired('ERROR.CODE.107');
      }
      if (Number(loginRc) !== 200) {
        return onResultCode(Number(loginRc));
      }
      onSuccess('Create New Password has been completed.');
      onClose();
    } catch (e) {
      onFail('Failed.', e);
    }
  };

  return (
    <Dialog maxWidth={maxWidth} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <DialogActions>
          <Button
            variant="text"
            onClick={onClose}
            sx={{ marginRight: '-20px', color: 'black' }}
          >
            <CloseIcon />
          </Button>
        </DialogActions>
        <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
          Forgot your Password?
        </Typography>
      </DialogTitle>
      {!인증확인 ? (
        <DialogContent
          sx={{
            whiteSpace: 'pre-line',
            wordBreak: 'keep-all',
            lineHeight: '24px',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
            1. If you have only registered
            <br />
            on the SmartNurse homepage :
          </Typography>
          A separate registration is required on ENR.
          <div style={{ marginTop: '15px', marginBottom: '20px' }}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/signup')}
            >
              Smartnurse ENR Sign up
            </Button>
          </div>
          <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>
            2. If you have registered for ENR <br />
            but have forgotten your password:
          </Typography>
          You can reset your password <br />
          through email verification
          <Stack spacing={1} sx={{ marginTop: '20px' }}>
            <Form.Item label="Name">
              <TextField
                required
                fullWidth
                placeholder="Please enter your name."
                {...register('userName', {
                  onChange: e => set이름(e.target.value),
                })}
                autoComplete="off"
                size="small"
              />
            </Form.Item>
            <Form.Item label="Email">
              <Box display="flex" gap={1}>
                <TextField
                  color={emailRegex() ? 'success' : 'warning'}
                  required
                  fullWidth
                  placeholder="Please enter your email"
                  size="small"
                  type="email"
                  {...register('userEmail', {
                    onChange: e => set이메일(e.target.value),
                  })}
                />
                <LoadingButton
                  sx={{ maxHeight: 56, width: '80px' }}
                  loading={isSendMailLoading}
                  variant={isSendMail ? 'outlined' : 'contained'}
                  onClick={onSendMail}
                >
                  {isSendMail ? 'resend' : 'submit'}
                </LoadingButton>
              </Box>
            </Form.Item>
            <Form.Item label="Verification code" isHidden={!isSendMail}>
              <Box display="flex" gap={1}>
                <TextField
                  required
                  fullWidth
                  size="small"
                  placeholder="Please enter the number sent by email"
                  InputProps={{
                    readOnly: isVerification,
                    endAdornment: <VerificationIcon />,
                  }}
                  {...register('userCode')}
                />
                <LoadingButton
                  variant="contained"
                  loading={isVerifyMailLoading}
                  onClick={onVerifyMail}
                  sx={{
                    // display: !isVerification ? 'block' : 'none',
                    height: '36px',
                    padding: '6px 16px 6px 16px',
                    width: '80px',
                  }}
                >
                  Enter
                </LoadingButton>
              </Box>
            </Form.Item>
          </Stack>
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            whiteSpace: 'pre-line',
            wordBreak: 'keep-all',
            lineHeight: '24px',
          }}
        >
          <Typography
            sx={{ fontSize: '16px', fontWeight: '700', color: '#6A3A87' }}
          >
            Create New Password
          </Typography>
          <div
            style={{
              marginTop: '15px',
              marginBottom: '20px',
              border: '1px solid #32313144',
              borderRadius: '4px',
              padding: '12px 16px 12px 16px',
            }}
          >
            <Box></Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 400, whiteSpace: 'pre' }}
            >
              Name :{'          '} {이름}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 400,
                whiteSpace: 'pre',
                marginTop: '20px',
              }}
            >
              Email :{'      '} {이메일}
            </Typography>
          </div>

          <Stack spacing={1} sx={{ marginTop: '20px' }}>
            <Form.Item label="New Password">
              <Form.Password
                required={false}
                color={passwordRegex() ? 'success' : 'warning'}
                helperText={
                  passwordRegex()
                    ? 'Safe!'
                    : 'special characters @ $ ! % * # ^ () _ = | ]'
                }
                fullWidth
                size="small"
                placeholder="8 characters, English letters + numbers + special characters"
                onChange={e => set비밀번호(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Repeat Password">
              <Form.Password
                required={false}
                size="small"
                fullWidth
                isHideVisibleBtn
                helperText={
                  비밀번호 === 비밀번호확인 ? 'Match!' : "Doesn't match!"
                }
                color={비밀번호 === 비밀번호확인 ? 'success' : 'warning'}
                placeholder="Please enter your password again."
                onChange={e => set비밀번호확인(e.target.value)}
              />
            </Form.Item>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => onSubmit()}
              sx={{ mb: 2.5 }}
            >
              SAVE
            </Button>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
}

export default PasswordConfirm;
