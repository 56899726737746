import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';

import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  // onRequired: (id: Ti18nId) => void;
  // onSuccess: (message: string) => void;
}

const Counselor = (props: Props) => {
  const { disabled, register } = props;

  const infos = [
    '1mg = 1cc ',
    '1kg = 1000g',
    '1g = 1000mg',
    '1mg = 1000mcg',
    '1mcg = 1000ng',
  ];

  const infoss = [
    '30cc/hr = 10gtt = one drop per 6 seconds',
    '45cc/hr = 15gtt = one drop per 4 seconds',
    '60cc/hr = 20gtt = one drop per 3 seconds',
    '90cc/hr = 30gtt = one drop per 2 seconds',
    '180cc/hr = 60gtt = one drop per second',
  ];

  return (
    <Fragment>
      <SectionTitle title="Unit for Reference" />
      <RowContainer xs={12} sx={{ marginTop: '20px' }}>
        <RowContainer xs={6} sx={{ marginLeft: '-150px' }}>
          {infos.map((v, i) => (
            <RowContent title="">
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '38px',
                  whiteSpace: 'pre',
                }}
              >
                ・ {v}
              </Typography>
            </RowContent>
          ))}
        </RowContainer>
        <RowContainer xs={6} sx={{ marginLeft: '80px' }}>
          {infoss.map((v, i) => (
            <RowContent title="">
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '38px',
                  whiteSpace: 'pre',
                }}
              >
                ・ {v}
              </Typography>
            </RowContent>
          ))}
        </RowContainer>
      </RowContainer>
    </Fragment>
  );
};

export default Counselor;
