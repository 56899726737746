import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import imgAwsAhnlabCloudLogo from 'assets/aws-ahnlab-cloud-logo.svg';
import imgSmartNurseLogoGlobal from 'assets/smart-nurse-logo-global.svg';
import Form from 'components/Form';
import MuiDialog from 'components/MuiDialog';

import theme from 'styles/theme';
import ConfirmButton from './components/ConfirmButton';
import PWConfirmButton from './components/PWConfirmButton';

interface Props {
  errors: { [x: string]: any };
  register: UseFormRegister<FieldValues>;
  onSignUp: () => void;
}

function SignInForm({ errors, register, onSignUp }: Props) {
  const { palette } = useTheme();

  return (
    <Box>
      <Container maxWidth="sm" sx={{ mt: 10, mb: 6 }}>
        <Card
          elevation={1}
          sx={{ borderTop: `solid 8px ${palette.primary.main}` }}
        >
          <CardMedia
            height={120}
            component="img"
            image={imgSmartNurseLogoGlobal}
            sx={{ objectFit: 'contain', mt: 5, mb: 5 }}
          />
          <CardContent
            sx={{ width: '100%', textAlign: 'center', padding: '0 0 40px 0' }}
          >
            <Typography>Together with the realistic patient data</Typography>
            <Typography sx={{ color: `${theme.palette.primary.main}` }}>
              Experience the best hospital Record System!
            </Typography>
          </CardContent>
          <CardContent sx={{ ml: 5, mr: 5 }}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              variant="outlined"
              sx={{ mb: 2.5 }}
              error={Boolean(errors.userEmail)}
              helperText={errors.userEmail?.message}
              {...register('userEmail', {
                required: 'Please enter your email address',
              })}
            />
            <Form.Password
              fullWidth
              label="Password"
              sx={{ mb: 0.25 }}
              error={Boolean(errors.userPassword)}
              helperText={errors.userPassword?.message}
              {...register('userPassword', {
                required: 'Please enter your password',
              })}
            />
            <FormControlLabel
              control={<Checkbox defaultChecked {...register('saveEmail')} />}
              label="Save Email"
            />
          </CardContent>
          <CardActions sx={{ ml: 5, mr: 5, pb: 5 }}>
            <Box flex={1} textAlign="center">
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{ mb: 2.5 }}
              >
                Log in
              </Button>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mb: 2.5 }}
                onClick={onSignUp}
              >
                Sign Up
              </Button>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'center',
                }}
              >
                <ConfirmButton title="Forgot email" color={'black'} />
                <Typography sx={{ lineHeight: '28px', fontSize: '12px' }}>
                  {' | '}
                </Typography>
                <PWConfirmButton title="Forgot password" color={'black'} />
              </Box>

              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mt: 2.5, mb: 2.5 }}
                onClick={() =>
                  window.open('https://smartnurse-global-howtouse.oopy.io/')
                }
              >
                Smart ENR Manual
              </Button>

              <Typography sx={{ fontSize: '0.8125rem' }}>
                inquiries: nurse@smartnurse.co.kr
              </Typography>
            </Box>
          </CardActions>

          <CardMedia
            height={28}
            component="img"
            image={imgAwsAhnlabCloudLogo}
            sx={{ objectFit: 'contain', mb: 1 }}
          />
          <Typography fontSize={10} align="center" sx={{ mb: 2 }}>
            copyright © 2024 DKMediInfo. All rights reserved.
          </Typography>
        </Card>
      </Container>
    </Box>
  );
}

export default SignInForm;
