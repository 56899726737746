import { IFormRegister } from 'routes/Main/type';
import { Box, Typography, useTheme } from '@mui/material';

import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;
  const { palette } = useTheme();

  const labels = [
    { title: 'Name of Patient', variable: 'recorde_person_name' },
    {
      title: 'Signature of Patient',
      variable: 'recorde_person_sig',
    },
    { title: 'Date', variable: 'recorde_date' },
  ];

  const labels2 = [
    { title: 'Name of Physician', variable: 'register_person_name' },
    { title: 'Signature of Physician', variable: 'register_person_sig' },
    { title: 'Date', variable: 'register_date' },
  ];

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  return (
    <>
      <RowContainer xs={12} mb={3}>
        {labels.map(({ title, variable }, _) => (
          <>
            {variable === 'recorde_date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={0.5}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  type="date"
                  disabled={disabled}
                  defaultValue={materialDateInput}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.3}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
        <Box
          sx={{
            width: '95.5%',
            margin: '20px auto 10px auto',
          }}
        >
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '15px',
              lineHeight: '24px',
              whiteSpace: 'pre-wrap',
            }}
          >
            I certify that I have explained the nature, purpose, benefits,
            risks, complications, and alternatives to the proposed procedure to
            the patient or the patient’s legal representative. I have
            <br />
            answered all questions fully, and I believe that the patient and/or
            legal representative fully understand(s) what I have explained.
          </Typography>
        </Box>

        {labels2.map(({ title, variable }, _) => (
          <>
            {variable === 'register_date' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={0.5}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  type="date"
                  disabled={disabled}
                  defaultValue={materialDateInput}
                  {...register(`${variable}`)}
                />
              </RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.3}
                childrenRatio={2}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default Signature;
