import { Button } from '@mui/material';
import { Fragment, useState } from 'react';
import ConfirmDialog from './PasswordConfirm';

interface Props {
  title: string;
  color?: string;
}

function PWConfirmButton({ title, color }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <Fragment>
      <Button
        size="small"
        variant="text"
        onClick={onOpen}
        sx={{ color: color || '' }}
      >
        {title}
      </Button>

      <ConfirmDialog title={title} isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
}

export default PWConfirmButton;
