import { Box, Typography, useTheme } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Terms of Agreement',
  "1. Patient agrees to abide by all the hospital rules and regulations, as well as all directives issued by any hospital representatives. Once the doctor, after his/her judgement, issues a discharge of any kind for the patient, the patient must follow the doctor's instruction.",
  '2. Patient/guarantor must pay the full medical expenses to hospital by the deadline agreed upon. If the patient does not pay the full medical expenses, the hospital will retain all contents within the deposit the patient/guarantor has made during hospitalization and will pursue legal actions against the patient/guarantor in a court within Korean jurisdiction.',
  '3. If the patient is not covered by Korean medical insurance plans, he/she must either have a Korean guarantor with the financial resources to sponsor the patient, or deposit ($ ) or more as determined by the hospital which can be applied to the total cost of the medical treatment. However, any amount beyond the deposit must be paid within the payment due date enlisted by the hospital by the patient or a guarantor in collective responsibility.',
  '4. General medical fees (medical conduct, medical supplies, medical materials, medical equipment, etc) which are exempt from the payment of health insurance will be paid by the patient and guarantor in collective responsibility.',
  '5. Patient agrees to accept liability for any loss or damages caused directly/indirectly by the patient to the hospital and/or its staff and/or its equipments/furniture, thereby accepting the financial responsibility to replace or correct such loss or damage(s) before being discharged from the hospital.',
  "6. The hospital dose not have responsibility for the patients/guests of patient's/patient guardian's personal property/valuables/money that is lost/misplaced/damaged/stolen during the patient's stay at the hospital.",
  '7. In case of staying in other than regular room, the patient assumes the special food provided by the hospital and extra cost for the food and the room.',
  "8. For any problems occurred during or after patient's hospitalization/operation/procedures/treatment/care, the patient will not pose any legal action against the hospital with the exception of medical errors by the hospital",
];

const PatientsRights = (props: Props) => {
  const { disabled } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="Patient’s Rights" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 10px auto',
        }}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '15px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you have any questions about this document or do not understand any
          portion of it, or need an interpreter, ask your physician or other
          health provider.
        </Typography>
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '0px auto 10px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '20px auto 20px auto',
        }}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '15px',
            lineHeight: '24px',
            whiteSpace: 'pre-wrap',
            color: `${palette.primary.main}`,
          }}
        >
          I have read and understand the above statements
        </Typography>
      </Box>
    </>
  );
};

export default PatientsRights;
