import Complete from './Complete';
import TermsOfService from './TermsOfService';
import PersonalInfo from './PersonalInfo';

const SignUpDialog = {
  Complete,
  TermsOfService,
  PersonalInfo,
};

export default SignUpDialog;
