import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MuiDialog from 'components/MuiDialog';
import { TFallDefaultValues, SurveyDialogProps } from 'routes/Main/Survey/type';

import Calculator1 from './Calculator1';
import Calculator2 from './Calculator2';
import Calculator3 from './Calculator3';
import CautionList from './CautionList';
import Counselor from './Counselor';

const DrugCalculatior = (props: SurveyDialogProps<TFallDefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    patientInfo,
    nurseName,
    onClose,
  } = props;

  const { handleSubmit, watch, register, getValues, setValue } = useForm({
    defaultValues,
  });

  const formProps = {
    disabled,
    watch,
    register,
    getValues,
    setValue,
  };

  return (
    <MuiDialog.SurveyFormColumn
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      // onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={3}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          Drug calculator
        </Typography>
        <Calculator1 {...formProps} />
        <Calculator2 {...formProps} />
        <Calculator3 {...formProps} />
        <CautionList {...formProps} />
        <Counselor {...formProps} />
      </Grid>
    </MuiDialog.SurveyFormColumn>
  );
};

export default DrugCalculatior;
