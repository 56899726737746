import { useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormWatch, IFormValues } from 'routes/Main/type';

import { Grid, Box, MenuItem } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { AccessTime } from '@mui/icons-material';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormWatch, IFormValues {
  disabled?: boolean;
}

interface IContents {
  [index: string]: string | null;
  accident_date: string;
  accident_time: string | null;
  arrival_time: string | null;
  visitMethod: string;
  visitReason: string;
}

const EmergencyRecord = (props: Props) => {
  const { disabled, register, watch, getValues, setValue } = props;
  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);

  const timePickers = [
    {
      title: 'Occurrence Time',
      variable: 'emergency_information.accident_time',
    },
    { title: 'Arrival Time', variable: 'emergency_information.arrival_time' },
  ];

  return (
    <>
      <SectionTitle title="Transfer Summary" />
      <RowContainer xs={12}>
        <RowContent title="Accident date" titleRatio={1} childrenRatio={2}>
          <Form.MuiTextField
            type="date"
            label="Please enter a date"
            InputLabelProps={{ shrink: true }}
            required={false}
            value={date}
            defaultValue={materialDateInput}
            disabled={disabled}
            {...register('emergency_information.accident_date', {
              onChange: e => setDate(e.target.value),
            })}
          />
        </RowContent>
        {timePickers.map(({ title, variable }, _) => (
          <RowContent
            key={variable}
            title={title}
            titleRatio={1}
            childrenRatio={2}
          >
            <MobileTimePicker
              disabled={disabled}
              value={watch(variable) || null}
              onChange={value => setValue(variable, value)}
              renderInput={params => (
                <MuiTextField
                  {...params}
                  required={false}
                  placeholder="00:00 pm"
                  InputProps={{ endAdornment: <AccessTime /> }}
                />
              )}
            />
          </RowContent>
        ))}
        <RowContent title="Mode of arrival" titleRatio={1} childrenRatio={2}>
          <Box display="flex">
            <Form.MuiTextField
              select
              required={false}
              disabled={disabled}
              defaultValue={getValues('emergency_information.visit_method')}
              {...register('emergency_information.visit_method')}
            >
              <MenuItem value="ambulance">Ambulatory</MenuItem>
              <MenuItem value="stretcher">Stretcher</MenuItem>
              <MenuItem value="wx">WC</MenuItem>
              <MenuItem value="police">Police</MenuItem>
              <MenuItem value="medics">Medics</MenuItem>
            </Form.MuiTextField>
          </Box>
        </RowContent>
        <Grid item xs={12}>
          <Form.MuiTextField
            multiline
            minRows={8}
            disabled={disabled}
            required={false}
            {...register('emergency_information.memo')}
            placeholder={'Chief Complaint'}
          />
        </Grid>
      </RowContainer>
    </>
  );
};

export default EmergencyRecord;
