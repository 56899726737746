import { useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

import { Box, MenuItem } from '@mui/material';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
  time: null | string;
  setTime: (time: null | string) => void;
}

const NewInfo = (props: Props) => {
  const { disabled, register, watch, time, setTime } = props;

  const [damageEtc, setDamageEtc] = useState(0);
  const [intentionalEtc, setIntentionalEtc] = useState(0);
  const [diseaseEtc, setDiseaseEtc] = useState(0);
  const [postureEtc, setintentionalEtc] = useState(0);

  const [methodEtc, setMethodEtc] = useState(0);

  const contents = [
    {
      label: 'Airway',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            select
            required={false}
            {...register('emergency_patient_information.symptom')}
          >
            <MenuItem value="patent">Patent</MenuItem>
            <MenuItem value="compromised">Compromised</MenuItem>
          </Form.MuiTextField>
        </Box>
      ),
    },
    {
      label: 'Breathing',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            select
            required={false}
            {...register('emergency_patient_information.etc_symptom')}
          >
            <MenuItem value="no">No respiratory distress</MenuItem>
            <MenuItem value="mild">Mild distress</MenuItem>
            <MenuItem value="moderate">Moderate distress</MenuItem>
            <MenuItem value="severe">Severe distress</MenuItem>
          </Form.MuiTextField>
        </Box>
      ),
    },
    {
      label: 'Breath Sounds',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            select
            required={false}
            {...register('emergency_patient_information.damage')}
            onChange={e => {
              if (e.target.value === 'other') setDamageEtc(1);
              else setDamageEtc(0);
            }}
          >
            <MenuItem value="clear">Equal, clear bilaterally</MenuItem>
            <MenuItem value="crackles">Crackles</MenuItem>
            <MenuItem value="wheezes">Wheezes</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Form.MuiTextField>
          {damageEtc ? (
            <Form.MuiTextField
              {...register('emergency_patient_information.ktas')}
              placeholder="Direct Input"
              sx={{ marginLeft: '5px' }}
            />
          ) : null}
        </Box>
      ),
    },
    {
      label: 'Circulation',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.intentional')}
        />
      ),
    },
    {
      label: 'Hydration',
      element: (
        <Box display="flex">
          <Form.MuiTextField
            select
            required={false}
            {...register('emergency_patient_information.medical_conditions')}
          >
            <MenuItem value="well">Well hydrated</MenuItem>
            <MenuItem value="mild">Mild dehydration</MenuItem>
            <MenuItem value="moderate">Moderate dehydration</MenuItem>
            <MenuItem value="severe">Severe dehydration</MenuItem>
          </Form.MuiTextField>
        </Box>
      ),
    },
    {
      label: 'Skin',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.motor')}
        />
      ),
    },
    {
      label: 'Neurologic',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.consciousness')}
        />
      ),
    },
    {
      label: 'GCS',
      element: (
        <Form.MuiTextField {...register('emergency_patient_information.gcs')} />
      ),
    },

    {
      label: 'Pupil',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.pupil')}
        />
      ),
    },
    {
      label: 'Pain Scale',
      element: (
        <Form.MuiTextField {...register('emergency_patient_information.nrs')} />
      ),
    },

    {
      label: 'Allergies',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.allergy')}
        />
      ),
    },

    {
      label: 'Triage',
      element: (
        <Form.MuiTextField
          {...register('emergency_patient_information.history')}
        />
      ),
    },
  ];

  return (
    <>
      <SectionTitle title="Patient information" />
      <RowContainer xs={12}>
        {contents.map(({ label, element }) => (
          <>
            <RowContent
              title={label}
              titleRatio={1}
              childrenRatio={label === 'surgical position' ? 5 : 2}
            >
              {element}
            </RowContent>
          </>
        ))}
      </RowContainer>
    </>
  );
};

export default NewInfo;
