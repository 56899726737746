import { Box, Skeleton, Toolbar } from '@mui/material';

import { inputInformationWidth } from '../InputInformation';
import { StyledContentContainer } from '../style';

import PatientInfo from './PatientInfo';
import SearchToolbar from './SearchToolbar';

import theme from 'styles/theme';

import Content from './Content';

interface Props {
  menuDrawerWidth: number;
  coachRefA: any;
  coachRefB: any;
}

const DisplayInformation = (props: Props) => {
  const { menuDrawerWidth, coachRefA, coachRefB } = props;

  // shallowEqual 해도 리렌더링 발생해서 이곳만 별도로 선택해서 사용

  const containerWidth = {
    xs:
      menuDrawerWidth !== 220
        ? `calc(100% - ${inputInformationWidth.xs}px)`
        : `calc(100% - ${menuDrawerWidth}px - ${inputInformationWidth.xs}px)`,
    xl:
      menuDrawerWidth !== 220
        ? `calc(100% - ${menuDrawerWidth}px - ${inputInformationWidth.xl}px)`
        : `calc(100% - ${menuDrawerWidth}px - ${inputInformationWidth.xl}px)`,
  };

  return (
    <Box
      display="flex"
      component="main"
      flexDirection="column"
      width={containerWidth}
    >
      <Toolbar
        sx={{ boxShadow: '-4px 4px 10px rgba(0, 0, 0, 0.04)' }}
        ref={coachRefA}
      >
        <SearchToolbar />
      </Toolbar>

      <StyledContentContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: `${theme.palette.primary.light}`,
        }}
      >
        <PatientInfo />
        <Content coachRef={coachRefB} />
      </StyledContentContainer>
    </Box>
  );
};

export default DisplayInformation;
