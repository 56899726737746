import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

interface Props {
  title: string;
  message: string;
  isOpen?: boolean;
  maxWidth?: Breakpoint;
  onClose: () => void;
}

function Confirm(props: Props) {
  const { title, message, isOpen = false, maxWidth = 'xs', onClose } = props;

  return (
    <Dialog maxWidth={maxWidth} open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          whiteSpace: 'pre-line',
          wordBreak: 'keep-all',
          lineHeight: '24px',
        }}
      >
        {message}
        <div style={{ marginTop: '1rem' }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mt: 1, mb: 0.5 }}
            onClick={() => window.open('https://e1p4z.channel.io/home')}
          >
            CONTACT US
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirm;
