import Form from 'components/Form';

import { Fragment } from 'react';
import { Typography } from '@mui/material';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const GuardianInfo = (props: Props) => {
  const { disabled, register } = props;

  const infos = [
    { title: 'A. Reasons for the Use of Physical Restraints', value: 'no2_1' },
    {
      title:
        'B. Alternative methods employed instead of physical restraints and their effectiveness',
      value: 'no2_2',
    },
    {
      title:
        'C. Areas of Application, Types, and Utilization Techniques of Physical Restraints',
      value: 'no2_3',
    },
    {
      title:
        'D. Potential Side Effects and Countermeasures during the Use of Physical Restraints',
      value: 'no2_4',
    },
    { title: 'E. Other Matters', value: 'no2_5' },
  ];

  const labels = [
    { title: '', variable: '' },
    { title: "Doctor's name", variable: 'no2_dr_name' },
    { title: "Doctor's signature", variable: 'no2_dr_sign' },
  ];

  return (
    <Fragment>
      <SectionTitle title="2. Description  and Details (Must Include Sections A to E)" />
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        {infos.map(({ title, value }) => (
          <>
            <Typography
              sx={{
                lineHeight: '20px',
                margin: '20px 0 0 15px',
                fontSize: '14px',
              }}
            >
              {title}
            </Typography>
            <Form.MuiTextField
              sx={{
                margin: '0px 0 0 15px',
              }}
              multiline
              minRows={3}
              required={false}
              disabled={disabled}
              {...register(`${value}`)}
            />
          </>
        ))}

        {labels.map(({ title, variable }, _) => (
          <>
            {Array(2)
              .fill(0)
              .map(() => (
                <RowContent titleRatio={1.5} childrenRatio={2.5} />
              ))}
            {variable === '' ? (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              ></RowContent>
            ) : (
              <RowContent
                key={variable}
                title={title}
                titleRatio={1.5}
                childrenRatio={2.5}
              >
                <Form.MuiTextField
                  disabled={disabled}
                  required={false}
                  {...register(`${variable}`)}
                />
              </RowContent>
            )}
          </>
        ))}
      </RowContainer>
    </Fragment>
  );
};

export default GuardianInfo;
