import { useForm } from 'react-hook-form';

import useSurvey from 'store/survey/useSurvey';
import useNotification from 'hooks/useNotification';

import { Typography, Box, Grid } from '@mui/material';
import MuiDialog from 'components/MuiDialog';

import { SurveyDialogProps, TSBAR3DefaultValues } from '../type';
import { updateSBAR3 } from 'apis/survey';

import CommonPatientInfo from '../components/CommonPatientInfo';
import Situation from './Situation';
import Background from './Background';
import Assessment1 from './Assessment1';
import Recommendation from './Recommendation';
import Assessment2 from './Assessment2';
import Assessment3 from './Assessment3';
import Assessment4 from './Assessment4';
import Assessment5 from './Assessment5';

const SBAR3 = (props: SurveyDialogProps<TSBAR3DefaultValues>) => {
  const {
    title,
    isOpen,
    disabled,
    defaultValues,
    user_id,
    nurseName,
    patientInfo,
    onClose,
  } = props;

  const { onUpdateIsSave } = useSurvey();
  const { onSuccess, onFail, onResultCode, onRequired } = useNotification();
  const { handleSubmit, watch, getValues, setValue, register } = useForm({
    defaultValues,
  });

  const onSubmit = (data: TSBAR3DefaultValues) => {
    const {
      situation_input,

      background_pmh,
      background_tests,
      background_tests_input,

      assessment_contact,
      assessment_need,
      assessment_extras,
      assessment_iv_input,
      assessment_iv_date,
      assessment_iv_rs,
      assessment_iv_site,
      assessment_iv_site_input,
      assessment_iv_central,

      assessment_drips_ivf,
      assessment_drips_ivf_input,
      assessment_drips_iv_input,
      assessment_drips_iv_date,
      assessment_drips_drips,
      assessment_neuro,
      assessment_neuro_input,
      assessment_neuro_activity,

      assessment_pain_input1,
      assessment_pain_input2,
      assessment_pain_input3,
      assessment_pain_input4,

      assessment_res,
      assessment_res_input,
      assessment_res_input_trach,
      assessment_res_input_sound,
      assessment_res_input_treat,
      assessment_res_input_cough,

      assessment_vs_hr,
      assessment_vs_temp,
      assessment_vs_bp,
      assessment_vs_rr,
      assessment_vs_o2,

      assessment_cardio,
      assessment_cardio_edema,
      assessment_cardio_pulse,
      assessment_vte,
      assessment_gastro_diet,
      assessment_gastro_symptom,
      assessment_gastro_tube,
      assessment_genitour,
      assessment_genitour_dialysis,
      assessment_muscul_weak,
      assessment_muscul_nuber,
      assessment_skin_input,
      assessment_bg,
      assessment_bg_input,
      assessment_bg_ac_b,
      assessment_bg_ac_l,
      assessment_bg_ac_d,
      assessment_bg_hs,

      assessment_drains,
      assessment_drains_input,
      assessment_lap_0,
      assessment_lap_1,
      assessment_lap_2,
      assessment_lap_3,
      assessment_lap_4,
      assessment_lap_5,
      assessment_lap_6,
      assessment_lap_7,
      assessment_lap_8,
      assessment_lap_9,
      assessment_lap_10,
      assessment_lap_11,
      assessment_lap_12,
      assessment_lap_13,

      recommendation_schedule,
      recommendation_consults,
      recommendation_discharge,
    } = data;

    const request = {
      user_id,
      patient_id: patientInfo.patient_id,
      sbar3_survey: {
        situation_input,

        background_pmh: JSON.stringify(background_pmh),
        background_tests: JSON.stringify(background_tests),
        background_tests_input,

        assessment_contact: JSON.stringify(assessment_contact),
        assessment_need: JSON.stringify(assessment_need),
        assessment_extras: JSON.stringify(assessment_extras),
        assessment_iv_input,
        assessment_iv_date,
        assessment_iv_rs: JSON.stringify(assessment_iv_rs),
        assessment_iv_site: JSON.stringify(assessment_iv_site),
        assessment_iv_site_input,
        assessment_iv_central: JSON.stringify(assessment_iv_central),

        assessment_drips_ivf: JSON.stringify(assessment_drips_ivf),
        assessment_drips_ivf_input,
        assessment_drips_iv_input,
        assessment_drips_iv_date,
        assessment_drips_drips: JSON.stringify(assessment_drips_drips),
        assessment_neuro: JSON.stringify(assessment_neuro),
        assessment_neuro_input,
        assessment_neuro_activity: JSON.stringify(assessment_neuro_activity),

        assessment_pain_input1,
        assessment_pain_input2,
        assessment_pain_input3,
        assessment_pain_input4,

        assessment_res: JSON.stringify(assessment_res),
        assessment_res_input,
        assessment_res_input_trach: JSON.stringify(assessment_res_input_trach),
        assessment_res_input_sound: JSON.stringify(assessment_res_input_sound),
        assessment_res_input_treat: JSON.stringify(assessment_res_input_treat),
        assessment_res_input_cough: JSON.stringify(assessment_res_input_cough),

        assessment_vs_hr,
        assessment_vs_temp,
        assessment_vs_bp,
        assessment_vs_rr,
        assessment_vs_o2,

        assessment_cardio: JSON.stringify(assessment_cardio),
        assessment_cardio_edema: JSON.stringify(assessment_cardio_edema),
        assessment_cardio_pulse: JSON.stringify(assessment_cardio_pulse),
        assessment_vte: JSON.stringify(assessment_vte),
        assessment_gastro_diet: JSON.stringify(assessment_gastro_diet),
        assessment_gastro_symptom: JSON.stringify(assessment_gastro_symptom),
        assessment_gastro_tube: JSON.stringify(assessment_gastro_tube),
        assessment_genitour: JSON.stringify(assessment_genitour),
        assessment_genitour_dialysis: JSON.stringify(
          assessment_genitour_dialysis
        ),
        assessment_muscul_weak: JSON.stringify(assessment_muscul_weak),
        assessment_muscul_nuber: JSON.stringify(assessment_muscul_nuber),
        assessment_skin_input,
        assessment_bg: JSON.stringify(assessment_bg),
        assessment_bg_input,
        assessment_bg_ac_b,
        assessment_bg_ac_l,
        assessment_bg_ac_d,
        assessment_bg_hs,

        assessment_drains: JSON.stringify(assessment_drains),
        assessment_drains_input,
        assessment_lap_0,
        assessment_lap_1,
        assessment_lap_2,
        assessment_lap_3,
        assessment_lap_4,
        assessment_lap_5,
        assessment_lap_6,
        assessment_lap_7,
        assessment_lap_8,
        assessment_lap_9,
        assessment_lap_10,
        assessment_lap_11,
        assessment_lap_12,
        assessment_lap_13,

        recommendation_schedule: JSON.stringify(recommendation_schedule),
        recommendation_consults: JSON.stringify(recommendation_consults),
        recommendation_discharge: JSON.stringify(recommendation_discharge),
      },
    };

    updateSBAR3(request)
      .then(({ data: { rc } }) => {
        if (rc !== 1) return onResultCode(rc);

        onUpdateIsSave(true);
        onSuccess('SBAR3 has been saved successfully.');
      })
      .catch(e => {
        onFail('Failed to save SBAR3.', e);
      });
  };

  const formProps = {
    disabled,
    watch,
    getValues,
    setValue,
    onSuccess,
    onRequired,
    register,
  };

  return (
    <MuiDialog.SurveyForm
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={disabled ? undefined : handleSubmit(onSubmit)}
      update_at={defaultValues?.update_at}
    >
      <Grid
        container
        wrap="wrap"
        rowSpacing={5}
        columnSpacing={2}
        sx={{ py: 5, px: 1 }}
      >
        <Typography
          sx={{
            margin: '40px auto 0px auto',
            fontWeight: '700',
            fontSize: '16px',
            textAlign: 'center',
          }}
        >
          SBAR3
        </Typography>
        <CommonPatientInfo patientInfo={patientInfo} nurseName={nurseName} />
        <Box sx={{ marginTop: '48px' }}>
          <Situation {...formProps} />
          <Background {...formProps} />
          <Assessment1 {...formProps} />
          <Assessment2 {...formProps} />
          <Assessment3 {...formProps} />
          <Assessment4 {...formProps} />
          <Assessment5 {...formProps} />
          <Recommendation {...formProps} />
        </Box>
      </Grid>
    </MuiDialog.SurveyForm>
  );
};

export default SBAR3;
