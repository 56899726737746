import Confirm from './Confirm';
import ConfirmButton from './ConfirmButton';
import SurveyForm from './SurveyForm';
import SurveyFormTwo from './SurveyFormTwo';
import SurveyFormColumn from './SurveyFormColumn';
import SurveyFormPrint from './SurveyFormPrint';

const MuiDialog = {
  Confirm,
  ConfirmButton,
  SurveyForm,
  SurveyFormTwo,
  SurveyFormColumn,
  SurveyFormPrint,
};

export default MuiDialog;
