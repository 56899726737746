import { Fragment } from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  Box,
} from '@mui/material';
import MuiRadioGroup from './components/MuiRadioGroup';

import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const KOOSContents4 = (props: Props) => {
  const { disabled, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="Function, Sports and Recreational Activities" />
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: '500',
            width: '500px',
            marginLeft: '15px',
          }}
          maxHeight={50}
          whiteSpace={'nowrap'}
        >
          The following questions concern your physical function when being
          active on a higher level.
          <br />
          The questions should be answered thinking of what degree of difficulty
          you have experienced during the last week due to your knee.
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: 'right' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Squatting
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv01')}
                  onChange={v => setValue('actv01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Running
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv02')}
                  onChange={v => setValue('actv02', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Jumping
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv03')}
                  onChange={v => setValue('actv03', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Twisting/pivoting on your injured knee
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv04')}
                  onChange={v => setValue('actv04', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  kneeling
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SPORTS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('actv05')}
                  onChange={v => setValue('actv05', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <SectionTitle title="Quality of Life" />
      <Grid item xs={12} sx={{ marginTop: '-10px' }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  How often are you aware of your knee problem?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol01')}
                  onChange={v => setValue('qol01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Have you modified your life style to avoid potentially
                  damaging
                  <br />
                  activities to your knee?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.TWO"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol02')}
                  onChange={v => setValue('qol02', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  How much are you troubled with lack of confidence in your
                  <br />
                  knee?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.TWO"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol03')}
                  onChange={v => setValue('qol03', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  In general, how much difficulty do you have with your knee?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.LIFEQUALITY.THREE"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('qol04')}
                  onChange={v => setValue('qol04', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      {/* <Grid item xs={12} sx={{ marginRight: '10px' }}>
        <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
          <Typography
            sx={{
              fontSize: '13px',
            }}
          >
            서승석, 정경칠, 김영복.(2006).슬관절 손상에 대한 국문 Knee Injury
            and Osteoarthritis Outcome Score [KOOS]의 적용을 통한 타당성,
            <br />
            신뢰성 및 반응성 평가.대한정형외과학회지,41(3),441-453.
          </Typography>
        </Box>
      </Grid> */}
    </Fragment>
  );
};

export default KOOSContents4;
