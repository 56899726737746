import { Fragment } from 'react';
import { Grid } from '@mui/material';

import MuiTable from './MuiTable';
import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import useTableForm from '../hooks/useTableForm';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const BodyStatus = (props: Props) => {
  const { radioGroup } = useTableForm(props);

  const columns = [
    { fieldId: 'title', label: 'Physical Condition' },
    {
      fieldId: '0',
      label: 'Complete help',
      sx: { textAlign: 'center' },
    },
    { fieldId: '1', label: 'Partial help', sx: { textAlign: 'center' } },
    {
      fieldId: '2',
      label: 'Completely independent',
      sx: { textAlign: 'center' },
    },
  ];

  const rows = [
    {
      id: 'takeOffClothes',
      title: 'Take off and wear clothes',
      ...radioGroup({
        key: 'body_status.takeOffClothes',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'eat',
      title: 'Eating',
      ...radioGroup({
        key: 'body_status.eat',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'sitUp',
      title: 'Sitting up',
      ...radioGroup({
        key: 'body_status.sitUp',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'toilet',
      title: 'Using a bathroom',
      ...radioGroup({
        key: 'body_status.toilet',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'washFace',
      title: 'Washing face',
      ...radioGroup({
        key: 'body_status.washFace',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'bath',
      title: 'Taking a bath',
      ...radioGroup({
        key: 'body_status.bath',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'transfer',
      title: 'Changing seats',
      ...radioGroup({
        key: 'body_status.transfer',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'controlStool',
      title: 'Controlling a stool',
      ...radioGroup({
        key: 'body_status.controlStool',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'brushTeeth',
      title: 'Brushing teeth',
      ...radioGroup({
        key: 'body_status.brushTeeth',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'changePosition',
      title: 'Changing position',
      ...radioGroup({
        key: 'body_status.changePosition',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'getOutRoom',
      title: 'Coming out of a room',
      ...radioGroup({
        key: 'body_status.getOutRoom',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
    {
      id: 'controlUrine',
      title: 'Controlling urine',
      ...radioGroup({
        key: 'body_status.controlUrine',
        options: [1, 2, 3],
        width: '25px',
      }),
    },
  ];

  return (
    <Fragment>
      <SectionTitle title="Physical condition (ability to perform daily life movements)" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
    </Fragment>
  );
};

export default BodyStatus;
