import Form from 'components/Form';

import { useEffect, useState, Fragment } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  useTheme,
  Typography,
} from '@mui/material';
import {
  CPRStyledTableCell,
  CPRStyledTableCellFirst,
  CPRStyledTableCellBodyNumbering,
} from 'routes/Main/style';
import SectionTitle from '../components/SectionTitle';

import { IFormValues, IFormWatch, IFormRegister } from 'routes/Main/type';

const contentLabel = [
  {
    id: 'V/S',
    ko: [
      'Date',
      'Time',
      'SBP (mm/Hg)',
      'DBP (mmHg)',
      'PR (times/min)',
      'RR (times/min)',
      'BT (℃)',
      'Spo2 (%)',
      'O2',
      'etc',
    ],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'height/\nweight',
    ko: ['height (cm)', 'weight (kg)'],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'Intake',
    ko: ['Oral', 'Intravenous injection', 'Blood and others', 'Total intake'],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'Output',
    ko: ['Urine', 'Vomiting', 'Stool', 'Drain and others', 'Total output'],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'Change of\nposition',
    ko: ['Whether to perform', 'Position', 'Skin condition'],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'Medical\nrestraint',
    ko: [
      'Application date and time',
      'Reasons for application',
      'Application site',
      'skin condition',
      'acrocyanosis',
      'Terminal temperature',
      'Terminal Sense',
      'Side effects occur',
      'prevention activities',
    ],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'IPC',
    ko: ['Application date and time'],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'Vent',
    ko: [
      'Start date and time',
      'Mode',
      'FiO2',
      'PEEP',
      'F',
      'Vsennse',
      'Pi',
      'Ti',
      'TV',
      'MV',
      'Cstat',
      'P/F ratio',
    ],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
  {
    id: 'CRRT',
    ko: [
      'Mode',
      'Blood flow',
      'Dialysate (mL/hr)',
      'Pre-Replacement (mL/hr)',
      'Post-Replacement (mL/hr)',
      'PBP (mL/hr)',
      'Pt fluid removal (mL/hr)',
      'Anticoagulation (mg/hr)',
      'CRRT Monitoring',
      'Access pressure',
      'Return pressure',
      'Filter pressure',
      'Effluent pressure',
      'TMP Pressure',
      'Pressure drop',
    ],
    desc: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  },
];

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
}

const VitalSignGraph = (props: Props) => {
  const { disabled, setValue, getValues, register } = props;
  const { palette } = useTheme();

  return (
    <>
      <SectionTitle title="Clinical Observation Record (ICU)" mt={3} />
      <Box
        sx={{
          width: '93%',
          marginTop: '60px',
          justifyContent: 'center',
          marginLeft: '3%',
        }}
      >
        <Table aria-label="simple table">
          <TableBody>
            {contentLabel.map(
              (content: { id: string; ko: string[]; desc: string[] }, i) => {
                return (
                  <TableRow>
                    <CPRStyledTableCellFirst
                      align="center"
                      sx={{
                        minWidth: '80px',
                        whiteSpace: 'pre',
                      }}
                    >
                      {content.id}
                    </CPRStyledTableCellFirst>
                    <CPRStyledTableCell>
                      {content.ko.map((_, i) => {
                        return (
                          <TableRow
                            sx={{
                              lineHeight: content.ko[i].includes('심장리듬')
                                ? '22px'
                                : '44px',
                              borderBottom:
                                i !== content.ko.length - 1
                                  ? '1px solid lightgray'
                                  : '',
                            }}
                          >
                            <Box
                              sx={{
                                width: '200px',
                                whiteSpace: 'pre',
                                padding: '0 0 0 5px',
                              }}
                            >
                              {content.ko[i]}
                            </Box>
                          </TableRow>
                        );
                      })}
                    </CPRStyledTableCell>

                    {content.desc.map((v, i) => {
                      return (
                        <CPRStyledTableCellBodyNumbering>
                          {content.ko.map((item, i) => {
                            if (content.id === 'V/S') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `vital_sign.vital0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'height/\nweight') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `weight_height.weight_height0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'Intake') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `intake.intake0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'Output') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `output.output0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'Change of\nposition') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `position_change.position_change0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'Medical\nrestraint') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(
                                        `restraint.restraint0${v}.no${v}${i}`
                                      )}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'IPC') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(`ipc.ipc0${v}`)}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'Vent') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(`vent.vent0${v}.no${v}${i}`)}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            } else if (content.id === 'CRRT') {
                              return (
                                <TableRow
                                  sx={{
                                    lineHeight: '43px',
                                    borderBottom:
                                      i !== content.ko.length - 1
                                        ? '1px solid lightgray'
                                        : '',
                                  }}
                                >
                                  <Box
                                    sx={{
                                      minWidth: '100px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    <input
                                      disabled={disabled}
                                      style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '44px',
                                      }}
                                      {...register(`crrt.crrt0${v}.no${v}${i}`)}
                                    />
                                  </Box>
                                </TableRow>
                              );
                            }
                          })}
                        </CPRStyledTableCellBodyNumbering>
                      );
                    })}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default VitalSignGraph;
