import { Fragment } from 'react';
import { Typography } from '@mui/material';

import RowContainer from '../../components/RowContainer';
import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const PatientInfo = (props: Props) => {
  const { disabled, register } = props;

  return (
    <Fragment>
      <SectionTitle title="1. Rationale for the Use of Physical Restraints" />
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        <Typography
          sx={{ lineHeight: '20px', margin: '20px 0 0 15px', fontSize: '14px' }}
        >
          Hospitalized patients often exhibit impaired cognitive function and
          motor abilities due to the nature of their illnesses. Consequently,
          they may attempt to remove or damage
          <br />
          therapeutic devices. Various efforts are made to address this issue,
          but there are situations where the minimal use of physical restraints
          becomes necessary.
        </Typography>
      </RowContainer>
    </Fragment>
  );
};

export default PatientInfo;
