import Form from 'components/Form';

import { Fragment } from 'react';
import { Grid, Typography } from '@mui/material';

import RowContainer from '../components/RowContainer';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';
import RowContent from '../components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'Walk',
    key: 'recommendation1.checked1',
  },
  {
    label: 'W/C',
    key: 'recommendation1.checked2',
  },
  {
    label: 'Cart',
    key: 'recommendation1.checked3',
  },
  {
    label: 'Bed',
    key: 'recommendation1.checked4',
  },
];

const checks2 = [
  {
    label: 'None',
    key: 'recommendation2_monitor_o2.checked1',
  },
  {
    label: 'Exist',
    key: 'recommendation2_monitor_o2.checked2',
  },
];

const checks3 = [
  {
    label: 'None',
    key: 'recommendation2_monitor_patients.checked1',
  },
  {
    label: 'Exist',
    key: 'recommendation2_monitor_patients.checked2',
  },
];

const Recommendation = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="R (Recommendation)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContent
          title="Scheduled Tests"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Form.MuiTextField
            required={false}
            fullWidth
            disabled={disabled}
            placeholder="Enter"
            {...register('recommendation1_input1')}
          />
        </RowContent>
        <RowContent
          title="Method of Transfer"
          titleRatio={1.2}
          childrenRatio={10.8}
        >
          <Stack direction="row" spacing={1}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={1.5}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Monitoring" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.5}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Oxygen
              </Typography>
            </Grid>
            {checks2.map((v, i) => {
              return (
                <Grid item xs={i === 0 ? 1.5 : 0.7}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
            <Form.MuiTextField
              required={false}
              sx={{ width: '190px' }}
              disabled={disabled}
              placeholder="Enter"
              InputProps={{ ...Form.adornment('', 'L/min') }}
              {...register(`recommendation2_monitor_o2_input`)}
            />
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.5}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                Patient Monitor
              </Typography>
            </Grid>
            {checks3.map((v, i) => {
              return (
                <Grid item xs={i === 0 ? 1.5 : 0.5}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            <Grid item xs={1.5}>
              <Typography
                variant="caption"
                fontWeight={400}
                lineHeight="38px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                etc.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row">
                <Form.MuiTextField
                  required={false}
                  fullWidth
                  disabled={disabled}
                  placeholder="Enter"
                  {...register(`recommendation2_monitor_etc_input`)}
                />
              </Stack>
            </Grid>
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Form.MuiTextField
            required={false}
            fullWidth
            multiline
            minRows={3}
            disabled={disabled}
            placeholder="Please specify additional details."
            {...register(`recommendation2_etc_input`)}
          />
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Recommendation;
