import { Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const CVCthree = (props: Props) => {
  const { disabled, register } = props;

  const info = [
    'To alleviate pain during the procedure, local anesthesia is administered at the insertion site.',
    'After puncturing the blood vessel with a syringe, an introducer is inserted into the needle to secure a passage for the guidewire to facilitate the insertion of the catheter.',
    'The catheter is inserted into the blood vessel through the introducer, and it is secured to the skin.',
    'The expected duration is approximately 20 to 40 minutes, but it may vary depending on the progress of the procedure.',
  ];

  return (
    <Fragment>
      <SectionTitle title="3. Central venous catheter insertion method." />
      <Box sx={{ margin: '38px 0px 30px 20px' }}>
        <Grid container xs={11.8}>
          {info.map(a => {
            return (
              <Grid item xs={11.5}>
                <Typography sx={{ fontSize: '14px', lineHeight: '30px' }}>
                  • {a}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CVCthree;
