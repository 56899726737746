import { Fragment, useState } from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AccountCircleOutlined,
  LogoutOutlined,
  NotificationsOutlined,
  VideocamOutlined,
  ComputerOutlined,
  ExpandMore,
} from '@mui/icons-material';
import { AiOutlineCalculator } from 'react-icons/ai';
import { ReactComponent as Infomatics } from '../../../assets/infomatics-icon.svg';
import { ReactComponent as MadicationLoog } from '../../../assets/madication-logo.svg';

import useUser from 'store/user/useUser';
import { useNavigate } from 'react-router-dom';
import useStudent from 'store/student/useStudent';
import useSurvey from 'store/survey/useSurvey';
import usePatient from 'store/patient/usePatient';
import useNotification from 'hooks/useNotification';
import { IToggleObj } from './type';
import { initialToggleObj } from './initialStates';
import Survey from '../Survey';

interface Props {
  menuDrawerWidth: number;
  setMenuDrawerWidth: (menuDrawerWidth: number) => void;
}

const MenuSettings = (props: Props) => {
  const { menuDrawerWidth, setMenuDrawerWidth } = props;

  const navigate = useNavigate();
  const { onSignOut } = useUser();
  const { onCloseReadOnly, onUpdateSurveyType } = useSurvey();
  const { student_uuid, onResetStudent } = useStudent();
  const { patientInfo, onSelectedPatient, onResetPatient } = usePatient();
  const { onRequired } = useNotification();

  const [toggle, setToggle] = useState<IToggleObj>(initialToggleObj);

  const settings = [
    {
      icon: <NotificationsOutlined />,
      label: 'Notice',
    },
    {
      icon: <AccountCircleOutlined />,
      label: 'Account Settings',
    },
    {
      icon: <LogoutOutlined />,
      label: 'Log out',
    },
  ];

  const onClickListItem = (label: string) => {
    if (label === 'Log out') {
      onSignOut();
      // 로그아웃 시 스토어 초기화
      onResetStudent();
      onResetPatient();
      onCloseReadOnly();
    } else if (label === 'Account Settings') {
      navigate('/mypage');
      return;
    } else if (label === 'Screen Settings') {
      navigate('/screensetting');
      return;
    } else if (label === 'Notice') {
      window.open('https://smartenr.com/notice');
      return;
    } else if (!patientInfo) return onRequired('REQUIRED.PATIENT');
    onUpdateSurveyType(label);
  };

  const onClickDisabledItem = (sublabel: string | undefined) => {
    let newToggle = { ...toggle };

    if (menuDrawerWidth !== 220) {
      if (sublabel) newToggle[sublabel] = true;
      setToggle(newToggle);
      setMenuDrawerWidth(220);
      return;
    }

    if (sublabel) newToggle[sublabel] = !newToggle[sublabel];
    setToggle(newToggle);
  };

  return (
    <Fragment>
      {settings.map(({ icon, label }) => {
        const onClick = () => {
          onClickListItem(label);
        };

        const MoreIcon = () => {
          return <ExpandMore fontSize="small" sx={{ color: '#fff' }} />;
        };

        return (
          <>
            {menuDrawerWidth !== 220 ? (
              icon ? (
                <ListItem key={label} disablePadding>
                  <ListItemButton onClick={onClick}>
                    <ListItemIcon>{icon}</ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ) : null
            ) : icon ? (
              <ListItem key={label} disablePadding>
                <ListItemButton onClick={onClick}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItemButton>
              </ListItem>
            ) : toggle ? (
              <ListItem key={label} disablePadding>
                <ListItemButton onClick={onClick}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{ whiteSpace: 'pre-wrap' }}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <></>
            )}
          </>
        );
      })}
      <Survey />
    </Fragment>
  );
};

export default MenuSettings;
