import { Fragment } from 'react';
import { Stack } from '@mui/material';

import Form from 'components/Form';
import { IFormRegister, IFormValues } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import RowContent from '../components/RowContent';
import SectionTitle from '../components/SectionTitle';
import MuiRadioGroup from './RadioGroupSub';

interface Props extends IFormRegister, IFormValues {
  disabled?: boolean;
}

const DefaultInfo = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="Basic Information" />

      <RowContainer xs={12}>
        <RowContent
          title="Discharge Destination"
          titleRatio={2}
          childrenRatio={9}
        >
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="OUT.HOSPITAL.DESTINATION"
              i18nNullKey="ETC"
              values={[1, 2, 3, 0]}
              disabled={disabled}
              defaultValue={getValues('default_info.destination.value')}
              onChange={v => setValue('default_info.destination.value', v)}
            />
            <Form.MuiTextField
              sx={{ width: '150px' }}
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('default_info.destination.input')}
            />
          </Stack>
        </RowContent>
        <RowContent title="Discharge Route" titleRatio={2} childrenRatio={9}>
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="OUT.HOSPITAL.WAY"
              i18nNullKey="ETC"
              disabled={disabled}
              values={[1, 2, 3, 4, 0]}
              defaultValue={getValues('default_info.out_hospital_way.value')}
              onChange={v => setValue('default_info.out_hospital_way.value', v)}
            />
            <Form.MuiTextField
              sx={{ width: '150px' }}
              required={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('default_info.out_hospital_way.input')}
            />
          </Stack>
        </RowContent>
        <RowContent
          title="Principal Guardian"
          titleRatio={2}
          childrenRatio={10}
        >
          <MuiRadioGroup
            i18nKey="HOSPITALIZATION.GUARDIAN"
            i18nNullKey="ETC"
            disabled={disabled}
            values={[1, 2, 3, 4, 5, 0]}
            defaultValue={getValues('default_info.guardians.value')}
            onChange={v => setValue('default_info.guardians.value', v)}
          />
          <Form.MuiTextField
            sx={{ width: '150px' }}
            required={false}
            disabled={disabled}
            placeholder="Enter"
            {...register('default_info.guardians.input')}
          />
        </RowContent>
        <RowContent
          title="Post-Discharge Diet"
          titleRatio={2}
          childrenRatio={9}
        >
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="OUT.HOSPITAL.FOOD"
              i18nNullKey="ETC"
              values={[1, 0]}
              disabled={disabled}
              defaultValue={getValues('default_info.food.value')}
              onChange={v => setValue('default_info.food.value', v)}
            />
            <Form.MuiTextField
              sx={{ width: '150px' }}
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('default_info.food.input')}
            />
          </Stack>
        </RowContent>
        <RowContent
          title="Post-Discharge Bath"
          titleRatio={2}
          childrenRatio={9}
        >
          <Stack direction="row" spacing={1}>
            <MuiRadioGroup
              i18nKey="OUT.HOSPITAL.SHOWER"
              i18nNullKey="ETC"
              values={[1, 2, 0]}
              disabled={disabled}
              defaultValue={getValues('default_info.shower.value')}
              onChange={v => setValue('default_info.shower.value', v)}
            />
            <Form.MuiTextField
              sx={{ width: '150px' }}
              required={false}
              fullWidth={false}
              disabled={disabled}
              placeholder="Enter"
              {...register('default_info.shower.input')}
            />
          </Stack>
        </RowContent>
        <RowContent
          title="Post-Discharge Activities"
          titleRatio={2}
          childrenRatio={9}
        >
          <MuiRadioGroup
            i18nKey="OUT.HOSPITAL.ACTIVITY"
            values={[1, 2]}
            disabled={disabled}
            defaultValue={getValues('default_info.activity')}
            onChange={v => setValue('default_info.activity', v)}
          />
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default DefaultInfo;
