import { Fragment } from 'react';
import {
  Grid,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Table,
  TableHead,
} from '@mui/material';
import MuiRadioGroup from './components/MuiRadioGroup';

import { IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormValues, IFormWatch {
  disabled?: boolean;
}

const KOOSContents = (props: Props) => {
  const { disabled, getValues, setValue } = props;

  return (
    <Fragment>
      <Typography sx={{ fontSize: '14px', margin: '40px 0 -10px 25px' }}>
        Instructions: This survey asks for your view about your knee. This
        information will help us keep track of how you feel about your knee and
        how well you are able to perform your usual
        <br />
        activities. Answer every question by ticking the appropriate box, only
        one box for each question. If you are unsure about how to answer a
        question, please give the best answer you can.
      </Typography>
      <SectionTitle title="Symptoms" />
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: '500',
            width: '500px',
            marginLeft: '15px',
          }}
          maxHeight={20}
          whiteSpace={'nowrap'}
        >
          These questions should be answered thinking of your knee symptoms
          during the last week.
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: 'right' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Do you have swelling in your knee?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SYMPTOMS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('spt01')}
                  onChange={v => setValue('spt01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Do you feel grinding, hear clicking or any other type of noise
                  <br />
                  when your knee moves?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SYMPTOMS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('spt02')}
                  onChange={v => setValue('spt02', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Does your knee catch or hang up when moving?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SYMPTOMS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('spt03')}
                  onChange={v => setValue('spt03', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Can you straighten your knee fully?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SYMPTOMS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('spt04')}
                  onChange={v => setValue('spt04', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={20}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  Can you bend your knee fully?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.SYMPTOMS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('spt05')}
                  onChange={v => setValue('spt05', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <SectionTitle title="Stiffness" />
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: '500',
            width: '500px',
            marginLeft: '15px',
          }}
          maxHeight={35}
          whiteSpace={'nowrap'}
        >
          The following questions concern the amount of joint stiffness you have
          experienced during the last week in your knee.
          <br />
          Stiffness is a sensation of restriction or slowness in the ease with
          which you move your knee joint.
        </Typography>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: 'right' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  How severe is your knee joint stiffness after first wakening
                  in
                  <br />
                  the morning?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.STIFFNESS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('stf01')}
                  onChange={v => setValue('stf01', v)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: '400',
                    width: '500px',
                  }}
                  maxHeight={50}
                  display="block"
                  variant="caption"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace={'pre-line'}
                >
                  How severe is your knee sitffness after sitting, lying or
                  resting
                  <br />
                  later in the day?
                </Typography>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                <MuiRadioGroup
                  disabled={disabled}
                  i18nNullKey="ETC"
                  i18nKey="KOOS.STIFFNESS"
                  values={[1, 2, 3, 4, 5]}
                  defaultValue={getValues('stf02')}
                  onChange={v => setValue('stf02', v)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Fragment>
  );
};

export default KOOSContents;
