import { useState } from 'react';

import Form from 'components/Form';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import { AccessTime } from '@mui/icons-material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';

import MuiTextField from 'components/Form/MuiTextField';

import RowContainer from '../../components/RowContainer';
import RowContent from './components/RowContentSub';
import SectionTitle from '../../components/SectionTitle';

interface Props extends IFormRegister, IFormValues, IFormWatch {
  disabled?: boolean;
  blood: string;
}

interface IBloodInfos {
  [index: string]: string | null;
  id: string;
  title: string;
  cc: string;
  arrivedTime: string | null;
  arrivedChecker: string;
  checker1: string;
  checker2: string;
  startTime: string | null;
  startStaff: string;
  endTime: string | null;
  endStaff: string;
}

const BloodInfo = (props: Props) => {
  const { blood, disabled, register, watch, setValue } = props;

  const [bloodInfos, setBloodInfos] = useState<IBloodInfos>({
    id: '',
    title: '',
    cc: '',
    arrivedTime: '',
    arrivedChecker: '',
    checker1: '',
    checker2: '',
    startTime: '',
    startStaff: '',
    endTime: '',
    endStaff: '',
  });

  const rows = [
    { title: 'Blood Number', variable: 'blood_number', type: 'text' },
    { title: 'Blood Name', variable: 'blood_name', type: 'text' },
    { title: 'Blood type', variable: 'type', type: 'label' },
    { title: 'Volume(ml)', variable: 'volume', type: 'text' },
    { title: 'Arrival Time', variable: 'arrival_time', type: 'time' },
    {
      title: 'Transfusion Arrival',
      variable: 'blood_transfusion_arrival',
      type: 'text',
    },
    {
      title: 'Transfusion Check1',
      variable: 'transfusion_check1',
      type: 'text',
    },
    {
      title: 'Transfusion Check2',
      variable: 'transfusion_check2',
      type: 'text',
    },
    {
      title: 'Transfusion Start time',
      variable: 'transfusion_start_time',
      type: 'time',
    },
    {
      title: 'Practitioner\n(at start)',
      variable: 'practitioner_start',
      type: 'text',
    },
    {
      title: 'Transfusion End time',
      variable: 'transfusion_end_time',
      type: 'time',
    },
    {
      title: 'Practitioner\n(at end)',
      variable: 'practitioner_end',
      type: 'text',
    },
  ];

  return (
    <>
      <RowContainer xs={12} sx={{ marginTop: '-32px' }}>
        {rows.map(({ title, variable, type }) => (
          <RowContent
            key={variable}
            title={title}
            titleRatio={1}
            childrenRatio={2}
          >
            {type === 'text' && (
              <MuiTextField
                value={watch(`${variable}`)}
                onChange={e => setValue(variable, e.target.value)}
                required={false}
                disabled={disabled}
              />
            )}
            {type === 'label' && (
              <Form.MuiTextField
                disabled={disabled}
                value={blood}
                InputProps={{ readOnly: true }}
              />
            )}
            {type === 'time' && (
              <MobileTimePicker
                disabled={disabled}
                value={watch(`${variable}`)}
                onChange={value => setValue(variable, value)}
                renderInput={params => (
                  <MuiTextField
                    {...params}
                    required={false}
                    placeholder="00:00 pm"
                    InputProps={{ endAdornment: <AccessTime /> }}
                    error={false}
                  />
                )}
              />
            )}
          </RowContent>
        ))}
      </RowContainer>
    </>
  );
};

export default BloodInfo;
