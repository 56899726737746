import { IFormRegister } from 'routes/Main/type';

import Form from 'components/Form';
import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';
import SectionTitle from '../../components/SectionTitle';

import { Box, Table, TableBody, TableRow } from '@mui/material';

import {
  StyledTableCell,
  StyledTableCellWithoutLeft,
  StyledTableCellWithoutLeftRight,
} from 'routes/Main/style';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;

  return (
    <>
      <Box sx={{ width: '1200px', margin: '10px auto' }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  padding: '50px',
                  width: '200px',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Patient
              </StyledTableCell>
              <StyledTableCellWithoutLeftRight>
                <div style={{ display: 'flex', margin: '20px 0 20px 0' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Date of birth
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('pt_bday')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Phone number
                  </Box>
                  <Form.MuiTextField
                    sx={{ paddingRight: '10px' }}
                    required={false}
                    disabled={disabled}
                    {...register('pt_contact')}
                  />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('pt_name')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Signature
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    sx={{ paddingRight: '10px' }}
                    disabled={disabled}
                    {...register('pt_sign')}
                  />
                </div>
              </StyledTableCellWithoutLeftRight>
              <StyledTableCellWithoutLeft></StyledTableCellWithoutLeft>
            </TableRow>

            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  padding: '50px',
                  width: '200px',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Guardian
              </StyledTableCell>
              <StyledTableCellWithoutLeftRight>
                <div style={{ display: 'flex', margin: '20px 0 20px 0' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      whiteSpace: 'nowrap',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Relationship with a patient
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('nok_bday')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Phone number
                  </Box>
                  <Form.MuiTextField
                    sx={{ paddingRight: '10px' }}
                    required={false}
                    disabled={disabled}
                    {...register('nok_contact')}
                  />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('nok_name')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Signature
                  </Box>
                  <Form.MuiTextField
                    sx={{ paddingRight: '10px' }}
                    required={false}
                    disabled={disabled}
                    {...register('nok_sign')}
                  />
                </div>
              </StyledTableCellWithoutLeftRight>
              <StyledTableCellWithoutLeft></StyledTableCellWithoutLeft>
            </TableRow>

            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  padding: '50px',
                  width: '200px',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                Doctor
              </StyledTableCell>
              <StyledTableCellWithoutLeftRight>
                <div style={{ display: 'flex', margin: '20px 0 20px 0' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('dr_name')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '10px',
                      minWidth: '200px',
                      fontSize: '12px',
                      fontWeight: 500,
                    }}
                  >
                    Signature
                  </Box>
                  <Form.MuiTextField
                    sx={{ paddingRight: '10px' }}
                    required={false}
                    disabled={disabled}
                    {...register('dr_sign')}
                  />
                </div>
              </StyledTableCellWithoutLeftRight>
              <StyledTableCellWithoutLeft></StyledTableCellWithoutLeft>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Signature;
