import { Fragment } from 'react';
import { Grid } from '@mui/material';

import MuiTable from './MuiTable';
import { IFormRegister, IFormWatch, IFormValues } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import useTableForm from '../hooks/useTableForm';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
}

const DiseaseStatus = (props: Props) => {
  const { checkbox } = useTableForm(props);

  const columns = [
    { fieldId: 'title', label: '', sx: { width: '230px' } },
    { fieldId: '0', label: '' },
    { fieldId: '1', label: '' },
    { fieldId: '2', label: '' },
    { fieldId: '3', label: '' },
    { fieldId: '4', label: '' },
  ];

  const rows = [
    {
      id: 'circulatory',
      title: 'Circulatory system',
      0: checkbox({
        label: 'Cerebral infarction (CI)',
        key: 'disease_status.circulatory.checked1',
      }),
      1: checkbox({
        label: 'Cerebral hemorrhage',
        key: 'disease_status.circulatory.checked2',
      }),
      2: checkbox({
        label: 'Angina pectoris',
        key: 'disease_status.circulatory.checked3',
      }),
      3: checkbox({
        label: 'Myocardial infarction(MI)',
        key: 'disease_status.circulatory.checked4',
      }),
      4: checkbox({
        label: 'etc',
        inputKey: 'disease_status.circulatory.etc',
        key: 'disease_status.circulatory.checked5',
      }),
    },
    {
      id: 'chronic',
      title: 'Chronic disease',
      0: checkbox({
        label: 'DM',
        key: 'disease_status.chronic.checked1',
      }),
      1: checkbox({
        label: 'HTN',
        key: 'disease_status.chronic.checked2',
      }),
      2: checkbox({
        label: 'Chronic pulmonary disease',
        key: 'disease_status.chronic.checked3',
      }),
      3: checkbox({
        label: 'Cancer',
        inputKey: 'disease_status.chronic.cancer',
        key: 'disease_status.chronic.checked4',
      }),
    },

    {
      id: 'nervous',
      title: 'Nervous system',
      0: checkbox({
        label: 'Dementia',
        key: 'disease_status.nervous.checked1',
      }),
      1: checkbox({
        label: 'Alzheimer’s disease',
        key: 'disease_status.nervous.checked2',
      }),
      2: checkbox({
        label: 'Epilepsy',
        key: 'disease_status.nervous.checked3',
      }),
      3: checkbox({
        label: 'etc',
        inputKey: 'disease_status.nervous.etc',
        key: 'disease_status.nervous.checked5',
      }),
    },
    {
      id: 'musculoskeletal',
      title: 'Musculoskeletal \nsystem',
      0: checkbox({
        label: 'Arthritis',
        key: 'disease_status.musculoskeletal.checked1',
      }),
      1: checkbox({
        label: 'Lumbago, Ischialgia',
        key: 'disease_status.musculoskeletal.checked2',
      }),
      2: checkbox({
        label: 'Fracture, sequela',
        key: 'disease_status.musculoskeletal.checked3',
      }),
      3: checkbox({
        label: 'etc',
        inputKey: 'disease_status.musculoskeletal.etc',
        key: 'disease_status.musculoskeletal.checked5',
      }),
    },
    {
      id: 'mentalAndBehavioral',
      title: 'Mental and \nBehavioral disorders',
      0: checkbox({
        label: 'Depression',
        key: 'disease_status.mentalAndBehavioral.checked1',
      }),
      1: checkbox({
        label: 'Sleep Disturbance',
        key: 'disease_status.mentalAndBehavioral.checked2',
      }),
      2: checkbox({
        label: 'Mental Illness',
        key: 'disease_status.mentalAndBehavioral.checked3',
      }),
      3: checkbox({
        label: 'etc',
        inputKey: 'disease_status.mentalAndBehavioral.etc',
        key: 'disease_status.mentalAndBehavioral.checked5',
      }),
    },
    {
      id: 'respiratory',
      title: 'Respiratory system',
      0: checkbox({
        label: 'Dyspnea',
        key: 'disease_status.respiratory.checked1',
      }),
      1: checkbox({
        label: 'Tuberculosis',
        key: 'disease_status.respiratory.checked2',
      }),
      2: checkbox({
        label: 'etc',
        inputKey: 'disease_status.respiratory.etc',
        key: 'disease_status.respiratory.checked5',
      }),
    },
    {
      id: 'renal',
      title: 'Chronic kidney \nfailure ',
      0: checkbox({
        key: 'disease_status.renal.checked1',
        label: 'Chronic Renal Failure',
      }),
      1: checkbox({
        label: 'etc',
        inputKey: 'disease_status.renal.etc',
        key: 'disease_status.renal.checked5',
      }),
    },
    {
      id: 'other',
      title: 'Other diseases',
      0: checkbox({
        label: 'Allergy',
        key: 'disease_status.other.checked1',
        inputKey: 'disease_status.other.allergy',
      }),
      1: checkbox({
        label: 'etc',
        inputKey: 'disease_status.other.etc',
        key: 'disease_status.other.checked5',
      }),
    },
  ];

  return (
    <Fragment>
      <SectionTitle title="Disease Condition" />
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={rows} />
      </Grid>
    </Fragment>
  );
};

export default DiseaseStatus;
