import { Box, Typography } from '@mui/material';

import SectionTitle from '../../components/SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Someone over 70 or under 14',
  'Someone who is taking medications such as diuretics, sedatives, and anticonvulsants',
  'Someone with symptoms such as gait disorder, stupor, and dizziness ',
  'Someone with weakness, vision and hearing disorders, urination and excretion disorders, osteoporosis',
  'Others who are under the risk of falling',
];

const CautionList = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="Please be especially careful of falling if you are under the categories below" />
      <Box sx={{ width: '98%', margin: '48px auto 24px auto' }}>
        {contents.map((v, i) => (
          <Typography
            sx={{ fontWeight: '400', fontSize: '14px', lineHeight: '40px' }}
          >
            {i + 1}) {v}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default CautionList;
