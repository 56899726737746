import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { AccessTime, Delete } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

import { Ti18nId } from 'hooks/useI18n';
import { IECardexDosage } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';
import MuiTable from 'components/MuiTable';
import MuiTextField from 'components/Form/MuiTextField';

import { formatStringToDate } from 'utils/formatting';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const Dosage = (props: Props) => {
  const { disabled, watch, setValue, onRequired, onSuccess, register } = props;
  const dosageList: IECardexDosage[] = watch('medication_data');

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);
  const [dosage, setDosage] = useState('');
  const [method, setMethod] = useState('');
  const [time, setTime] = useState(null);
  const [termination, setTermination] = useState('');

  const columns = [
    { fieldId: 'date', label: 'Date', sx: { width: 200 } },
    { fieldId: 'medication', label: 'Medication' },
    { fieldId: 'method', label: 'Method', sx: { width: 200 } },
    { fieldId: 'time', label: 'Time', sx: { width: 200 } },
    { fieldId: 'end', label: 'End', sx: { width: 200 } },
    { fieldId: 'action', label: '', sx: { width: 100 } },
  ];

  const onAddRow = () => {
    const request = {
      date,
      medication: dosage,
      method,
      time,
      end: termination,
    };

    onSuccess('Added dosing');
    setValue(
      'medication_data',
      dosageList ? [...dosageList, request] : [request]
    );
    setValue('etc.dosage.date', '');
    setDate(materialDateInput);
    setDosage('');
    setMethod('');
    setTime(null);
    setTermination('');
  };

  const inputRow = {
    id: 'add-dosage',
    date: (
      <Form.MuiTextField
        type="date"
        required={false}
        disabled={disabled}
        label="Please enter a date"
        defaultValue={materialDateInput}
        InputLabelProps={{ shrink: true }}
        {...register('etc.dosage.date', {
          onChange: e => setDate(e.target.value),
        })}
      />
    ),
    medication: (
      <MuiTextField
        value={dosage}
        required={false}
        onChange={({ target: { value } }) => setDosage(value)}
      />
    ),
    method: (
      <MuiTextField
        value={method}
        required={false}
        onChange={({ target: { value } }) => setMethod(value)}
      />
    ),
    time: (
      <MobileTimePicker
        value={time}
        onChange={setTime}
        renderInput={params => (
          <MuiTextField
            {...params}
            required={false}
            placeholder="Choose Time"
            InputProps={{ endAdornment: <AccessTime /> }}
          />
        )}
      />
    ),
    end: (
      <MuiTextField
        value={termination}
        required={false}
        onChange={({ target: { value } }) => setTermination(value)}
      />
    ),
    action: (
      <Button variant="contained" size="small" onClick={onAddRow}>
        ADD
      </Button>
    ),
  };

  const onDeleteRow = (index: number) => {
    setValue(
      'medication_data',
      dosageList.filter((_, i) => i !== index)
    );
  };

  const displayRows = dosageList
    ? dosageList.map((item, i) => ({
        ...item,
        id: i,
        time: formatStringToDate(item.time, 'hh:mm a'),
        action: (
          <IconButton
            size="small"
            onClick={() => onDeleteRow(i)}
            sx={{ display: disabled ? 'none' : 'block' }}
          >
            <Delete />
          </IconButton>
        ),
      }))
    : [];
  const tableRow = disabled ? displayRows : [inputRow, ...displayRows];

  return (
    <Fragment>
      <Grid item xs={12}>
        <MuiTable columns={columns} rows={[...tableRow]} />
      </Grid>
    </Fragment>
  );
};

export default Dosage;
