import Form from 'components/Form';

import { Fragment } from 'react';
import { Grid } from '@mui/material';

import RowContainer from '../components/RowContainer';

import { Ti18nId } from 'hooks/useI18n';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import SectionTitle from '../components/SectionTitle';
import { Stack } from '@mui/system';
import RowContent from '../components/RowContent';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const checks = [
  {
    label: 'Hepatitis',
    key: 'background_infection.checked1',
  },
  {
    label: 'HIV',
    key: 'background_infection.checked2',
  },
  {
    label: 'Tuberculosis',
    key: 'background_infection.checked3',
  },
  {
    label: 'Rota-V',
    key: 'background_infection.checked4',
  },
  {
    label: 'MRSA/VRE',
    key: 'background_infection.checked5',
  },
];

const checks2 = [
  {
    label: 'Medication',
    key: 'background_allergy.checked1',
  },
  {
    label: 'Food',
    key: 'background_allergy.checked2',
  },
];

const Background = (props: Props) => {
  const { disabled, register, getValues, setValue } = props;

  return (
    <Fragment>
      <SectionTitle title="B (Background)" />
      <RowContainer
        xs={12}
        sx={{ margin: '20px 0px 30px 0px', marginLeft: '-25px' }}
      >
        <RowContent title="Infection" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="row" spacing={1}>
            {checks.map((v, i) => {
              return (
                <Grid item xs={1.5}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="Allergies" titleRatio={1.2} childrenRatio={10.8}>
          <Stack direction="column" spacing={1}>
            {checks2.map((v, i) => {
              return (
                <Grid item xs={12}>
                  <Stack direction="row">
                    <Form.MuiCheckbox
                      label={v.label}
                      disabled={disabled}
                      defaultValue={Boolean(getValues(v.key)) ? [v.label] : []}
                      onChange={(_, checked) => {
                        setValue(v.key, checked);
                      }}
                    />
                    <Form.MuiTextField
                      required={false}
                      sx={{ marginLeft: i === 1 ? '60px' : '20px' }}
                      fullWidth
                      disabled={disabled}
                      placeholder="Enter"
                      {...register(`background_allergy_input${i + 1}`)}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Stack>
        </RowContent>
        <RowContent title="" titleRatio={0} childrenRatio={12}>
          <Form.MuiTextField
            required={false}
            fullWidth
            multiline
            minRows={3}
            disabled={disabled}
            placeholder="Please specify additional details."
            {...register('background_input')}
          />
        </RowContent>
      </RowContainer>
    </Fragment>
  );
};

export default Background;
