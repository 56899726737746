import { IFormRegister } from 'routes/Main/type';

import Form from 'components/Form';

import { Box, Table, TableBody, TableRow } from '@mui/material';

import {
  StyledTableCellWithoutLeft,
  StyledTableCellWithoutRight,
} from 'routes/Main/style';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const Signature = (props: Props) => {
  const { disabled, register } = props;

  return (
    <>
      <Box sx={{ width: '1100px', margin: '60px auto' }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <StyledTableCellWithoutRight>
                <div style={{ display: 'flex', margin: '20px 0 20px 0' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '60px',
                      minWidth: '150px',
                      fontSize: '12px',
                    }}
                  >
                    Sending Department
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('sending')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '60px',
                      fontSize: '12px',
                      minWidth: '150px',
                    }}
                  >
                    Receiving Department
                  </Box>
                  <Form.MuiTextField
                    sx={{ paddingRight: '60px' }}
                    required={false}
                    disabled={disabled}
                    {...register('subject')}
                  />
                </div>
                <div style={{ display: 'flex', marginBottom: '20px' }}>
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '60px',
                      minWidth: '150px',
                      fontSize: '12px',
                    }}
                  >
                    Sender
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    disabled={disabled}
                    {...register('takeover')}
                  />
                  <Box
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '60px',
                      minWidth: '150px',
                      fontSize: '12px',
                    }}
                  >
                    Receiver
                  </Box>
                  <Form.MuiTextField
                    required={false}
                    sx={{ paddingRight: '60px' }}
                    disabled={disabled}
                    {...register('consignee')}
                  />
                </div>
              </StyledTableCellWithoutRight>
              <StyledTableCellWithoutLeft></StyledTableCellWithoutLeft>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default Signature;
