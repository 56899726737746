import { Box, Typography } from '@mui/material';

import SectionTitle from './SectionTitle';

interface Props {
  disabled?: boolean;
}

const contents = [
  'Dignity and Respect',
  'You will be given appropriate and professional healthcare regardless of your age, gender, race, religion, nationality, social status, or if you have any special needs. You will be treated with \ncompassion, dignity and respect. Your legal guardian or any legally authorized person will exercise your rights according to the extent of the law should you be unable to do so.',
  'You have the right to meet and talk with family, trends and other visitors during visiting hours unless such rights are restricted as part of your overall medical treatment.\nYou also have the right to refuse visitors.',
  'Your special needs will be respected, whether these mean requiring language translators, special religious food requirements. device or access assistance during the course of your care.',
  'During stay in our hospital, you may arrange for your own spiritual care, and request for any counselling or pastoral support to be made available.',
  'Our Hospitals will respect and comply with your decisions made in accordance to your Advance Medical Directive. You have the right to refuse to donate tissue or participate in any sort \nof medical research whilst admitted to our Hospitals.',
  '*The advance medical Directive (AMD) is a legal document you sign in advance to inform your doctor that you do not want the use of any life-sustaining treatment to be used to prolong \nyour life in the event you become terminally and unconscious and where death is imminent.',
];

const contents2 = [
  'Confidentiality and Privacy',
  'Your medical information and all aspects of care rendered to you are kept private and confidential. Your medical records are accessed only by those involved in treating you and \narranging for your care. These records will not be released to anyone without your consent, except when required by the law or a third-party payer contract.',
  "You may request for a copy of your medical report in accordance to the hospital's policy. \nYour medical records are kept in a safe and protected environment.",
];

const contents3 = [
  'Receive Information and Explanation about Your Treatment',
  'You are entitled to information about your medical condition, treatment and possible results in a language that you understand (where possible). This includes information about \npossible risks, side effects and alternative methods of treatment.',
  'You have the right to know the names of the healthcare professionals responsible for your treatment and care.',
  'The information provided will help you to participate in decisions involving your medical condition and potential treatment.',
  'At any time, you will have access to people and information about health education, self-care and prevention of illness.',
  "You have the right to refuse medical care or recommended treatment to the extent permitted by law. However, if you choose not to undergo treatment, or leave the hospital against the \ndoctor's advice, you will have to accept responsibility for any medical consequences resulting from your decision. You will be briefed on the possible medical consequences.",
];

const contents4 = [
  'Financial Counselling and Estimated Bill',
  'You are entitled to financial counselling and an estimate of your hospital bill at the time of your admission. Be mindful to ask for information related to any change in your treatment \nplan or medical needs which may alter this initial estimate.',
  'You have the right to ask for this information and seek the necessary clarification whilst admitted to our Hospital.',
];

const contents5 = [
  'Upon Your Discharge',
  'Feel free to ask for your healthcare instructions in writing; we want you to feel that you have the information you need to care for yourself at home. Please make sure you and your care-\ngiver understand these instructions and ask questions until you understand them.',
  'Your medication must be followed as prescribed, and if you have any concerns about your safety, please tell your nurse, doctor or therapist.',
  'Make sure you have the names and contact information for your doctors, clinics, pharmacies and rehabilitation services for easy reference.',
];

const PatientsRights = (props: Props) => {
  const { disabled } = props;

  return (
    <>
      <SectionTitle title="Patient’s Rights" />
      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents2.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents2.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents3.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents3.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents4.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents4.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>

      <Box
        sx={{
          width: '98%',
          margin: '48px auto 24px auto',
          border: '1px solid lightgray',
          padding: '10px',
        }}
      >
        {contents5.map((v, i) => {
          if (i === 0) {
            return (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          if (i === contents5.length - 1) {
            return (
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '15px',
                  lineHeight: '24px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {v}
              </Typography>
            );
          }
          return (
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '15px',
                lineHeight: '24px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {v}
              <p />
            </Typography>
          );
        })}
      </Box>
    </>
  );
};

export default PatientsRights;
