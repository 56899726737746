import { Fragment, useState } from 'react';
import { Grid } from '@mui/material';

import Form from 'components/Form';
import MuiTable from 'components/MuiTable';
import { IFormRegister } from 'routes/Main/type';

import RowContainer from '../components/RowContainer';
import SectionTitle from '../components/SectionTitle';

interface Props extends IFormRegister {
  disabled?: boolean;
}

const CheckReservations = (props: Props) => {
  const { disabled, register } = props;

  const columns = [
    { fieldId: 'name', label: 'Inspection Name' },
    { fieldId: 'destination', label: 'Place' },
    { fieldId: 'warning', label: 'Precautions' },
    { fieldId: 'call_number', label: 'Phone Number' },
    { fieldId: 'date', label: 'Reservation Date' },
  ];
  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date1 =
    dateNow.getUTCDate().toString().length < 2 // 10일 이하인지 보고, 이하면 01 02 처럼 표시하고 아니면 그대로 표시해라.Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date1}`; // combining to format for defaultValue or value attribute of material <TextField>

  const [date, setDate] = useState(materialDateInput);

  const rows = Array.from({ length: 2 }, (_, i) => {
    const prefix = `check_reservations.${i}`;
    return {
      id: i,
      name: (
        <Form.MuiTextField
          sx={{ width: '13.55vw' }}
          disabled={disabled}
          {...register(`${prefix}.name`)}
        />
      ),
      destination: (
        <Form.MuiTextField
          sx={{ width: '13.55vw' }}
          disabled={disabled}
          {...register(`${prefix}.destination`)}
        />
      ),
      warning: (
        <Form.MuiTextField
          sx={{ width: '13.55vw' }}
          disabled={disabled}
          {...register(`${prefix}.warning`)}
        />
      ),
      call_number: (
        <Form.MuiTextField
          sx={{ width: '13.55vw' }}
          type="tel"
          disabled={disabled}
          {...register(`${prefix}.call_number`)}
        />
      ),
      date: (
        <Form.MuiTextField
          type="date"
          label="Please enter a date"
          InputLabelProps={{ shrink: true }}
          required={false}
          value={date}
          defaultValue={materialDateInput}
          disabled={disabled}
          sx={{ width: '230px' }}
          {...register(`${prefix}.date`, {
            onChange: e => setDate(e.target.value),
          })}
        />
      ),
    };
  });

  return (
    <Fragment>
      <SectionTitle title="Examination Reservation" />

      <RowContainer xs={12}>
        <Grid item xs={12}>
          <MuiTable columns={columns} rows={rows} />
        </Grid>
      </RowContainer>
    </Fragment>
  );
};

export default CheckReservations;
